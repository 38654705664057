import { Warp } from "warp-contracts";

import { arconnect, metamask } from "@/utils/walletConnection";
import { exhaustiveSwitch } from "@/utils/exhaustiveSwitch";

let warp: Warp | undefined;

export async function getWarp() {
  const { WarpFactory, LoggerFactory } = await import("warp-contracts");
  LoggerFactory.INST.logLevel("none");
  if (!warp) {
    warp = WarpFactory.forMainnet({ inMemory: true, dbLocation: "nowhere" });
  }
  return warp;
}

export type WalletType = "arconnect" | "metamask";

export const WALLETS_URLS: Readonly<Record<WalletType, string>> = {
  arconnect: "https://www.arconnect.io/",
  metamask: "https://metamask.io/download/",
};

export type ArconnectWalletData = {
  type: "arconnect";
  publicKey: string;
  address: string;
};

export type MetamaskWalletData = {
  type: "metamask";
  publicKey?: Buffer;
  address: string;
};

export type WalletData = ArconnectWalletData | MetamaskWalletData;

export function getWalletData(type: WalletType): Promise<WalletData | null> {
  switch (type) {
    case "arconnect":
      return arconnect.getWalletData();

    case "metamask":
      return metamask.getWalletData();

    default:
      return exhaustiveSwitch(type);
  }
}

export async function sign(walletData: WalletData, data: string): Promise<string> {
  switch (walletData.type) {
    case "arconnect":
      return arconnect.sign(data);

    case "metamask": {
      return metamask.sign(walletData, data);
    }

    default:
      return exhaustiveSwitch(walletData);
  }
}

export function walletDataToPayload(walletData: WalletData) {
  switch (walletData.type) {
    case "arconnect":
      return { arweave_public_key: walletData.publicKey };

    case "metamask":
      return { evm_address: walletData.address };

    default:
      return exhaustiveSwitch(walletData);
  }
}
