import { SVGProps } from "react";

export default function VerifiedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="22" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.3071 2.20987C13.578 0.345651 16.2682 0.345651 16.5391 2.20987C16.7498 3.66012 18.6163 4.12017 19.4769 2.93397C20.5831 1.40916 22.9652 2.65939 22.3387 4.43595C21.8513 5.818 23.2902 7.09277 24.6034 6.44235C26.2915 5.60627 27.8198 7.82031 26.4394 9.10223C25.3656 10.0995 26.0473 11.8969 27.5123 11.9313C29.3956 11.9755 29.7199 14.6461 27.9019 15.1397C26.4876 15.5237 26.2559 17.4321 27.5372 18.1434C29.1842 19.0577 28.2302 21.5731 26.3911 21.1653C24.9604 20.8481 23.8684 22.4302 24.6723 23.6554C25.7058 25.2304 23.6921 27.0144 22.2531 25.7986C21.1337 24.8528 19.4315 25.7462 19.574 27.2047C19.7572 29.0796 17.1451 29.7234 16.4359 27.9782C15.8843 26.6205 13.9619 26.6205 13.4103 27.9782C12.7011 29.7234 10.089 29.0796 10.2722 27.2047C10.4147 25.7462 8.71248 24.8528 7.59306 25.7986C6.15411 27.0144 4.14042 25.2304 5.17389 23.6554C5.97785 22.4302 4.88582 20.8481 3.4551 21.1653C1.61597 21.5731 0.66199 19.0577 2.30902 18.1434C3.5903 17.4321 3.35858 15.5237 1.94431 15.1397C0.126329 14.6461 0.450604 11.9755 2.33388 11.9313C3.79895 11.8969 4.48064 10.0995 3.40681 9.10223C2.02645 7.82031 3.5547 5.60627 5.24279 6.44235C6.55601 7.09277 7.99493 5.818 7.50755 4.43595C6.88104 2.65939 9.26315 1.40916 10.3693 2.93397C11.2299 4.12017 13.0964 3.66012 13.3071 2.20987Z"
        fill="#767BFF"
      />
      <path
        d="M13.3071 2.20987C13.578 0.345651 16.2682 0.345651 16.5391 2.20987C16.7498 3.66012 18.6163 4.12017 19.4769 2.93397C20.5831 1.40916 22.9652 2.65939 22.3387 4.43595C21.8513 5.818 23.2902 7.09277 24.6034 6.44235C26.2915 5.60627 27.8198 7.82031 26.4394 9.10223C25.3656 10.0995 26.0473 11.8969 27.5123 11.9313C29.3956 11.9755 29.7199 14.6461 27.9019 15.1397C26.4876 15.5237 26.2559 17.4321 27.5372 18.1434C29.1842 19.0577 28.2302 21.5731 26.3911 21.1653C24.9604 20.8481 23.8684 22.4302 24.6723 23.6554C25.7058 25.2304 23.6921 27.0144 22.2531 25.7986C21.1337 24.8528 19.4315 25.7462 19.574 27.2047C19.7572 29.0796 17.1451 29.7234 16.4359 27.9782C15.8843 26.6205 13.9619 26.6205 13.4103 27.9782C12.7011 29.7234 10.089 29.0796 10.2722 27.2047C10.4147 25.7462 8.71248 24.8528 7.59306 25.7986C6.15411 27.0144 4.14042 25.2304 5.17389 23.6554C5.97785 22.4302 4.88582 20.8481 3.4551 21.1653C1.61597 21.5731 0.66199 19.0577 2.30902 18.1434C3.5903 17.4321 3.35858 15.5237 1.94431 15.1397C0.126329 14.6461 0.450604 11.9755 2.33388 11.9313C3.79895 11.8969 4.48064 10.0995 3.40681 9.10223C2.02645 7.82031 3.5547 5.60627 5.24279 6.44235C6.55601 7.09277 7.99493 5.818 7.50755 4.43595C6.88104 2.65939 9.26315 1.40916 10.3693 2.93397C11.2299 4.12017 13.0964 3.66012 13.3071 2.20987Z"
        fill="url(#paint0_linear)"
        fillOpacity="0.5"
      />
      <path
        d="M9.73828 15L13.2383 18.5L19.7383 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="14.9231"
          y1="0.811707"
          x2="14.9231"
          y2="29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
