import { useEffect } from "react";

function disableZoom() {
  let meta = document.querySelector('meta[name="viewport"]');
  if (!meta) {
    meta = document.createElement("meta");
    meta.setAttribute("name", "viewport");
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
  meta.setAttribute("content", "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0");

  setTimeout(() => {
    if (meta) document.getElementsByTagName("head")[0].removeChild(meta);
  }, 500);
}

export default function useDisableZoomOnInputFocus() {
  useEffect(() => {
    const handleFocus = (ev: TouchEvent) => {
      const target = ev.target as HTMLElement;
      if (target.tagName === "INPUT" || target.tagName === "TEXTAREA") {
        disableZoom();
      }
    };

    document.addEventListener("touchstart", handleFocus, { passive: true });

    return () => {
      document.removeEventListener("touchstart", handleFocus);
    };
  }, []);
}
