import clsx from "clsx";
import Image from "next/image";

import { AudioPlayerTrack, useAudioPlayer } from "@/components/AudioPlayer";
import PlayPauseButton from "@/components/ui/svg/PlayPauseButton";
import { BasicTrackFragment, TrackThumbnailType } from "@/graphql/types";

/** Props for {@link TrackThumbnail} */
interface Props {
  /** Track to display */
  track: BasicTrackFragment;
  /**
   * Playlist to play when clicking play
   * Just plays the track if left undefined
   */
  playlist?: Omit<AudioPlayerTrack, "streamSource">[];
  /**
   * Index of the track in the playlist.
   */
  playlistIndex?: number;
  /**
   * Where the thumbnail is being used.
   * Sent to analytics as the stream's source.
   */
  streamSource: string;
  /** Sizes prop passed to the NextJS Image component */
  nextSizes?: string;
  /** Priority prop passed to the NextJS Image component. @defaultValue false */
  priority?: boolean;
  /** Shows a play/pause button. @defaultValue true */
  playButton?: boolean;
  /** Makes the play button larger */
  largePlayButton?: boolean;
  /** Disables the onClick event for the play button */
  disablePlayButton?: boolean;
  /**
   * Decide wether or not to show the play/pause
   * overlay when the track is playing
   *
   * @defaultValue true
   */
  showOverlayWhenPlaying?: boolean;
  /** Rounds the thumbnail with rounded-md. Defaults to true */
  rounded?: boolean;
  /** Called after the play/pause logic */
  onClick?: () => void;
}

/**
 * Thumbnail of the track's minified video or image cover.
 * Also has a play/pause button overlay
 *
 * @remarks
 * Will always use `track.minifiedVideoUrl` over
 * `track.thumbnail?.minifiedImageUrl`
 *
 * @param props - Component props
 */
export default function TrackThumbnail({
  track,
  playlist,
  playlistIndex,
  streamSource,
  nextSizes,
  priority = false,
  playButton = true,
  largePlayButton = false,
  disablePlayButton = false,
  showOverlayWhenPlaying = true,
  rounded = true,
  onClick,
}: Props) {
  const { playTracks, isPlaying: musicPlaying, musicId, musicIndex } = useAudioPlayer();

  const isPlaying = musicPlaying && musicId === track.id && (!playlistIndex || musicIndex === playlistIndex);
  const borderRadius = rounded ? " rounded-md" : "";
  return (
    <div
      onClick={(e) => {
        if (!disablePlayButton) {
          e.stopPropagation();
          e.preventDefault();
          const tracks = playlist ?? [track];
          const sourcedTracks = tracks.map((t) => ({ ...t, streamSource }));
          playTracks(sourcedTracks, playlistIndex);
          onClick?.();
        }
      }}
      className={
        clsx("group absolute inset-0 flex-shrink-0 w-full h-full flex", "items-center text-white") +
        borderRadius
      }
    >
      {playButton && (
        <div
          className={
            clsx(
              "transition-all duration-300 ease-quart-out group-one",
              "cursor-pointer absolute w-full h-full inset-0 flex items-end",
              "md:items-center md:justify-center",
              "md:bg-[image:linear-gradient(135deg,rgba(120,134,255,0.8)_0%,rgba(71,76,210,0.8)_100%)]",
              "z-10 flex-shrink-0"
            ) +
            (showOverlayWhenPlaying && isPlaying ? "" : " md:opacity-0 md:group-hover:opacity-100") +
            borderRadius
          }
        >
          <div
            className={clsx(
              "flex-shrink-0 border-2 border-white",
              "text-purple md:text-white bg-white md:bg-transparent mb-2 ml-2 md:m-0",
              largePlayButton ? "w-[30%]" : "w-1/5",
              "md:w-1/3 max-w-16 min-w-4 square-ratio flex items-center justify-center rounded-full",
              "transition-all transform md:group-one-hover:scale-102.5 shadow-large md:shadow-none z-10"
            )}
          >
            <PlayPauseButton
              width="30%"
              playing={isPlaying}
              className={clsx("absolute group-one-hover:scale-102.5 ") + (isPlaying ? "" : " ml-5%")}
            />
          </div>
        </div>
      )}
      <div className="relative w-full h-full">
        {track.thumbnail?.type === TrackThumbnailType.Video && track.thumbnail.minifiedUrl ? (
          <video
            className={"absolute h-full w-full object-cover" + borderRadius}
            src={track.thumbnail.minifiedUrl}
            autoPlay
            loop
            playsInline
            muted
          />
        ) : (
          <Image
            className={borderRadius}
            src={track.thumbnail?.minifiedImageUrl ?? "/assets/misc/no-image.svg"}
            alt={track.title}
            layout="fill"
            objectFit="cover"
            sizes={nextSizes}
            priority={priority}
          />
        )}
      </div>
    </div>
  );
}
