import { PropsWithChildren } from "react";

import Link from "@/components/hoc/CustomLink";

type HTMLTag = "div" | "strong";

type Props = {
  /**
   * Href passed to the Next Link component.
   * A div is returned instead if undefined.
   */
  href: string | undefined;
  /**
   * ClassName passed to the anchor tag when
   * href is defined.
   * @defaultValue `min-w-0`
   */
  anchorClassName?: string;
  /**
   * HTML tag to use instead of the link.
   * @defaultValue `div`
   */
  as?: HTMLTag;
  external?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement, MouseEvent>) => void;
};

export default function ConditionalLink({
  href,
  anchorClassName,
  as,
  className,
  external,
  onClick,
  children,
}: PropsWithChildren<Props>) {
  const child =
    as === "strong" ? (
      <strong {...{ className, onClick }}>{children}</strong>
    ) : (
      <div {...{ className, onClick }}>{children}</div>
    );
  if (external)
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={anchorClassName ?? "min-w-0"}
        onClick={(e) => e.stopPropagation()}
        {...{ href }}
      >
        {child}
      </a>
    );

  return href ? (
    <Link href={href}>
      <a className={anchorClassName ?? "min-w-0"} onClick={(e) => e.stopPropagation()}>
        {child}
      </a>
    </Link>
  ) : (
    child
  );
}
