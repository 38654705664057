import { WalletData } from "@/utils/walletConnection";
import { requestWalletChallenge } from "@/utils/oAuth/requests";
import { isOAuthError } from "@/utils/oAuth/helpers";
import { sign, walletDataToPayload } from "@/utils/walletConnection/common";

export async function signWalletDataForLogin(walletData: WalletData) {
  const walletDataPayload = walletDataToPayload(walletData);

  // Get the challenge from the OAuth API
  const challenge = await requestWalletChallenge({
    type: "login",
    payload: walletDataPayload,
  });

  if (isOAuthError(challenge)) {
    throw new Error(`OAuth error received in place of challenge: ${JSON.stringify(challenge)}`);
  }

  const signature = await sign(walletData, challenge.challenge);

  const args = {
    signature,
    challenge: challenge.challenge,
    ...walletDataPayload,
  };

  // Return the payload required to log in
  return args;
}
