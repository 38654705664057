import { unwrapMaybe } from "@/utils/graphql";
import {
  WalletApproveState,
  BasicUserFragment,
  DetailedPrivateUserFragment,
  UserTransactionType,
} from "@/graphql/types";
import { LS_ARTIST_REGISTER } from "@/utils/constants";

const LS_AUTH_USER = "pianity-auth-user";

interface AuthUser extends BasicUserFragment {
  email: string | undefined;
  limited?: boolean;
  artist?: DetailedPrivateUserFragment["artist"];
  label?: DetailedPrivateUserFragment["label"];
  transactionType: UserTransactionType;
  walletApproveState: WalletApproveState | undefined;
}

/**
 * Saves basic information about the user to local storage.
 *
 * @param user - The user to save to local storage.
 */
export function saveAuthUser(user: DetailedPrivateUserFragment): void {
  if (typeof window === "undefined") return;

  const authUser: AuthUser = {
    id: user.id,
    slug: user.slug,
    email: unwrapMaybe(user.email),
    limited: user.limited,
    username: user.username,
    displayName: user.displayName,
    avatarUrl: user.avatarUrl,
    artist: user.artist,
    label: user.label,
    transactionType: user.transactionType,
    walletApproveState: unwrapMaybe(user.walletApproveState),
  };

  localStorage.setItem(LS_AUTH_USER, JSON.stringify(authUser));
}

/**
 * Clears the local storage of the logged in user.
 */
export function clearAuthUser(): void {
  if (typeof window === "undefined") return;

  localStorage.removeItem(LS_AUTH_USER);
  localStorage.removeItem(LS_ARTIST_REGISTER);

  // Admin builder related storage
  localStorage.removeItem("pianity-saved-scarcities-sale");
  localStorage.removeItem("pianity-saved-scarcities-bounty");
  localStorage.removeItem("pianity-saved-scarcities-private");
}

/**
 * Fetches basic info about logged in user from local storage.
 *
 * @returns The user saved in local storage, or undefined if there is no user.
 */
export function getAuthUser(): AuthUser | undefined {
  if (typeof window === "undefined") return undefined;

  const user = localStorage.getItem(LS_AUTH_USER);
  if (user) {
    return JSON.parse(user);
  }
  return undefined;
}
