import { BasicNftFragment, BasicTrackFragment, Rarity, ShareAssetsUrLs } from "@/graphql/types";

/**
 * Creates a hidden download link to download all 3
 * release image assets.
 *
 * @param nft - NFT to use the # of
 * @param release - Release that has the downloadable assets
 * @param track - Track to use for title
 *
 * @remarks
 * Only works client side of course.
 */
export function downloadReleaseAssets(
  assets: ShareAssetsUrLs | undefined,
  scarcity: Rarity,
  track: BasicTrackFragment,
  nft?: BasicNftFragment
) {
  if (typeof window === "undefined" || !assets) return;

  const name = `${track.title} ${scarcity}${nft ? " NFT #" + nft.number : ""}.png`;

  const { story, square, twitter } = assets;
  const downloads = [
    { path: square, name: "square - " + name },
    { path: story, name: "portrait - " + name },
    { path: twitter, name: "landscape - " + name },
  ];

  downloads.forEach((download, i) => {
    setTimeout(() => {
      fetch(download.path)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = download.name;
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(url);
        })
        .catch((err) => console.error(err));
    }, i * 300);
  });
}
