/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const API_URI = process.env.NEXT_PUBLIC_API_URI!;
/** API version this build is using.
 * Used to force client refreshes on major updates.
 * @example 1.0.0
 */
export const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION;
export const WS_URI = process.env.NEXT_PUBLIC_WS_URI!;
export const OAUTH_URI = process.env.NEXT_PUBLIC_OAUTH_URI!;
export const STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY!;
export const SEGMENT_API_KEY = process.env.NEXT_PUBLIC_SEGMENT_API_KEY!;
export const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY!;
export const POSTHOG_HOST = process.env.NEXT_PUBLIC_POSTHOG_HOST!;
export const DISCORD_CLIENT_ID = process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID!;
export const DISCORD_REDIRECT_URI = process.env.NEXT_PUBLIC_DISCORD_REDIRECT_URI!;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN!;
export const SENTRY_PROJECT_ID = process.env.NEXT_PUBLIC_SENTRY_PROJECT_ID!;
export const NEXT_ENV = process.env.NEXT_PUBLIC_ENV ?? "production";
export const PIANITY_ENV = process.env.NEXT_PUBLIC_PIANITY_ENV ?? "production";
export const DATADOG_SITE = process.env.NEXT_PUBLIC_DATADOG_SITE!;
export const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!;
export const DATADOG_ENABLED = process.env.NEXT_PUBLIC_DATADOG_ENABLED === "true";
export const RUDDERSTACK_KEY = process.env.NEXT_PUBLIC_RUDDERSTACK_KEY!;
export const RUDDERSTACK_CONFIG_URL = process.env.NEXT_PUBLIC_RUDDERSTACK_CONFIG_URL!;
export const RUDDERSTACK_DEST_SDK_URL = process.env.NEXT_PUBLIC_RUDDERSTACK_DEST_SDK_URL!;
export const RUDDERSTACK_DATA_PLANE_URL = process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL!;
export const PIANITY_ERC1155_ID = process.env.NEXT_PUBLIC_PIANITY_ERC1155_ID!;
export const PIANITY_API_ADDRESS = process.env.NEXT_PUBLIC_PIANITY_API_ADDRESS!;
export const CHAT_SOURCE_IFRAME = process.env.NEXT_PUBLIC_CHAT_SOURCE_IFRAME!;
export const ETHEREUM_RPC_URL = process.env.NEXT_PUBLIC_ETHEREUM_RPC_URL!;
export const ETHEREUM_API_ADDRESS = process.env.NEXT_PUBLIC_ETHEREUM_API_ADDRESS!;
export const ETHEREUM_USDC_ADDRESS = process.env.NEXT_PUBLIC_ETHEREUM_USDC_ADDRESS!;
export const FINGERPRINT_API_KEY = process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY!;
export const FINGERPRINT_ENDPOINT = process.env.NEXT_PUBLIC_FINGERPRINT_ENDPOINT!;
export const FINGERPRINT_SCRIPT_URL = process.env.NEXT_PUBLIC_FINGERPRINT_SCRIPT_URL!;
export const SEO_ARTIST_DEFAULT_FORM_URL = process.env.NEXT_PUBLIC_SEO_ARTIST_DEFAULT_FORM_URL!;
export const SEO_ARTIST_DEFAULT_AVATAR_URL = process.env.NEXT_PUBLIC_SEO_ARTIST_DEFAULT_AVATAR_URL!;
export const SEO_ARTIST_DEFAULT_NAME = process.env.NEXT_PUBLIC_SEO_ARTIST_DEFAULT_NAME!;
export const PIANITY_HOSTNAME = process.env.NEXT_PUBLIC_PIANITY_HOSTNAME ?? "pianity.com";

if (!API_URI) {
  throw new Error("NEXT_PUBLIC_API_URI env var isn't set");
}

if (!WS_URI) {
  throw new Error("NEXT_PUBLIC_WS_URI env var isn't set");
}

if (!OAUTH_URI) {
  throw new Error("NEXT_PUBLIC_OAUTH_URI env var isn't set");
}

if (!STRIPE_API_KEY) {
  throw new Error("NEXT_PUBLIC_STRIPE_API_KEY env var isn't set");
}

if (!SEGMENT_API_KEY) {
  throw new Error("NEXT_PUBLIC_SEGMENT_API_KEY env var isn't set");
}

if (!POSTHOG_API_KEY) {
  throw new Error("NEXT_PUBLIC_POSTHOG_API_KEY env var isn't set");
}

if (!POSTHOG_HOST) {
  throw new Error("NEXT_PUBLIC_POSTHOG_HOST env var isn't set");
}

if (!DISCORD_CLIENT_ID) {
  throw new Error("NEXT_PUBLIC_DISCORD_CLIENT_ID env var isn't set");
}

if (!DISCORD_REDIRECT_URI) {
  throw new Error("NEXT_PUBLIC_DISCORD_REDIRECT_URI env var isn't set");
}

if (!SENTRY_DSN) {
  throw new Error("NEXT_PUBLIC_SENTRY_DSN env var isn't set");
}

if (!SENTRY_PROJECT_ID) {
  throw new Error("NEXT_PUBLIC_SENTRY_PROJECT_ID env var isn't set");
}

if (!DATADOG_SITE) {
  throw new Error("NEXT_PUBLIC_DATADOG_SITE env var isn't set");
}

if (!DATADOG_CLIENT_TOKEN) {
  throw new Error("NEXT_PUBLIC_DATADOG_CLIENT_TOKEN env var isn't set");
}

if (!RUDDERSTACK_KEY) {
  throw new Error("NEXT_PUBLIC_RUDDERSTACK_KEY env var isn't set");
}

if (!RUDDERSTACK_KEY) {
  throw new Error("NEXT_PUBLIC_RUDDERSTACK_KEY env var isn't set");
}

if (!RUDDERSTACK_DEST_SDK_URL) {
  throw new Error("NEXT_PUBLIC_RUDDERSTACK_DEST_SDK_URL env var isn't set");
}

if (!RUDDERSTACK_DATA_PLANE_URL) {
  throw new Error("NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL env var isn't set");
}

if (!PIANITY_ERC1155_ID) {
  throw new Error("NEXT_PUBLIC_PIANITY_ERC1155_ID env var isn't set");
}

if (!PIANITY_API_ADDRESS) {
  throw new Error("NEXT_PUBLIC_PIANITY_API_ADDRESS env var isn't set");
}

if (!CHAT_SOURCE_IFRAME) {
  throw new Error("NEXT_PUBLIC_CHAT_SOURCE_IFRAME env var isn't set");
}

if (!ETHEREUM_RPC_URL) {
  throw new Error("NEXT_PUBLIC_ETHEREUM_RPC_URL env var isn't set");
}

if (!ETHEREUM_API_ADDRESS) {
  throw new Error("NEXT_PUBLIC_ETHEREUM_API_ADDRESS env var isn't set");
}

if (!ETHEREUM_USDC_ADDRESS) {
  throw new Error("NEXT_PUBLIC_ETHEREUM_USDC_ADDRESS env var isn't set");
}

if (!FINGERPRINT_API_KEY) {
  throw new Error("NEXT_PUBLIC_FINGERPRINT_API_KEY env var isn't set");
}

if (!FINGERPRINT_ENDPOINT) {
  throw new Error("NEXT_PUBLIC_FINGERPRINT_ENDPOINT env var isn't set");
}

if (!FINGERPRINT_SCRIPT_URL) {
  throw new Error("NEXT_PUBLIC_FINGERPRINT_SCRIPT_URL env var isn't set");
}
