import { createContext, useContext, useState, useCallback, useMemo, useEffect } from "react";
import { apiObject } from "rudder-sdk-js";

type Context = {
  data: apiObject;
  updateData: (data: apiObject) => void;
  clearData: () => void;
};

const TrackingDataContext = createContext<Context>({
  data: {},
  updateData: () => {
    /* */
  },
  clearData: () => {
    /* */
  },
});

interface TrackingDataProviderProps {
  initialData?: apiObject;
  children: React.ReactNode;
}

const TrackingDataProvider = ({ initialData = {}, children }: TrackingDataProviderProps) => {
  const { data: parentData } = useTrackingData();
  const [data, setData] = useState<apiObject>({ ...parentData, ...initialData });

  const updateData = useCallback(
    (data: apiObject) => {
      setData((prevData) => {
        return {
          ...prevData,
          ...data,
        };
      });
    },
    [setData]
  );

  const clearData = useCallback(() => {
    setData({});
  }, [setData]);

  useEffect(() => {
    updateData(parentData);
  }, [parentData, updateData]);

  const stateValue = useMemo(() => ({ data, updateData, clearData }), [data, updateData, clearData]);

  return <TrackingDataContext.Provider value={stateValue}>{children}</TrackingDataContext.Provider>;
};

export const useTrackingData = () => useContext(TrackingDataContext);
export default TrackingDataProvider;
