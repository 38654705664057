import { SVGProps } from "react";

export default function PreviousButton(props: SVGProps<SVGSVGElement>) {
  const { width = "13", height = "16", ...rest } = props;
  return (
    <svg {...{ width, height }} {...rest} viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3839 2.88388C12.872 2.39573 12.872 1.60427 12.3839 1.11612C11.8957 0.627961 11.1043 0.627961 10.6161 1.11612L5.32322 6.40901C4.44454 7.28769 4.44454 8.71231 5.32322 9.59099L10.6161 14.8839C11.1043 15.372 11.8957 15.372 12.3839 14.8839C12.872 14.3957 12.872 13.6043 12.3839 13.1161L7.26777 8L12.3839 2.88388ZM1.99997 1C2.55226 1 2.99997 1.44772 2.99997 2V14C2.99997 14.5523 2.55226 15 1.99997 15H1.49997C0.947686 15 0.499971 14.5523 0.499971 14V2C0.499971 1.44772 0.947686 1 1.49997 1H1.99997Z"
        className="fill-current"
      />
    </svg>
  );
}
