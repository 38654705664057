import { createContext, useContext, useEffect, useState } from "react";

const INFINITE = Number.POSITIVE_INFINITY;

type Context = {
  width: number;
  height: number;
};

const WindowSizeContext = createContext<Context>({
  width: INFINITE,
  height: INFINITE,
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } =
    typeof window !== "undefined" ? window : { innerWidth: INFINITE, innerHeight: INFINITE };
  return {
    width,
    height,
  };
}

const WindowSizeProvider = ({ children }: { children: React.ReactNode }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window === "undefined") return () => undefined;
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <WindowSizeContext.Provider value={windowDimensions}>{children}</WindowSizeContext.Provider>;
};

export const useWindowSize = () => useContext(WindowSizeContext);
export default WindowSizeProvider;
