import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { useQuery, useReactiveVar } from "@apollo/client";
import clsx from "clsx";

import { AudioPlayerTrack, useAudioPlayer } from "@/components/AudioPlayer";
import { GetTracksSizeDocument, MyReferralsDocument, SaleState } from "@/graphql/types";
import PreviousButton from "@/components/ui/svg/audio/PreviousButton";
import PlayPauseButton from "@/components/ui/svg/PlayPauseButton";
import VolumeControl from "@/components/AudioPlayer/VolumeControl";
import ShareModule from "@/components/reusable/ShareModule";
import NextButton from "@/components/ui/svg/audio/NextButton";
import AudioTimestamp from "@/components/AudioPlayer/Timestamp";
import ProgressBar from "@/components/AudioPlayer/ProgressBar";
import { getArtistNames } from "@/utils/getArtistNames";
import { hasAccessTokenVar } from "@/apollo/links/auth";
import TrackInfo from "@/components/AudioPlayer/TrackInfo";
import ShareIcon from "@/components/ui/svg/ShareIcon";
import { easeQuartOut } from "@/utils/constants";
import Chevron from "@/components/ui/svg/Chevron";
import Link from "@/components/hoc/CustomLink";
import { useWindowSize } from "@/components/WindowSizeProvider";

type Props = {
  track: AudioPlayerTrack;
};

export function AudioPlayer({ track }: Props) {
  const isLogged = useReactiveVar(hasAccessTokenVar);
  const { t } = useTranslation("common");
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const didMount = useRef(false);

  const [showShareTooltip, setShowShareTooltip] = useState(false);

  const { audio, playlist, isPlaying, showFullPlayer, setShowFullPlayer, repeat, setRepeat, playNextTrack } =
    useAudioPlayer();

  const { data: referralData } = useQuery(MyReferralsDocument, { skip: !isLogged });
  const { data: salesData } = useQuery(GetTracksSizeDocument, {
    variables: {
      trackId: track.id,
      trackFilter: { releaseFilter: { nftFilter: { saleFilter: { state: [SaleState.Active] } } } },
    },
  });

  function togglePlay() {
    if (!audio) return;

    if (isPlaying) audio.pause();
    else audio.play().catch(() => undefined);
  }

  // Make player minimized when mounting on mobile
  useEffect(() => {
    if (isMobile && !didMount.current) {
      setShowFullPlayer(false);
      didMount.current = true;
    }
  }, [isMobile, setShowFullPlayer]);

  const noPlaylist = !playlist || playlist.length < 2;
  const artistNames = getArtistNames(track.artists);
  return (
    <motion.div
      className={clsx(
        "fixed inset-0 lg:top-auto lg:bottom-0 flex flex-col justify-end z-[999]",
        showFullPlayer ? "lg:inset-x-[1.6vw]" : "top-auto lg:left-[1.6vw] lg:right-auto"
      )}
    >
      <motion.div
        key="mobile-bg-fade"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: easeQuartOut }}
        onClick={isMobile ? () => setShowFullPlayer(false) : undefined}
        className="lg:hidden grow bg-purple-fade-to-b"
      />
      <motion.div
        key="player"
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        transition={{ ease: easeQuartOut }}
        layout
        onClick={showFullPlayer ? undefined : () => setShowFullPlayer(true)}
        className={clsx(
          "shrink-0 w-screen lg:w-auto lg:rounded-t-lg relative",
          "lg:shadow-[0px_-4px_4px_rgba(28,15,125,0.02),0px_0px_20px_rgba(28,15,125,0.07)]",
          "bg-dark-4/40 bg-dark-glass lg:py-3 lg:pl-3 flex items-center backdrop-blur-lg",
          showFullPlayer ? "lg:pr-4" : "cursor-pointer lg:pr-2",
          "before:border-x before:border-t before:bg-dark-stroke before:bg-dark-6/30",
          "before:border-gradient lg:before:rounded-t-lg before:backdrop-blur-lg"
        )}
      >
        <motion.div
          layout
          transition={{ ease: easeQuartOut }}
          className="flex flex-col w-full justify-end min-w-0"
        >
          <motion.div
            layout
            transition={{ ease: easeQuartOut }}
            className={clsx(
              "flex",
              showFullPlayer ? ["lg:grid lg:grid-cols-[1fr,auto,1fr]", "px-4 pb-4 pt-3 lg:p-0"] : "p-2 lg:p-0"
            )}
          >
            <div className="grow flex lg:justify-between items-center min-w-0">
              <TrackInfo track={track} key={track.id} minimized={!showFullPlayer} />
              <div className="flex items-center">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    togglePlay();
                  }}
                  className={clsx(
                    "lg:ml-4 w-8 h-6 lg:w-9 lg:h-9 items-center justify-center",
                    "rounded-full lg:hover:bg-dark-hover shrink-0",
                    showFullPlayer ? "hidden lg:flex" : "flex lg:hidden"
                  )}
                >
                  <PlayPauseButton
                    className={isPlaying ? undefined : "ml-1"}
                    playing={isPlaying}
                    width="13"
                    height="15.6"
                  />
                </button>
                {!showFullPlayer && (
                  <button
                    disabled={noPlaylist}
                    className={clsx(
                      "w-8 h-6 flex lg:hidden items-center justify-center shrink-0 mr-2",
                      noPlaylist && "opacity-60"
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      playNextTrack();
                    }}
                  >
                    <NextButton />
                  </button>
                )}
                {showFullPlayer && <VolumeControl audio={audio} />}
              </div>
            </div>
            <div
              className={clsx(
                "hidden items-center justify-center w-[37vw] lg:w-[31vw] mx-4 shrink-0",
                showFullPlayer && "lg:flex"
              )}
            >
              <ProgressBar
                togglePlay={togglePlay}
                trackId={track.id}
                trackingData={{
                  track: track.slug,
                  artists: track.artists.map((a) => a.slug),
                }}
              />
            </div>
            <div
              className={clsx(
                "flex flex-col items-center lg:justify-between lg:flex-row",
                !showFullPlayer && "justify-center"
              )}
            >
              {showFullPlayer && (
                <div className="hidden lg:flex items-center shrink-0">
                  <div className="hidden lg:block mr-4 shrink-0">
                    <AudioTimestamp />
                  </div>
                  <div className={"flex rounded-full lg:hover:bg-dark-6 shrink-0"}>
                    <button
                      disabled={noPlaylist}
                      className={clsx(
                        "hidden lg:flex w-9 h-9 items-center justify-center",
                        "rounded-l-full shrink-0",
                        noPlaylist ? "opacity-60" : "lg:hover:bg-dark-hover"
                      )}
                      onClick={() => playNextTrack(true)}
                    >
                      <PreviousButton />
                    </button>
                    <button
                      disabled={noPlaylist}
                      className={
                        "w-9 h-9 hidden lg:flex items-center justify-center shrink-0" +
                        (noPlaylist ? " opacity-60" : " lg:hover:bg-dark-hover")
                      }
                      onClick={() => playNextTrack()}
                    >
                      <NextButton />
                    </button>
                    <button
                      className={clsx(
                        "w-5.5 h-5.5 lg:w-9 lg:h-9 flex items-center justify-center",
                        "rounded-r-full lg:hover:bg-dark-hover shrink-0"
                      )}
                      onClick={() => setRepeat((r) => !r)}
                    >
                      <svg
                        width="13"
                        height="18"
                        viewBox="0 0 13 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-1"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.16641 8.00593C7.77589 7.61541 7.77589 6.98224 8.16641 6.59172L9.60873 5.1494H4C2.89543 5.1494 2 6.04483 2 7.1494V8.08616C2 8.63845 1.55228 9.08616 1 9.08616C0.447715 9.08616 0 8.63845 0 8.08616V7.1494C0 4.94026 1.79086 3.1494 4 3.1494H9.6087L8.16641 1.70711C7.77588 1.31658 7.77588 0.683418 8.16641 0.292893C8.55693 -0.0976311 9.1901 -0.0976311 9.58062 0.292893L12.0229 2.7352C12.804 3.51625 12.804 4.78258 12.0229 5.56363L9.58062 8.00593C9.1901 8.39646 8.55693 8.39646 8.16641 8.00593ZM4.53043 9.74096C4.92096 10.1315 4.92096 10.7646 4.53043 11.1552L3.08811 12.5975L8.69684 12.5975C9.80141 12.5975 10.6968 11.7021 10.6968 10.5975V9.66072C10.6968 9.10844 11.1446 8.66072 11.6968 8.66072C12.2491 8.66072 12.6968 9.10844 12.6968 9.66072V10.5975C12.6968 12.8066 10.906 14.5975 8.69684 14.5975L3.08814 14.5975L4.53043 16.0398C4.92096 16.4303 4.92096 17.0635 4.53043 17.454C4.13991 17.8445 3.50674 17.8445 3.11622 17.454L0.673913 15.0117C-0.107135 14.2306 -0.107136 12.9643 0.673913 12.1833L3.11622 9.74096C3.50674 9.35043 4.13991 9.35043 4.53043 9.74096Z"
                          className={repeat ? "fill-purple" : "fill-current"}
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              {/* MARK: Buttons */}
              <div
                className={clsx(
                  "flex flex-col-reverse lg:flex-row items-end lg:items-center",
                  "justify-between lg:justify-end",
                  showFullPlayer && "grow"
                )}
              >
                {showFullPlayer && (
                  <div className="flex items-center lg:mr-2">
                    {/* MARK: Buy/bid button */}
                    {!!salesData?.tracks.size && track.slug && track.artists[0]?.slug && (
                      <Link
                        href={`/${track.hasWaitlistSlug ? "toplist" : track.artists[0]?.slug}/${
                          track.slug
                        }#sales`}
                        shallow
                      >
                        <a
                          onClick={isMobile ? () => setShowFullPlayer(false) : undefined}
                          className={clsx(
                            "z-10 h-8 lg:h-10 px-3 lg:px-5 flex items-center",
                            "justify-center rounded-full bg-gradient-135deg",
                            "from-[#ADB6FF] to-[#767BFF] hover:from-[#8195f9]",
                            "hover:to-[#4b62f7] text-xs leading-1 lg:text-base",
                            "lg:leading-1 tracking-wider text-white font-medium uppercase",
                            "-mr-2 lg:-mr-4"
                          )}
                        >
                          {t("common.sales.buttons.buy")}
                        </a>
                      </Link>
                    )}
                    {track.slug && track.artists[0]?.slug && (
                      <div className="relative">
                        <ShareModule
                          show={showShareTooltip}
                          onClose={() => setShowShareTooltip(false)}
                          link={`https://pianity.com/${
                            track.hasWaitlistSlug ? "toplist" : track.artists[0]?.slug
                          }/${track.slug}${
                            referralData?.me.invitationCode ? `?rc=${referralData.me.invitationCode}` : ""
                          }`}
                          copyShareText={`Listen to "${track.title}" by ${artistNames} on Pianity!`}
                          emailShareTitle={`Listen to "${track.title}" by ${artistNames} on Pianity!`}
                          emailShareBody=""
                          facebookShareText={`Listen to "${track.title}" by ${artistNames} on Pianity!`}
                          twitterShareText={`Listen to "${track.title}" by ${artistNames} on @pianitynft!`}
                          whatsappShareText={`Listen to "${track.title}" by ${artistNames} on Pianity!`}
                          telegramShareText={`Listen to "${track.title}" by ${artistNames} on Pianity!`}
                          trackingInfo={{
                            page: "Music Player",
                            item: "Track",
                            trackTitle: track.title,
                            artistName: track.artists[0]?.name,
                          }}
                        />
                        <div
                          onClick={() => setShowShareTooltip(true)}
                          className={clsx(
                            "h-8 lg:h-10 w-8 lg:w-10 flex items-center justify-center",
                            "bg-dark-7 rounded-full shadow-small z-0 cursor-pointer",
                            "transition ease-quart-out duration-300 md:hover:bg-dark-hover"
                          )}
                        >
                          <ShareIcon className="mr-0.5 lg:mr-0 lg:w-5 lg:h-5.5" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* MARK: Minimize button */}
                <button
                  onClick={() => setShowFullPlayer((s) => !s)}
                  className="w-4 h-4 lg:w-6 lg:h-8 flex items-center justify-center text-purple"
                >
                  <Chevron
                    className={clsx(
                      "transition-transform ease-quart-out duration-300",
                      showFullPlayer ? "rotate-180 lg:-rotate-90 " : "lg:rotate-90 "
                    )}
                  />
                </button>
              </div>
            </div>
          </motion.div>
          {/* MARK: Mobile Waveform + Controls */}
          <AnimatePresence>
            {showFullPlayer && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: 64 }}
                exit={{ height: 0 }}
                layout
                transition={{ ease: easeQuartOut }}
                className="grid grid-cols-[1fr,auto,1fr] lg:hidden bg-dark-8/30 px-3.5"
              >
                <div className="flex items-center">
                  <button
                    disabled={noPlaylist}
                    className={clsx(
                      "w-6 h-6 flex items-center justify-center shrink-0",
                      noPlaylist && "opacity-60"
                    )}
                    onClick={() => playNextTrack(true)}
                  >
                    <PreviousButton />
                  </button>
                  <button
                    onClick={togglePlay}
                    className="w-8 h-6 flex items-center justify-center rounded-full shrink-0"
                  >
                    <PlayPauseButton
                      className={isPlaying ? undefined : "ml-1"}
                      playing={isPlaying}
                      width="13"
                      height="15.6"
                    />
                  </button>
                  <button
                    disabled={noPlaylist}
                    className={clsx(
                      "w-6 h-6 flex items-center justify-center shrink-0",
                      noPlaylist && "opacity-60"
                    )}
                    onClick={() => playNextTrack()}
                  >
                    <NextButton />
                  </button>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div className="w-[37vw] lg:w-[31vw] mx-4 shrink-0">
                    <ProgressBar
                      togglePlay={togglePlay}
                      trackId={track.id}
                      trackingData={{
                        track: track.slug,
                        artists: track.artists.map((a) => a.slug),
                      }}
                    />
                  </div>
                  <div className="mt-1 shrink-0">
                    <AudioTimestamp />
                  </div>
                </div>
                <div className="flex items-center w-full justify-end">
                  <button
                    className="w-5.5 h-5.5 flex items-center justify-center rounded-r-full shrink-0"
                    onClick={() => setRepeat((r) => !r)}
                  >
                    <svg
                      width="13"
                      height="18"
                      viewBox="0 0 13 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.16641 8.00593C7.77589 7.61541 7.77589 6.98224 8.16641 6.59172L9.60873 5.1494H4C2.89543 5.1494 2 6.04483 2 7.1494V8.08616C2 8.63845 1.55228 9.08616 1 9.08616C0.447715 9.08616 0 8.63845 0 8.08616V7.1494C0 4.94026 1.79086 3.1494 4 3.1494H9.6087L8.16641 1.70711C7.77588 1.31658 7.77588 0.683418 8.16641 0.292893C8.55693 -0.0976311 9.1901 -0.0976311 9.58062 0.292893L12.0229 2.7352C12.804 3.51625 12.804 4.78258 12.0229 5.56363L9.58062 8.00593C9.1901 8.39646 8.55693 8.39646 8.16641 8.00593ZM4.53043 9.74096C4.92096 10.1315 4.92096 10.7646 4.53043 11.1552L3.08811 12.5975L8.69684 12.5975C9.80141 12.5975 10.6968 11.7021 10.6968 10.5975V9.66072C10.6968 9.10844 11.1446 8.66072 11.6968 8.66072C12.2491 8.66072 12.6968 9.10844 12.6968 9.66072V10.5975C12.6968 12.8066 10.906 14.5975 8.69684 14.5975L3.08814 14.5975L4.53043 16.0398C4.92096 16.4303 4.92096 17.0635 4.53043 17.454C4.13991 17.8445 3.50674 17.8445 3.11622 17.454L0.673913 15.0117C-0.107135 14.2306 -0.107136 12.9643 0.673913 12.1833L3.11622 9.74096C3.50674 9.35043 4.13991 9.35043 4.53043 9.74096Z"
                        className={repeat ? "fill-purple" : "fill-current"}
                      />
                    </svg>
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
