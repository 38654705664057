import { useTranslation } from "next-i18next";
import { useQuery, useReactiveVar } from "@apollo/client";
import clsx from "clsx";

import ShareButtons, { ShareProps } from "@/components/ShareButtons";
import { MyInvitationCodeDocument } from "@/graphql/types";
import { hasAccessTokenVar } from "@/apollo/links/auth";
import ToolTip from "@/components/reusable/Tooltip";
import { useWindowSize } from "@/components/WindowSizeProvider";
import HelpIcon from "@/components/ui/svg/HelpIcon";
import { Optional } from "@/utils/types";

interface Props extends Optional<ShareProps, "link"> {
  show: boolean;
  onClose: () => void;
}

export default function ShareModule(props: Props) {
  const { show, onClose, ...shareProps } = props;

  const { width } = useWindowSize();
  const isMobile = width < 1024;
  const { t } = useTranslation("common");
  const isLogged = useReactiveVar(hasAccessTokenVar);

  const { data } = useQuery(MyInvitationCodeDocument, { skip: !isLogged });

  let { link } = shareProps;
  if (link && !link.includes("rc=") && data) {
    link = `${link}${link.includes("?") ? "&" : "?"}rc=${data.me.invitationCode}`;
  }
  return (
    <ToolTip show={show} mobile={isMobile} onClose={onClose}>
      <div
        className={clsx(
          "flex flex-col items-start bg-dark-2 bg-gradient-to-r from-purple/50 to-purple/50",
          "rounded-md p-4 z-30 lg:min-w-[340px] relative text-white border border-purple"
        )}
      >
        <div className="text-lg leading-1.1 font-medium">{t("common.share.title")}</div>
        <div className="mb-3 flex items-center opacity-60">
          <span className="text-sm leading-1.1">{t("common.share.subtitle")}</span>

          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://help.pianity.com/hc/en-us/articles/7303244339101-What-s-the-referral-program-"
            className="p-2 flex items-center shrink-0"
          >
            <HelpIcon />
          </a>
        </div>
        {link && <ShareButtons {...shareProps} link={link} />}
      </div>
    </ToolTip>
  );
}
