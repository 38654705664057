import { CurrencyInputable } from "@/graphql/types";
import { LS_USER_CURRENCY } from "@/pages/_app";
import { getNumberFormatter, suffixLargeNumber } from "@/utils/numbers";

const currencyFormatter = (currency: string) => {
  return getNumberFormatter(undefined, {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

interface CurrencyFormatterOptions {
  /**
   * The number display to use. Default is "full".
   *
   * - `"full"`: normal full length
   * - `"short"`: when number has too many
   * significant digits, use k to divid by thousands
   * or M to divide by milions (10,000 to 10k).
   */
  display: "full" | "short";
}

/**
 * Creates a locale number formatter for currencies
 *
 * @param currency - optional currency code
 * @param options - options for the formatting
 * @defaultValue saved local storage currency or EUR
 *
 * @returns Intl.NumberFormat with currency options
 */
export function getCurrencyFormatter(currency?: string, options?: CurrencyFormatterOptions) {
  const { display = "full" } = options ?? {};
  const formatter = currencyFormatter(currency ?? getCurrencyCode());

  return {
    format: (amount: number) => {
      const { suffix, number } =
        display === "short" ? suffixLargeNumber(amount) : { suffix: "", number: amount };
      return formatter.format(number) + suffix;
    },
  };
}

/**
 * Get the currency code for a specific country.
 * Will check local storage if no country is specified.
 *
 * EUR is default for unknown, US is for all non EU
 * except JP and GBP
 *
 * @param country - optional 2 letter country code
 *
 * @returns Currency code
 */
export function getCurrencyCode(country?: string): CurrencyInputable {
  if (country) {
    if (EUROPEAN_COUNTRY_CODES.includes(country.toUpperCase())) return CurrencyInputable.Eur;

    if (country.toUpperCase() === JAPAN_CODE) return CurrencyInputable.Jpy;
    if (country.toUpperCase() === BRITAIN_CODE) return CurrencyInputable.Gbp;
    // if (country.toUpperCase() === CHINA_CODE) return CurrencyInputable.Cny;

    return CurrencyInputable.Usd;
  }

  return (
    (typeof window !== "undefined"
      ? (localStorage?.getItem(LS_USER_CURRENCY) as CurrencyInputable)
      : CurrencyInputable.Eur) ?? CurrencyInputable.Eur
  );
}

/**
 * Returns the symbol for the currency used by a country.
 *
 * @remarks
 * Calls getCurrencyCode with country to get currency
 *
 * @param country - optional 2 letter country code
 *
 * @returns "€" | "¥" | "£" | "$"
 */
export function getCurrencySymbol(country?: string, currency?: CurrencyInputable) {
  const code = currency ?? getCurrencyCode(country);
  switch (code) {
    case CurrencyInputable.Eur:
      return "€";
    case CurrencyInputable.Jpy:
      return "¥";
    case CurrencyInputable.Gbp:
      return "£";
    default:
      return "$";
  }
}

const EUROPEAN_COUNTRY_CODES = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
];
// const CHINA_CODE = "CN";
const JAPAN_CODE = "JP";
const BRITAIN_CODE = "GB";
