import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(
  ref: React.MutableRefObject<HTMLElement | null>,
  callback: (e: MouseEvent) => void
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

type Props = {
  children: React.ReactNode;
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
  callback: (e: MouseEvent) => void;
};

export default function OutsideAlerter(props: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, props.callback);

  return (
    <div className={props.className} ref={wrapperRef}>
      {props.children}
    </div>
  );
}
