import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation, Trans } from "next-i18next";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

import CustomCheckbox from "@/components/reusable/CustomCheckbox";
import ExternalAuths from "@/components/connect/ExternalAuths";
import { LS_REFERRAL_CODE, SS_CLAIM_CODE } from "@/pages/_app";
import CustomButton from "@/components/reusable/CustomButton";
import { WalletType } from "@/utils/walletConnection";
import InputBox from "@/components/connect/InputBox";
import { getAnalytics } from "@/utils/analytics";
import { DetailedPrivateUserFragment } from "@/graphql/types";
import { removeQueryParams } from "@/utils/router";

const WalletConnectForm = dynamic(() => import("@/components/connect/WalletConnectSignupForm"));
const SignupLastStep = dynamic(() => import("@/components/connect/SignupLastStep"));

type FormData = {
  email: string;
  password: string;
  confirmPassword: string;
  terms: boolean;
};

interface SignupFormProps {
  onSectionChange: () => void;
  onSuccess: (user: DetailedPrivateUserFragment) => void;
  containerClassName?: string;
  walletSetupContainerClassName?: string;
  externalAuthsContainerClassName?: string;
}

export default function SignupForm({
  onSectionChange,
  onSuccess,
  walletSetupContainerClassName,
}: SignupFormProps) {
  const { t } = useTranslation("connect");
  const router = useRouter();
  const isAuthModal = !router.pathname.startsWith("/connect");

  const [withWallet, setWithWallet] = useState<undefined | WalletType>(undefined);
  const [processing, setProcessing] = useState(false);
  const [isArtist, setIsArtist] = useState(false);

  const formMethods = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const { register, handleSubmit, setValue, getValues, control } = formMethods;
  const terms = useWatch({ control, name: "terms" });

  const { invitation_email: invitationEmail, rc: referralCode } = router.query;

  useEffect(() => {
    if (router.query.msg === "artist") setIsArtist(true);
  }, [router.query]);

  useEffect(() => {
    if (invitationEmail) {
      setValue("email", typeof invitationEmail === "object" ? invitationEmail[0] : invitationEmail);
    }
  }, [invitationEmail, setValue]);

  async function onSubmit(inputs: FormData) {
    setProcessing(true);

    const lsReferralCode = localStorage.getItem(LS_REFERRAL_CODE);
    const rCode = (typeof referralCode === "object" ? referralCode[0] : referralCode) ?? lsReferralCode;
    const isClaimingBounty = !!sessionStorage.getItem(SS_CLAIM_CODE);
    getAnalytics()?.track("WEB - Auth - Signup First Step - Passed", {
      email: inputs.email,
      terms: inputs.terms,
      invitationEmail: !!invitationEmail,
      referralCode: !rCode || rCode === "undefined" ? undefined : rCode,
      arConnect: false,
      metamask: false,
      isClaimingBounty,
      authPopin: isAuthModal,
    });

    router.push({ pathname: router.pathname, query: { ...router.query, "signup-step": "last" } }, undefined, {
      shallow: true,
    });
    setProcessing(false);
  }

  if (withWallet) {
    return (
      <WalletConnectForm
        wallet={withWallet}
        email={getValues().email}
        onBack={(email) => {
          setValue("email", email);
          setWithWallet(undefined);
        }}
        onSuccess={onSuccess}
        setupContainerClassName={walletSetupContainerClassName}
      />
    );
  }

  if (router.asPath.includes("signup-step=last")) {
    return (
      <SignupLastStep
        password={getValues().password}
        email={getValues().email}
        onBack={() => {
          router.push(
            { pathname: router.pathname, query: removeQueryParams(router.query, "signup-step") },
            undefined,
            { shallow: true }
          );
        }}
        onSuccess={onSuccess}
      />
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-6 lg:mb-8">
        {/* MARK: Login/Signup buttons */}
        <div className="mb-5 lg:mb-7 flex items-center">
          <button
            onClick={() => onSectionChange()}
            className="mr-3 lg:mr-4 text-22 leading-1 lg:text-2xl lg:leading-1 font-medium opacity-25"
          >
            {t("connect.forms.buttons.login")}
          </button>

          <div className="text-22 leading-1 lg:text-2xl lg:leading-1 font-medium">
            {t("connect.forms.buttons.signup")}
          </div>
        </div>

        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} key="signup-form" className={"flex flex-col"}>
            <InputBox
              placeholder={t("connect.forms.inputs.email")}
              type="email"
              autoComplete="email"
              readOnly={!!invitationEmail}
              name="email"
              registerOptions={{
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
                  message: t("connect.forms.errors.email.pattern"),
                },
              }}
              required
            />

            <div className="mt-3 grid grid-cols-1 lg:grid-cols-2 gap-3">
              <InputBox
                placeholder={t("connect.forms.inputs.password")}
                autoComplete="new-password"
                type="password"
                name="password"
                registerOptions={{
                  minLength: { value: 8, message: t("connect.forms.errors.password.length") },
                }}
                required
              />
              <InputBox
                placeholder={t("connect.forms.inputs.password_confirm")}
                autoComplete="new-password"
                type="password"
                name="confirmPassword"
                registerOptions={{
                  validate: (value) => {
                    const password = getValues("password");
                    if (value === password) return true;
                    const msg = t("connect.forms.errors.password.match");
                    return msg;
                  },
                }}
                required
              />
            </div>

            {router.query.msg === "artist" && (
              <div className="mt-4 -mb-4">
                <CustomCheckbox
                  label={t("connect.forms.inputs.artist")}
                  checked={isArtist}
                  setChecked={setIsArtist}
                />
              </div>
            )}

            <div className="mt-5 flex flex-col lg:flex-row items-end lg:items-center justify-between">
              <div className="self-start lg:self-center mb-6 lg:mb-0">
                <CustomCheckbox
                  label={
                    <Trans ns="connect" i18nKey="connect.forms.inputs.terms">
                      text
                      <a
                        className="underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://help.pianity.com/hc/en-us/articles/5011062821405-Terms-of-Service"
                        onClick={(e) => {
                          e.stopPropagation();
                          getAnalytics()?.track("Signup ToS Clicked", {
                            authPopin: isAuthModal,
                          });
                        }}
                      />
                    </Trans>
                  }
                  required
                  name="terms"
                  register={register}
                  checked={terms}
                  setChecked={() => undefined}
                />
              </div>

              {/* MARK: Normal login */}
              <CustomButton
                base
                lowercase
                label={t("connect.forms.buttons.signup")}
                type="submit"
                disabled={processing}
                loading={processing}
              />
            </div>
          </form>
        </FormProvider>
      </div>

      <div className="h-0.5 bg-dark-stroke" />

      <ExternalAuths onWalletAuth={setWithWallet} />
    </div>
  );
}
