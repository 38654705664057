import { motion } from "framer-motion";
import clsx from "clsx";

import NftCard, { Props as NftCardProps } from "@/components/releases/cards/NftCard";
import { easeQuartOut } from "@/utils/constants";

type Props = NftCardProps;

export default function RotatedNftCard(props: Props) {
  return (
    <div className="w-full flex justify-center relative">
      <motion.div
        initial={{
          rotate: 0,
        }}
        animate={{ rotate: "-19deg" }}
        transition={{
          duration: 1,
          ease: easeQuartOut,
          delay: 0.05,
        }}
        className={clsx(
          "absolute min-w-52 max-w-[340px] w-3/5 p-0.75 nft-ratio",
          "bg-[#50576E]/30 rounded-xl z-0 shadow-[0px_18px_28px_0px_rgba(0,0,0,0.07)]"
        )}
      />
      <motion.div
        initial={{
          rotate: 0,
        }}
        animate={{ rotate: "-8deg" }}
        transition={{
          duration: 0.6,
          ease: easeQuartOut,
          delay: 0.05,
        }}
        className="min-w-52 max-w-[340px] w-3/5 p-0.75 nft-ratio rounded-xl z-10"
      >
        <div className="absolute inset-0">
          <NftCard {...props} />
        </div>
      </motion.div>
    </div>
  );
}
