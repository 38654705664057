import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/router";
import { SVGProps } from "react";

import { ArtistVerificationState, BasicArtistFragment, BasicUserFragment, Maybe } from "@/graphql/types";
import ConditionalLink from "@/components/hoc/ConditionalLink";
import VerifiedIcon from "@/components/ui/svg/VerifiedIcon";
import { NO_IMAGE_URL } from "@/utils/constants";

type Artist = Pick<BasicArtistFragment, "id" | "slug" | "name" | "avatarUrl" | "verificationState">;

interface Props {
  /** List artists' names to be displayed */
  artists: (Artist & { user?: Maybe<BasicUserFragment> })[];
  /**
   * Set to true to wrap artist names with links
   * to their profiles
   */
  withLinks?: boolean;
  /**
   * Set to true to use Next's Link instead of an
   * onclick `router.push()`
   *
   * @remarks Only used if withLinks is true.
   */
  allowNextLink?: boolean;
  /** Optionally set to disable hover tooltip */
  disableShowAllOnHover?: boolean;
  /** Optionally set to display +N as text only */
  textOnlyArtistSize?: boolean;
  /** Set to display verified icon next to artist names */
  verifiedIconProps?: SVGProps<SVGSVGElement>;
  /**
   * Classname for the visible artist name
   * @defaultValue "block text-sm leading-1.3 font-normal truncate"
   */
  artistClassName?: string;
}

/**
 * Renders the first artist name of the list with a
 * counter next to it indicating how many other artists
 *
 * @remarks
 * By default shows a tooltip with all artists on hover.
 * Disable this by setting `disableShowAllOnHover`
 */
export default function ArtistNames({
  artists,
  withLinks,
  allowNextLink,
  disableShowAllOnHover,
  textOnlyArtistSize,
  verifiedIconProps,
  artistClassName = "block text-sm leading-1.3 font-normal truncate",
}: Props) {
  const router = useRouter();

  if (!artists || artists.length === 0) return null;

  return (
    <div
      className={clsx(
        "flex items-center gap-2 relative group-unique w-max",
        "max-w-full min-w-0",
        !disableShowAllOnHover && artists.length > 1 && "cursor-pointer"
      )}
    >
      {!disableShowAllOnHover && artists.length > 1 && (
        <div
          className={clsx(
            "hidden group-unique-hover:flex flex-col absolute bottom-3/5",
            "left-1/2 -translate-x-1/2 z-100"
          )}
        >
          <div
            className={clsx(
              "bg-dark-2 bg-gradient-to-r from-purple/50 to-purple/50 shadow-tooltip p-3",
              "w-max min-w-[200px] max-w-[300px] grid grid-cols-1 gap-2 rounded-md text-white",
              "border border-purple"
            )}
          >
            {artists.map((artist) => (
              <ConditionalLink
                key={"artist-" + artist.slug}
                href={withLinks && allowNextLink && artist.slug ? `/artist/${artist.slug}` : undefined}
                onClick={
                  withLinks && !allowNextLink ? () => router.push(`/artist/${artist.slug}`) : undefined
                }
                className={"flex items-start gap-2 hover:underline cursor-pointer"}
              >
                <div className="shrink-0 h-5 flex items-center">
                  <Image
                    src={artist.avatarUrl ?? NO_IMAGE_URL}
                    width="20"
                    height="20"
                    alt=""
                    className="rounded-full"
                  />
                </div>
                <span className="text-15 leading-1.25 text-left self-end">
                  {artist.name}
                  {artist.verificationState === ArtistVerificationState.Accepted && verifiedIconProps && (
                    <span className="inline-block">
                      <VerifiedIcon {...verifiedIconProps} />
                    </span>
                  )}
                </span>
              </ConditionalLink>
            ))}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="17"
            viewBox="0 0 30 17"
            fill="none"
            className="mx-auto -mt-2.5 mb-2 -z-10"
          >
            <path
              d="M17.5594 15.3746C15.9973 16.3666 14.0027 16.3666 12.4406 15.3746L2.88186 9.30395C-1.15181 6.74222 0.662839 0.499999 5.44122 0.5L24.5588 0.500002C29.3372 0.500002 31.1518 6.74222 27.1181 9.30394L17.5594 15.3746Z"
              fill="#767BFF"
            />
          </svg>
        </div>
      )}
      <ConditionalLink
        href={withLinks && allowNextLink && artists[0].slug ? `/artist/${artists[0].slug}` : undefined}
        as="strong"
        onClick={withLinks && !allowNextLink ? () => router.push(`/artist/${artists[0].slug}`) : undefined}
        className={
          artistClassName +
          (verifiedIconProps && !artistClassName?.includes("flex") ? " flex items-center" : "")
        }
      >
        <span className="truncate">{artists[0].name}</span>
        {verifiedIconProps && artists[0].verificationState === ArtistVerificationState.Accepted && (
          <VerifiedIcon {...verifiedIconProps} />
        )}
      </ConditionalLink>
      {artists.length > 1 && (
        <div
          className={clsx(
            !textOnlyArtistSize && "min-w-5 h-5 rounded-full flex items-center justify-center bg-purple-6",
            "text-purple text-11 leading-1.25 font-medium",
            "flex-shrink-0"
          )}
        >
          +{artists.length - 1}
        </div>
      )}
    </div>
  );
}
