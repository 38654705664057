import { ButtonHTMLAttributes } from "react";
import LoadingIndicator from "@/components/ui/svg/LoadingIndicator";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  outlined?: boolean;
  warning?: boolean;
  dark?: boolean;
  loading?: boolean;
  xl?: boolean;
  xs?: boolean;
  base?: boolean;
  lowercase?: boolean;
  extraClasses?: string;
};

export default function CustomButton(props: ButtonProps) {
  const {
    label,
    outlined,
    warning,
    dark,
    loading,
    xl,
    xs,
    base,
    lowercase,
    extraClasses,
    onClick,
    ...buttonProps
  } = props;
  let className = `min-w-max w-max flex items-center border-2 rounded-full tracking-wider ${
    lowercase ? "" : "uppercase"
  } font-medium transition ease-quart-out duration-300 relative md:hover:shadow-button`;

  let sizeClass = " h-8 lg:h-10 px-3 lg:px-5 text-xs leading-1 lg:text-sm";
  if (xl) sizeClass = " h-10 lg:h-12 px-4 lg:px-5 text-sm leading-1 lg:text-base lg:leading-1";
  else if (xs) sizeClass = " h-7 px-3 text-xs leading-1";
  else if (base) sizeClass = " h-10 px-5 text-base leading-1";
  className += sizeClass;

  if (extraClasses) className += " " + extraClasses;
  else if (outlined) {
    if (warning) {
      className += " text-grey-dark border-orange-4 md:hover:bg-orange-4";
    } else if (dark) {
      className += " text-grey-dark border-grey-dark md:hover:bg-grey-dark";
    } else {
      className += " text-grey-dark border-purple-4 md:hover:bg-purple-4";
    }
  } else if (warning) {
    className += " text-white border-orange bg-orange md:hover:bg-transparent md:hover:text-orange";
  } else if (dark) {
    className += " text-white border-grey-dark bg-grey-dark md:hover:bg-transparent md:hover:text-grey-dark";
  } else {
    className += " text-white border-purple bg-purple md:hover:bg-transparent md:hover:text-purple";
  }

  if (buttonProps.disabled) className += " opacity-50 pointer-events-none";

  return (
    <button
      className={className}
      {...buttonProps}
      onClick={(e) => {
        if (buttonProps.type !== "submit") e.preventDefault(); // Necessary to stop form from submitting
        onClick?.(e);
      }}
    >
      <span className={loading ? "opacity-0" : undefined}>{label}</span>
      {loading && (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <LoadingIndicator height="20" width="20" />
        </div>
      )}
    </button>
  );
}
