import { SVGProps } from "react";

export default function PlayPauseButton({
  playing,
  ...props
}: { playing: boolean } & SVGProps<SVGSVGElement>) {
  return playing ? (
    <svg
      width="20"
      height="24"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1V15C0 15.5523 0.447715 16 1 16H3C3.55228 16 4 15.5523 4 15V1C4 0.447715 3.55228 0 3 0H1ZM9 0C8.44771 0 8 0.447715 8 1V15C8 15.5523 8.44771 16 9 16H11C11.5523 16 12 15.5523 12 15V1C12 0.447715 11.5523 0 11 0H9Z"
        className="fill-current"
      />
    </svg>
  ) : (
    <svg
      width="20"
      height="24"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
      {...props}
    >
      <path
        d="M14.3041 7.76283C15.232 8.34867 15.232 9.65133 14.3041 10.2372L2.40195 17.7525C1.37215 18.4027 -5.39299e-08 17.696 0 16.5153L6.86567e-07 1.48469C7.40497e-07 0.304035 1.37215 -0.402716 2.40195 0.247522L14.3041 7.76283Z"
        className="fill-current"
      />
    </svg>
  );
}
