import clsx from "clsx";

import { BasicNftFragment, BasicReleaseFragment, BasicTrackFragment } from "@/graphql/types";
import CardInfo from "@/components/releases/cards/MarketCard/CardInfo";

export interface Props {
  nft: BasicNftFragment | undefined;
  release: BasicReleaseFragment | undefined;
  track: BasicTrackFragment;
  streamSource: string;
}

export default function NftCard({ track, release, nft, streamSource }: Props) {
  return (
    <div
      className={clsx(
        "h-full bg-dark-6 shadow-medium rounded-xl text-white",
        "bg-[image:linear-gradient(158deg,rgba(21,23,32,0.20)_2.36%,rgba(180,191,227,0.02)_98.28%)",
        "relative before:border-2 before:bg-dark-stroke",
        "before:border-gradient before:rounded-xl"
      )}
    >
      <CardInfo
        track={track}
        scarcities={release ? [release.rarity] : []}
        nftNumber={nft?.number}
        hasExclusiveContent={!!release?.unlockableContent}
        streamSource={streamSource}
        fullCard
      />
    </div>
  );
}
