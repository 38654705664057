import { Trans } from "next-i18next";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";

import { DeepNonNullable } from "@/utils/types";
import { AuthenticationDemand, AuthType } from "@/components/connect/AuthModal/types";
import { getDefaultAuthType } from "@/components/connect/AuthModal/helpers";
import LoginForm from "@/components/connect/LoginForm";
import SignupForm from "@/components/connect/SignupForm";
import CloseButton from "@/components/ui/svg/CloseButton";
import { getAnalytics } from "@/utils/analytics";

interface AuthModalContainerProps extends DeepNonNullable<Pick<AuthenticationDemand, "onSuccess">> {
  onClose: () => void;
}

function AuthModalContainer({ onSuccess, onClose }: AuthModalContainerProps) {
  const [authType, setAuthType] = useState<AuthType>(getDefaultAuthType);

  useEffect(() => {
    getAnalytics()?.track("WEB - Auth - Popin - Opened");

    return () => {
      getAnalytics()?.track("WEB - Auth - Popin - Closed");
    };
  }, []);

  return (
    <motion.div
      className={clsx(
        "fixed inset-0 h-full w-full px-5 lg:px-0",
        "flex flex-col justify-center z-9999999 overflow-y-auto"
      )}
      onClick={() => onClose()}
    >
      <motion.div
        initial={{ backdropFilter: "blur(12px) opacity(0)" }}
        animate={{ backdropFilter: "blur(12px) opacity(1)" }}
        exit={{ backdropFilter: "blur(12px) opacity(0)" }}
        className={clsx(
          "absolute inset-0 bg-dark-3/10 -z-10",
          "before:absolute before:inset-0 before:-z-10 before:backdrop-blur-md"
        )}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={(e) => e.stopPropagation()}
        className="lg:w-[676px] max-h-full flex flex-col lg:self-center"
      >
        <div
          className={clsx(
            "w-full rounded-md lg:rounded-xl flex flex-col relative",
            "bg-dark-4/40 bg-dark-glass backdrop-blur-[48px] shadow-larger rounded-xl relative",
            "border-2 border-dark-6/50 p-6 lg:p-10"
          )}
        >
          <button
            className={clsx(
              "flex items-center justify-center text-white",
              "absolute top-3 right-3 lg:right-6 lg:top-6 w-8 h-8 lg:w-10 lg:h-10",
              "rounded-full border-2 border-white/40 lg:border-purple-3/40 lg:hover:border-purple-3"
            )}
            onClick={() => onClose()}
          >
            <CloseButton width="16" height="16" />
          </button>

          {authType === "login" ? (
            <LoginForm
              onClose={onClose}
              onSuccess={onSuccess}
              onSectionChange={() => {
                setAuthType("signup");
                getAnalytics()?.track("WEB - Auth - Popin Section - Changed", { section: "signup" });
              }}
            />
          ) : (
            <SignupForm
              onSuccess={onSuccess}
              onSectionChange={() => {
                setAuthType("login");
                getAnalytics()?.track("WEB - Auth - Popin Section - Changed", { section: "login" });
              }}
            />
          )}
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={clsx(
          "leading-1.2 p-4 rounded-sm bg-black/40 hidden lg:block",
          "text-center lg:text-left text-13 text-white text-opacity-75",
          "absolute bottom-4 right-4 whitespace-pre-wrap"
        )}
      >
        <Trans ns="connect" i18nKey="connect.page.captcha">
          text
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://policies.google.com/privacy"
            className="underline"
          />
          and
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://policies.google.com/terms"
            className="underline"
          />
        </Trans>
      </motion.div>
    </motion.div>
  );
}

export default AuthModalContainer;
