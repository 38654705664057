import posthog, { PostHog } from "posthog-js";
import { useEffect, useState } from "react";
import { Mutex } from "async-mutex";

import {
  POSTHOG_API_KEY,
  RUDDERSTACK_CONFIG_URL,
  RUDDERSTACK_DATA_PLANE_URL,
  RUDDERSTACK_DEST_SDK_URL,
  RUDDERSTACK_KEY,
} from "@/env";

declare global {
  interface Window {
    rudderstack: RudderStack | undefined;
    posthog: PostHog | undefined;
  }
}

type RudderStack = Omit<Awaited<ReturnType<typeof _initializeAnalytics>>, "default">;
/*
 * Initializes Rudderstack's JS SDK which handles
 * the initialization of all other analytics tools.
 *
 * @remarks
 * DO NOT CALL SERVER SIDE.
 */
async function _initializeAnalytics() {
  // WARNING: Checking for typeof window here to
  //          return undefined server side completely
  //          fucks up the types of everything in the
  //          closure... and I have not a single clue why.
  const rudderanalytics = await import("rudder-sdk-js");

  rudderanalytics.load(RUDDERSTACK_KEY, RUDDERSTACK_DATA_PLANE_URL, {
    configUrl: RUDDERSTACK_CONFIG_URL,
    destSDKBaseURL: RUDDERSTACK_DEST_SDK_URL,
    integrations: {
      All: true,
      VWO: {
        // Loaded manually
        loadIntegration: false,
      },
      POSTHOG: {
        // Loaded manually
        loadIntegration: false,
      },
    },
  });

  return rudderanalytics;
}

export const { getAnalytics, initializeAnalytics, getPosthog, useGetPosthog } = (() => {
  const initalizationMutex = new Mutex();
  let _analytics: RudderStack | undefined;

  function _posthogIsReady() {
    const posthog = getPosthog();
    if (!posthog) return false;
    if (Array.isArray(posthog)) return false;
    return typeof posthog.getFeatureFlag === "function";
  }

  return {
    /** Returns the RudderAnalytics SDK */
    getAnalytics() {
      return _analytics;
    },

    /**
     * Initializes the analytics object.
     * Simply returns already initialized analytics
     * on subsequent calls.
     *
     * @remarks
     * Uses a mutex so initialization is only
     * actually ran once.
     */
    async initializeAnalytics() {
      await initalizationMutex.runExclusive(async () => {
        _analytics = _analytics ?? (await _initializeAnalytics());
        if (typeof window !== "undefined") {
          window.rudderstack = _analytics;
        }
      });
      return _analytics;
    },

    /**
     * Returns the PostHog SDK
     *
     * @remarks
     * DO NOT USE FOR EVENT TRACKING.
     * Use analytics instead. This property is
     * only used for posthog only methods like
     * `isFeatureEnabled`.
     */
    getPosthog(): PostHog | undefined {
      if (typeof window === "undefined") return undefined;
      posthog.init(POSTHOG_API_KEY, {
        api_host: `${window.origin}/_sty`,
        capture_pageview: false,
        autocapture: false,
        session_recording: { maskAllInputs: false, maskInputOptions: { password: true } },
        persistence: "localStorage+cookie",
        opt_in_site_apps: true,
      });
      window.posthog = posthog;
      return posthog;
    },

    /**
     * Hook which updates the status of Rudderstack
     * loading up the PostHog library.
     *
     * @remarks
     * Will consider the load a fail after 5s.
     * If the load succeeds after those 5s, status
     * will still change from failed to ready.
     */
    useGetPosthog() {
      const [status, setStatus] = useState<"loading" | "ready" | "failed">("loading");

      useEffect(() => {
        if (_posthogIsReady()) {
          setStatus("ready");
          return undefined;
        }

        // Create a timeout to return status failed
        // if initialization takes too long.
        // This is used to not block feature flag
        // gated components.
        const timeout = setTimeout(() => {
          setStatus("failed");
        }, 5000);

        // Set an interval to check if the posthog
        // library is loaded. If it is, set status
        // to ready.
        const interval = setInterval(() => {
          if (_posthogIsReady()) {
            clearTimeout(timeout);
            setStatus("ready");
            clearInterval(interval);
          }
        }, 100);

        return () => {
          clearInterval(interval);
          clearTimeout(timeout);
        };
      }, []);

      return { posthog: getPosthog(), status };
    },
  };
})();
