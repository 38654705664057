import { createContext, useCallback, useContext, useState } from "react";

import PhoneVerificationModal from "@/components/phone-verification/Modal";
import { PhoneVerificationCallbacks } from "@/components/phone-verification/types";
import { getAnalytics } from "@/utils/analytics";

type Context = {
  demandPhoneVerification({ onSuccess, onQuit }: PhoneVerificationCallbacks): void;
};

const PhoneVerificationContext = createContext<Context>({
  demandPhoneVerification: () => undefined,
});

const PhoneVerificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [onSuccess, setOnSuccess] = useState<PhoneVerificationCallbacks["onSuccess"]>();
  const [onQuit, setOnQuit] = useState<PhoneVerificationCallbacks["onQuit"]>();

  const closeModal = useCallback(() => {
    if (onQuit) onQuit();
    setOnSuccess(undefined);
    setOnQuit(undefined);
  }, [onQuit, setOnSuccess, setOnQuit]);

  const demandPhoneVerification = useCallback(
    ({ onSuccess, onQuit }: PhoneVerificationCallbacks) => {
      if (onSuccess) {
        setOnSuccess(() => () => {
          getAnalytics()?.track("WEB - Auth - Phone Verification - Completed");
          onSuccess();
          setOnSuccess(undefined);
          setOnQuit(undefined);
        });
      }
      if (onQuit) {
        setOnQuit(() => () => {
          getAnalytics()?.track("WEB - Auth - Phone Verification - Abandoned");
          onQuit();
          setOnSuccess(undefined);
          setOnQuit(undefined);
        });
      }
    },
    [setOnSuccess, setOnQuit]
  );

  return (
    <PhoneVerificationContext.Provider value={{ demandPhoneVerification }}>
      <PhoneVerificationModal onSuccess={onSuccess} onClose={closeModal} />
      {children}
    </PhoneVerificationContext.Provider>
  );
};

export const usePhoneVerification = () => useContext(PhoneVerificationContext);
export default PhoneVerificationProvider;
