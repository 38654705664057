import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import getUrlProperties from "@/utils/analytics/getUrlProperties";
import { getPosthog, initializeAnalytics } from "@/utils/analytics";

// Note: This is to whitelist all non dynamic pages
//       which are tracked as a grouped event for analytics
//       Strings ending with "/" are matched as a prefix instead of exact match
const URL_WHITELIST = [
  "/tracks",
  "/dashboard/artist/tracks",
  "/dashboard/label/tracks",
  "/dashboard/label/artists",
  "/profile/settings",
  "/profile/edit",
  "/connect",
  "/connect/external",
  "/verify-email",
  "/market",
  "/market/upcoming",
  "/activity",
  "/reset-password",
  "/artists",
  "/artists/register",
  "/for-artists",
  "/event/",
  "/events/",
  "/leaderboards",
  "/about",
  "/press",
  "/blog",
  "/release/",
  "/stats",
  "/referral",
  "/claim",
  "/playlists",
  "/feed",
];

async function initAnalyticsAndTrackPageView(page: string, properties: { [key: string]: string }) {
  const analytics = await initializeAnalytics();

  getPosthog()?.register({ "Pianity URL": page });

  analytics?.track(`Viewed ${page} page`, properties);
  analytics?.page();
}

/**
 * Registers Posthog analytics events for page views
 *
 * @param path - current url path
 */
export default function usePageAnalytics(path?: string) {
  const router = useRouter();

  const capturedPath = useRef<string | undefined>(undefined);

  // Get first page view event
  // NOTE: NextRouter's routeChangeComplete event is fired
  //       on a page's first load if the path contains
  //       query parameters. No logging needs to be done here
  useEffect(() => {
    if (path && path !== capturedPath.current && path !== "/404" && (path === "/" || doesPathMatch(path))) {
      const [url, properties] = getUrlProperties(router.asPath);
      initAnalyticsAndTrackPageView(url, properties);
      capturedPath.current = router.asPath;
    }
  }, [router.asPath, path]);
}

function doesPathMatch(path: string) {
  [path] = path.split("?");
  return URL_WHITELIST.some((p) => {
    if (p.endsWith("/")) return path.startsWith(p);
    return path === p;
  });
}
