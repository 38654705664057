// import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { FormProvider, useForm } from "react-hook-form";
import { useReCaptcha } from "next-recaptcha-v3";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";

import { getWalletData, WALLETS_URLS, WalletType } from "@/utils/walletConnection";
import { usePhoneVerification } from "@/components/phone-verification/Provider";
import { signWalletDataForLogin } from "@/utils/walletConnection/loginFlow";
import ExternalAuths from "@/components/connect/ExternalAuths";
import CustomButton from "@/components/reusable/CustomButton";
import { connectWithOAuth } from "@/utils/oAuth/connect";
import TwoFACode from "@/components/connect/TwoFACode";
import { isOAuthError } from "@/utils/oAuth/helpers";
import InputBox from "@/components/connect/InputBox";
import { toast } from "@/utils/toasts";
import { getAnalytics } from "@/utils/analytics";
import Link from "@/components/hoc/CustomLink";
import { SS_CLAIM_CODE } from "@/pages/_app";
import { DetailedPrivateUserFragment } from "@/graphql/types";
import { logoutUser } from "@/utils/oAuth/tokens";
import { PIANITY_ENV } from "@/env";

type FormData = {
  email: string;
  password: string;
  code: string;
};

interface LoginFormProps {
  onClose?: () => void;
  onSectionChange: () => void;
  onSuccess: (user: DetailedPrivateUserFragment) => void;
  containerClassName?: string;
  externalAuthsContainerClassName?: string;
}

export default function LoginForm({ onClose, onSectionChange, onSuccess }: LoginFormProps) {
  const { t } = useTranslation(["connect", "notifications"]);
  const router = useRouter();
  const isAuthModal = !router.pathname.startsWith("/connect");

  const [processing, setProcessing] = useState(false);
  // Keeps the email of the user requiring 2FA
  const [emailRequiring2FA, setEmailRequiring2FA] = useState<string>();

  const formMethods = useForm<FormData>();
  const { setError, handleSubmit } = formMethods;

  const { executeRecaptcha } = useReCaptcha();
  //   const { getData: getFingerprintData } = useVisitorData(undefined, { immediate: false });

  const { demandPhoneVerification } = usePhoneVerification();

  function handleOAuthSuccess(user: DetailedPrivateUserFragment) {
    if (user.limited) {
      demandPhoneVerification({
        onSuccess() {
          onSuccess(user);
        },
        onQuit() {
          logoutUser();
        },
      });
    } else {
      onSuccess(user);
    }
  }

  //   async function getVisitorID(wallet?: boolean) {
  //     const fingerprint = await getFingerprintData().catch((error) => ({ visitorId: null, error }));
  //     const { visitorId } = fingerprint;
  //     if (!visitorId) {
  //       getAnalytics()?.track("WEB - Auth - Fingerprint - Failed", {
  //         for: `${wallet ? "Wallet" : "Basic"} Login`,
  //         error: "error" in fingerprint && fingerprint.error,
  //         authPopin: isAuthModal,
  //       });
  //       toast.error(t("notifications:notifications.messages.errors.default"));
  //       setProcessing(false);
  //     }

  //     return visitorId;
  //   }

  /**
   * Logs in the user using the basic OAuth method.
   *
   * @param inputs - The form inputs
   */
  async function onSubmit(inputs: FormData) {
    setProcessing(true);

    const isClaimingBounty = !!sessionStorage.getItem(SS_CLAIM_CODE);
    if (!emailRequiring2FA) {
      getAnalytics()?.track("Login Submitted", {
        email: inputs.email,
        arConnect: false,
        metamask: false,
        isClaimingBounty,
        authPopin: isAuthModal,
      });
    }

    // Get the recaptcha token
    const action = emailRequiring2FA ? "2fa" : "login";
    const token = await executeRecaptcha(action);
    // Get the fingerprint data
    // const visitorId = await getVisitorID();
    // if (!visitorId) return;

    // Log in the user using the basic OAuth method.
    const login = await connectWithOAuth("login", "basic", {
      ...inputs,
      two_factor_code: inputs.code,
      // Note: Captcha doesn't work locally
      captcha_token: PIANITY_ENV === "local" ? "" : token,
      // fingerprint: visitorId,
      fingerprint: "not_used_anymore",
    });

    if (login) {
      if (!isOAuthError(login)) {
        getAnalytics()?.track("Login Succeeded", {
          arConnect: false,
          metamask: false,
          isClaimingBounty,
          limited: login.limited,
          authPopin: isAuthModal,
        });
        handleOAuthSuccess(login);
        return;
      } else if (login.error === "requires_2fa") {
        setEmailRequiring2FA(inputs.email);
        getAnalytics()?.track("Login 2FA Required", {
          arConnect: false,
          metamask: false,
          isClaimingBounty,
          authPopin: isAuthModal,
        });
      } else if (login.error === "invalid_2fa") {
        setError("code", { type: "custom", message: t("connect:connect.forms.errors.2fa.invalid") });
      }
    }
    setProcessing(false);
  }

  /**
   * Logs in the user using the wallet (ArConnect) OAuth method.
   */
  async function walletLogin(wallet: WalletType) {
    const isClaimingBounty = !!sessionStorage.getItem(SS_CLAIM_CODE);
    getAnalytics()?.track("Login Submitted", {
      arConnect: wallet === "arconnect",
      metamask: wallet === "metamask",
      isClaimingBounty,
      authPopin: isAuthModal,
    });

    try {
      const walletData = await getWalletData(wallet);

      if (!walletData) {
        window.open(WALLETS_URLS[wallet], "_blank");
        toast.warn(t(`connect:connect.notifications.errors.wallet.none_${wallet}`), {
          autoClose: false,
        });
        return;
      }

      const signedWalletData = await signWalletDataForLogin(walletData);

      //   const visitorId = await getVisitorID();
      //   if (!visitorId) return;

      const authenticationResult = await connectWithOAuth("login", "wallet", {
        ...signedWalletData,
        // fingerprint: visitorId,
        fingerprint: "not_used_anymore",
      });

      if (authenticationResult && !("error" in authenticationResult)) {
        getAnalytics()?.track("Login Succeeded", {
          arConnect: wallet === "arconnect",
          metamask: wallet === "metamask",
          isClaimingBounty,
          authPopin: isAuthModal,
        });
        handleOAuthSuccess(authenticationResult);
      }
    } catch {
      toast.error(t(`connect:connect.notifications.errors.wallet.cancelled_${wallet}`));
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-6 lg:mb-8">
        {/* MARK: Login/Signup buttons */}
        <div className="mb-5 lg:mb-7 flex items-center">
          <div className="mr-3 lg:mr-4 text-22 leading-1 lg:text-2xl lg:leading-1 font-medium">
            {t("connect:connect.forms.buttons.login")}
          </div>

          <button
            onClick={() => onSectionChange()}
            className="text-22 leading-1 lg:text-2xl lg:leading-1 font-medium opacity-40"
          >
            {t("connect:connect.forms.buttons.signup")}
          </button>
        </div>

        {/* MARK: Inputs */}
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)} key="login-form" className="flex flex-col">
            {emailRequiring2FA ? (
              <TwoFACode email={emailRequiring2FA} />
            ) : (
              <div className="grid grid-cols-1 gap-3">
                <InputBox
                  placeholder={t("connect:connect.forms.inputs.email")}
                  autoComplete="email"
                  type="email"
                  name="email"
                  required
                />
                <InputBox
                  placeholder={t("connect:connect.forms.inputs.password")}
                  autoComplete="current-password"
                  type="password"
                  name="password"
                  required
                />
              </div>
            )}

            <div className="mt-5 flex items-center justify-end">
              {/* MARK: Forgot password */}
              {!emailRequiring2FA && (
                <Link href="/reset-password">
                  <a
                    onClick={() => onClose?.()}
                    className="mr-auto shrink-0 text-sm opacity-60 underline w-max cursor-pointer"
                  >
                    {t("connect:connect.forms.buttons.forgot")}
                  </a>
                </Link>
              )}

              {/* MARK: Normal login */}
              <CustomButton
                base
                lowercase
                label={t("connect:connect.forms.buttons.login")}
                type="submit"
                disabled={processing}
                loading={processing}
              />
            </div>
          </form>
        </FormProvider>
      </div>

      {!emailRequiring2FA && <div className="h-0.5 bg-dark-stroke" />}

      {!emailRequiring2FA && <ExternalAuths onWalletAuth={walletLogin} />}
    </div>
  );
}
