import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AnimatePresence } from "framer-motion";
import { useLazyQuery } from "@apollo/client";
import dynamic from "next/dynamic";

import { getAudioEvents, createAudio } from "@/utils/audioPlayer";
import { getAnalytics } from "@/utils/analytics";
import { GetTrackWaveformDataDocument, BasicTrackFragment } from "@/graphql/types";
import { DEFAULT_WAVEFORM_VALUES } from "@/utils/constants";
import { useWindowSize } from "@/components/WindowSizeProvider";

const AudioPlayer = dynamic(async () => (await import("@/components/AudioPlayer")).AudioPlayer);

export const LS_PLAYER_REPEAT = "pianity-player-repeat";

export type AudioPlayerTrack = BasicTrackFragment & {
  /** ID of the track's currently played playlist */
  playlistId?: string;
  /** Seek the track to this timestamp */
  startPlayingAt?: number;
  /** Set to true when slug should be used for toplist url */
  hasWaitlistSlug?: boolean;
  /** Where the play action originated from */
  streamSource: string;
};

type MusicPlayerContext = {
  /** Current loaded audio element */
  audio?: HTMLAudioElement;
  playlist?: AudioPlayerTrack[];
  playTracks: (
    tracks: AudioPlayerTrack[],
    index?: number,
    autoPlay?: boolean
  ) => HTMLAudioElement | undefined;
  playNextTrack: (previous?: boolean) => HTMLAudioElement | undefined;
  addToQueue: (tracks: AudioPlayerTrack[]) => void;
  /** ID of the track loaded in the audio element */
  musicId?: string;
  /** Index in the playlist of the current audio */
  musicIndex?: number;
  /** ID of the playlist currently playing */
  playlistId?: string;
  /** Boolean indicating if a song is being played or not */
  isPlaying: boolean;
  volume: number;
  setVolume: Dispatch<SetStateAction<number>>;
  toggleVolume: () => void;
  waveformArray?: number[];
  hidePlayer: boolean;
  showFullPlayer: boolean;
  repeat: boolean;
  setRepeat: React.Dispatch<React.SetStateAction<boolean>>;
  seekTime?: number;
  setSeekTime: Dispatch<SetStateAction<number | undefined>>;
  setHidePlayer: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFullPlayer: React.Dispatch<React.SetStateAction<boolean>>;
};

const MusicPlayerContext = createContext<MusicPlayerContext>({
  playTracks: () => undefined,
  playNextTrack: () => undefined,
  addToQueue: () => undefined,
  isPlaying: false,
  volume: 0.75,
  setVolume: () => undefined,
  toggleVolume: () => undefined,
  hidePlayer: false,
  showFullPlayer: true,
  repeat: false,
  setRepeat: () => undefined,
  setSeekTime: () => undefined,
  setHidePlayer: () => undefined,
  setShowFullPlayer: () => undefined,
});

export const useAudioPlayer = () => useContext(MusicPlayerContext);

/**
 * JSX Component which wraps its children with the `MusicPlayerContext`
 * and its accompanying AudioPlayer component
 *
 * @remarks
 * Adds a 2.5rem bottom padding to the HTML body when the player is open
 *
 * @param props - component props
 */
export function AudioPlayerProvider({ children }: PropsWithChildren<unknown>) {
  const { width: screenWidth } = useWindowSize();

  const [playlist, setPlaylist] = useState<AudioPlayerTrack[]>();
  const [musicIndex, setMusicIndex] = useState(0);
  const [musicId, setMusicId] = useState<string>();
  const [playlistId, setPlaylistId] = useState<string>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [seekTime, setSeekTime] = useState<number>();
  const [hidePlayer, setHidePlayer] = useState(false);
  const [showFullPlayer, setShowFullPlayer] = useState(true);
  const [audio, setAudio] = useState<HTMLAudioElement>();
  const [waveformArray, setWaveformArray] = useState<number[]>();
  const [volume, setVolume] = useState(() => {
    if (typeof window === "undefined") return 0.75;

    const userVolume = localStorage.getItem("volume_preference");
    return userVolume === null ? 0.75 : +userVolume;
  });
  const [savedVolume, setSavedVolume] = useState(volume);

  const [repeat, setRepeat] = useState(
    typeof window === "undefined" ? false : localStorage.getItem(LS_PLAYER_REPEAT) === "1"
  );

  const [getWaveformData] = useLazyQuery(GetTrackWaveformDataDocument);

  /**
   * Plays the first track in the playlist
   *
   * @param tracks - array of tracks to play @defaultValue playlist
   * @param index - index of the track to play @defaultValue 0
   * @param autoPlay - set to `false` to not play the audio element @defaultValue true
   *
   * @returns audio element used to play the track or undefined
   */
  function playTracks(
    tracks: AudioPlayerTrack[] | undefined = playlist,
    index = 0,
    autoPlay = true
  ): HTMLAudioElement | undefined {
    setMusicIndex(index);
    setPlaylist(tracks);

    if (tracks && tracks.length > 0) {
      const track = tracks[index];

      if (audio) {
        const flacUrl = track.audio?.flac.url;
        if (
          (index === musicIndex && flacUrl === audio.currentSrc) ||
          track.audio?.mp3.url === audio.currentSrc
        ) {
          if (audio.paused) audio.play();
          else audio.pause();
          return undefined;
        }
        audio.pause();
      }

      getAnalytics()?.track("Audio Player Music Set", {
        track: track.slug,
        artists: track.artists.map((a) => a.slug),
        start: track.startPlayingAt,
        autoPlay,
      });

      const newAudio = createAudio({
        track,
        ...getAudioEvents({
          track,
          setIsPlaying,
          musicIndex,
          setMusicIndex,
          playlist: tracks,
          playlistId: track.playlistId,
          setPlaylist,
        }),
      });

      if (newAudio) {
        if (autoPlay) newAudio.play();
        setMusicId(track.id);
        setPlaylistId(track.playlistId);
      }

      setAudio(newAudio);
      return newAudio;
    } else {
      setPlaylist(undefined);
      setMusicId(undefined);
      setPlaylistId(undefined);
      setMusicIndex(0);
    }

    return undefined;
  }

  /**
   * Adds tracks to the current playlist. Does nothing
   * if there is no playlist.
   */
  function addToQueue(tracks: AudioPlayerTrack[]) {
    // Use setState methods to get the latest state... yes, I know.
    let newPlaylist: AudioPlayerTrack[] = [];
    setPlaylist((p) => {
      newPlaylist = [...(p ?? []), ...tracks];
      return newPlaylist;
    });

    // Update the event listeners with new playlist

    let index = 0;
    let audio: HTMLAudioElement | undefined;
    setMusicIndex((i) => {
      index = i;
      return i;
    });
    setAudio((a) => {
      audio = a;
      return a;
    });

    const track = newPlaylist[index];
    if (track && audio) {
      const { onplay, onpause, onended } = getAudioEvents({
        track,
        setIsPlaying,
        musicIndex,
        setMusicIndex,
        playlist: newPlaylist,
        playlistId: track.playlistId,
        setPlaylist,
      });

      audio.onplay = onplay;
      audio.onpause = onpause;
      audio.onended = onended;
    }
  }

  // Saves the repeat in local storage
  useEffect(() => {
    localStorage.setItem(LS_PLAYER_REPEAT, repeat ? "1" : "0");
  }, [repeat]);

  // NOTE: Used to play next/previous songs in playlist
  //       Skipped if track already set by `playTrack`
  //       or if no next track
  // WARNING: Do not use setMusicIndex on user action to skip/previous.
  //          Use playTracks with an index instead. This is because of
  //          a bug on safari and iOS which prevents autoplay through
  //          a react hook on user action.
  useEffect(() => {
    if (playlist && playlist.length > 1) {
      const track = playlist[musicIndex];

      if (musicId === track?.id) return;

      if (!track) {
        setMusicId(undefined);
        setPlaylistId(undefined);
        setAudio(undefined);
        setMusicIndex(0);
        return;
      }

      const newAudio = createAudio({
        track,
        ...getAudioEvents({
          track,
          setIsPlaying,
          musicIndex,
          setMusicIndex,
          playlist,
          playlistId: track.playlistId,
          setPlaylist,
        }),
      });

      if (newAudio) {
        audio?.pause();
        newAudio.play();
        setMusicId(track.id);
        setPlaylistId(track.playlistId);
      }
      setAudio(newAudio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlist, musicIndex, audio, musicId]);

  // Adds padding to the body so nothing is hidden behind the player
  useEffect(() => {
    const footer = document.getElementsByTagName("body")[0];
    footer.style.paddingBottom = playlist ? "2.5rem" : "0";
  }, [playlist]);

  // Fetches waveform data for current playing track and screen size
  useEffect(() => {
    const track = playlist?.[musicIndex];
    if (!track) {
      setWaveformArray(undefined);
      return;
    }
    getWaveformData({ variables: { trackId: track.id } }).then((query) => {
      const waveformData =
        query.data?.tracks.results[0]?.audio?.visuals.waveformData ?? DEFAULT_WAVEFORM_VALUES;

      let waveform = waveformData.find((w) => w.size === 75) ?? waveformData.find((w) => w.size === 100);
      if (screenWidth < 600)
        waveform =
          waveformData.find((w) => w.size === 25) ?? waveformData.find((w) => w.size === 50) ?? waveform;
      else if (screenWidth < 1080) waveform = waveformData.find((w) => w.size === 50) ?? waveform;
      else if (screenWidth > 1480) waveform = waveformData.find((w) => w.size === 100) ?? waveform;
      else if (screenWidth > 1920) waveform = waveformData.find((w) => w.size === 150) ?? waveform;

      setWaveformArray(waveform?.values);
    });
  }, [playlist, musicIndex, screenWidth, getWaveformData]);

  // Attach to window a keypress event listenning for space bar
  // to play/pause the audio element when not hidden
  useEffect(() => {
    if (audio && !hidePlayer) {
      const onKeyPress = (e: KeyboardEvent) => {
        // Ignore when focus is on a text input
        if ((e.target as HTMLElement).tagName === "INPUT" || (e.target as HTMLElement).tagName === "TEXTAREA")
          return;

        if (e.key === " ") {
          e.preventDefault();
          if (audio.paused) audio.play();
          else audio.pause();
        }
      };
      window.addEventListener("keydown", onKeyPress);

      return () => {
        window.removeEventListener("keydown", onKeyPress);
      };
    }

    return undefined;
  }, [audio, hidePlayer]);

  function toggleVolume() {
    if (volume === 0) {
      setVolume(savedVolume);
      getAnalytics()?.track("Audio Player Music Volume Set", { volume: savedVolume });
    } else {
      setSavedVolume(volume);
      setVolume(0);
      getAnalytics()?.track("Audio Player Music Volume Muted");
    }
  }

  return (
    <MusicPlayerContext.Provider
      value={{
        audio,
        playlist,
        playTracks: (tracks, index, autoPlay) => {
          return playTracks(tracks, index, autoPlay);
        },
        playNextTrack: (previous = false) => {
          let index = musicIndex + (previous ? -1 : 1);
          if (playlist && index < 0) index = playlist.length - 1;
          if (playlist && index >= playlist.length) index = 0;

          return playTracks(undefined, index);
        },
        addToQueue,
        musicId,
        musicIndex,
        playlistId,
        isPlaying,
        volume,
        setVolume,
        toggleVolume,
        waveformArray,
        showFullPlayer,
        hidePlayer,
        repeat,
        setRepeat,
        seekTime,
        setSeekTime,
        setHidePlayer,
        setShowFullPlayer,
      }}
    >
      {children}
      <AnimatePresence>
        {!hidePlayer && playlist?.[musicIndex] && <AudioPlayer track={playlist[musicIndex]} />}
      </AnimatePresence>
    </MusicPlayerContext.Provider>
  );
}
