import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import clsx from "clsx";
import { ReactNode } from "react";

type CheckboxProps<T extends FieldValues> = {
  checked?: boolean;
  label: ReactNode | null;
  labelClassName?: string;
  required?: boolean;
  showRequired?: boolean;
  register?: UseFormRegister<T>;
  name?: Path<T>;
  setChecked?: (checked: boolean) => void;
  disabled?: boolean;
  rtl?: boolean;
};

// TODO: Clean up...
export default function CustomCheckbox<T extends FieldValues>({
  checked,
  label,
  labelClassName,
  name,
  required,
  showRequired,
  disabled,
  rtl,
  register,
  setChecked,
}: CheckboxProps<T>) {
  return (
    <div className={clsx("relative", disabled && "pointer-events-none")}>
      {required && !register && (
        <input
          // Used to trigger default form required validation
          className="absolute opacity-0 -z-5"
          type="checkbox"
          required={required}
          disabled={disabled}
          checked={checked}
          onChange={() => undefined}
        />
      )}

      <label
        className={clsx(
          "z-10 group-one relative cursor-pointer flex items-center",
          rtl ? "flex-row-reverse" : "flex-row"
        )}
        onClick={setChecked ? () => setChecked(!checked) : undefined}
      >
        {register && (
          <input
            className="absolute opacity-0 -z-5"
            type="checkbox"
            required={required}
            disabled={disabled}
            {...(name ? register(name) : {})}
          />
        )}

        <span
          className={clsx(
            "rounded-3px w-5 h-5 p-0.25 absolute cursor-pointer flex",
            "items-center justify-center",
            checked
              ? "bg-gradient-to-br from-purple-3 to-purple"
              : "bg-dark-stroke border border-purple-4 group-one-hover:bg-dark-hover"
          )}
        >
          {checked && (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
              <path
                d="M1 4L4.5 7.5L11 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
        <div className={clsx(rtl ? "mr-8" : "ml-8", "text-sm", labelClassName)}>
          {label}
          {showRequired && <span className="text-red"> *</span>}
        </div>
      </label>
    </div>
  );
}
