import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import CloseButton from "@/components/ui/svg/CloseButton";
import { useWindowSize } from "@/components/WindowSizeProvider";
import { CHAT_SOURCE_IFRAME } from "@/env";
import { useSetModalStyles } from "@/utils/hooks/useSetModalStyles";

type Props = {
  /** Toggle to open the chat */
  show: boolean;
  /** Close the chat (needs to set `show` to false) */
  closeModal: () => void;
};

export default function ChatModal(props: Props) {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  useSetModalStyles(props.show);

  return (
    <AnimatePresence>
      {props.show && (
        <motion.div
          onClick={() => props.closeModal()}
          initial="closed"
          animate="open"
          exit="closed"
          variants={{ closed: { opacity: 0 }, open: { opacity: 1 } }}
          className={clsx(
            "flex justify-end items-start fixed inset-0 sm:p-6 h-screen",
            "w-screen z-9999999 transition-visibility ease-quart-out",
            "duration-800"
          )}
        >
          <div
            className={clsx(
              "absolute inset-0 w-full h-full bg-wallet transition",
              "ease-quart-out duration-1000 opacity-50"
            )}
          />
          <motion.div
            variants={isMobile ? undefined : { closed: { x: 60 }, open: { x: 0 } }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className={clsx(
              "relative h-full w-full sm:w-120 bg-[#101219] rounded-md flex",
              "flex-col ease-quart-out duration-800 transition-spacing",
              "overflow-hidden"
            )}
          >
            <div className="w-full h-full flex flex-col">
              <motion.div
                className={clsx(
                  "w-full py-2 px-5 sm:px-8 flex items-center justify-end gap-5",
                  "shrink-0 lg:hidden"
                )}
              >
                <button
                  onClick={props.closeModal}
                  className={clsx(
                    "text-white cursor-pointer flex justify-center items-center",
                    "rounded-full box-border border-2 border-white",
                    "border-opacity-40 h-6 w-6 z-60"
                  )}
                >
                  <CloseButton width="16" height="16" />
                </button>
              </motion.div>
              <iframe src={CHAT_SOURCE_IFRAME} className={"w-full h-full grow"} />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
