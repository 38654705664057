import { getAnalytics, getPosthog } from "@/utils/analytics";

export function getNumberFormatter(locales?: string | string[], opt?: Intl.NumberFormatOptions) {
  try {
    return new Intl.NumberFormat(locales, opt);
  } catch {
    try {
      return new Intl.NumberFormat("en-US");
    } catch (error: unknown) {
      getAnalytics()?.track("ERROR: Intl ICU Initialisation", {
        exception:
          (error instanceof Error ? error.stack : error) +
          `\n\nPostHog Distinct ID: ${getPosthog()?.get_distinct_id()}`,
        message: error instanceof Error ? error.message : "unknown",
      });
      // WARNING: Temporary fix for unavailable Intl options...
      return {
        format: (number: number, _?: unknown) => "" + number,
      };
    }
  }
}

/**
 * When number has too many significant digits,
 * use k to divid by thousands or M to divide
 * by milions (10,000 to 10k).
 *
 * @param number - Number to format
 *
 * @returns Formatted number and suffix
 */
export function suffixLargeNumber(number: number) {
  let suffix = "";
  if (number > 999_999) {
    suffix = "M";
    number /= 1_000_000;
  } else if (number > 999) {
    suffix = "k";
    number /= 1_000;
  }

  return { number, suffix };
}
