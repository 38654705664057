import { useTranslation } from "next-i18next";
import { motion } from "framer-motion";
import clsx from "clsx";
import Image from "next/image";

import { getScarcityCount } from "@/utils/mintBuilder";
import { useWindowSize } from "@/components/WindowSizeProvider";
import { Rarity } from "@/graphql/types";
import { getScarcityKey } from "@/utils/scarcity";

interface Props {
  scarcities: Rarity[];
}

export default function ScarcityBadge({ scarcities }: Props) {
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  const { t } = useTranslation("common");

  return (
    // TODO: Fix animation ?
    <div className="z-50 absolute top-2 left-2 flex items-center">
      {scarcities.reverse().map((scarcity) => {
        const lowercased = getScarcityKey(scarcity);
        return (
          <motion.div
            key={scarcity}
            layout="size"
            className={clsx(
              "bg-dark-4/50 rounded-full lg:cursor-pointer z-10",
              "flex items-center justify-center group-unique shadow-medium backdrop-blur-sm",
              "-ml-3 first:ml-0 min-w-8 lg:min-w-11 h-8 lg:h-11 lg:hover:pr-5 lg:hover:pl-2",
              "before:border-[1.5px] before:bg-dark-stroke",
              "before:border-gradient before:rounded-full"
            )}
          >
            <Image
              src={`/assets/icons/rarity/${lowercased}.png`}
              width={isMobile ? 22 : 28}
              height={isMobile ? 22 : 28}
              alt=""
            />

            <div className="hidden lg:group-unique-hover:flex flex-col ml-2">
              <div className="text-sm leading-1.25 font-medium mb-1">{t(`common.rarity.${lowercased}`)}</div>
              <div className="text-10 leading-0.75 uppercase opacity-60 mb-1">
                {t(`common.rarity.edition`, { count: getScarcityCount(scarcity) })}
              </div>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}
