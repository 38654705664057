import { useEffect } from "react";
import { useWindowSize } from "@/components/WindowSizeProvider";

export function useSetModalStyles(show: boolean) {
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  useEffect(() => {
    const root = document.documentElement;
    if (show) {
      document.body.style.overflowY = "hidden";
      // Set the notification container above the modal
      root.style.setProperty("--toast-container-z-index", "99999999");
      root.style.setProperty("--toast-container-top", "16px");
    } else {
      document.body.style.overflowY = "unset";
    }

    return () => {
      document.body.style.overflowY = "unset";
      // Set notification container back to defaults
      root.style.setProperty("--toast-container-z-index", "999");
      root.style.setProperty("--toast-container-top", isMobile ? "64px" : "96px");
    };
  }, [show, isMobile]);
}
