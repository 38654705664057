import { ApolloClient, NormalizedCacheObject, from, split } from "@apollo/client/core/";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { RetryLink } from "@apollo/client/link/retry";
import { useMemo } from "react";

import { versionCheckLink } from "@/apollo/links/versionCheck";
import { authLink, createWebSocketLink } from "@/apollo/links/auth";
import getApolloInMemoryCache from "@/apollo/cache";
import { errorLink } from "@/apollo/links/error";
import { API_URI, NEXT_ENV } from "@/env";

const GRAPHQL_URI = API_URI;
const GRAPHQL_SSR_URI = process.env.SERVER_API_URI ?? GRAPHQL_URI;

let apolloClient: ApolloClient<NormalizedCacheObject>;

export function useApollo(initialState?: NormalizedCacheObject) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return createApolloClient();

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

// Retry Server Time outs
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const retryIf = (error: any) => {
  return !!error && [504, 524].includes(error.statusCode);
};

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
});

export function buildApolloLinks() {
  const webSocketLink = createWebSocketLink();
  const splitLink = webSocketLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === "OperationDefinition" && definition.operation === "subscription";
        },
        webSocketLink,
        createUploadLink({ uri: GRAPHQL_URI })
      )
    : createUploadLink({ uri: GRAPHQL_SSR_URI });

  return from([retryLink, errorLink, authLink, versionCheckLink, splitLink]);
}

function createApolloClient() {
  const link = buildApolloLinks();

  return new ApolloClient({
    ssrMode: typeof window === "undefined", // set to true for SSR
    link,
    cache: getApolloInMemoryCache(),
    connectToDevTools: NEXT_ENV !== "production",
    defaultOptions: {
      query: { errorPolicy: "none" },
      mutate: { errorPolicy: "none" },
    },
  });
}
