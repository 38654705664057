import clsx from "clsx";

interface Props {
  onClick: () => void;
  children: React.ReactNode;
}

export default function ConnectButton({ onClick, children }: Props) {
  return (
    <button
      type="button"
      className={clsx(
        "w-full h-10 bg-dark-6/30 px-3 shadow-small flex items-center",
        "justify-center rounded-full lg:hover:bg-dark-hover text-white",
        "relative before:bg-dark-stroke",
        "before:border-[1.5px] before:border-gradient before:rounded-full"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
