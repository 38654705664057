import { Dispatch, SetStateAction, createContext, useCallback, useContext, useState } from "react";

import { WalletSection } from "@/components/Wallet/types";
import WalletModal from "@/components/Wallet/Modal";

type Context = {
  setShowWallet: Dispatch<SetStateAction<boolean | WalletSection>>;
};

const WalletContext = createContext<Context>({
  setShowWallet: () => undefined,
});

const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const [showWallet, setShowWallet] = useState<boolean | WalletSection>(false);

  const closeModal = useCallback(() => {
    setShowWallet(false);
  }, [setShowWallet]);

  return (
    <WalletContext.Provider value={{ setShowWallet }}>
      <WalletModal
        show={!!showWallet}
        section={typeof showWallet === "boolean" ? undefined : showWallet}
        closeModal={closeModal}
      />
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
export default WalletProvider;
