import { toast as toastify, ToastContent, ToastOptions } from "react-toastify";
import { ReactNode } from "react";
import clsx from "clsx";

import NotificationCard from "@/components/notifications/NotificationCard";
import CheckmarkIcon from "@/components/ui/svg/CheckmarkIcon";

export type NotificationOptions = {
  dateString?: string;
  tag?: string;
  level?: "info" | "important" | "error" | "admin";
  link?: string;
  tagClassName?: string;
  iconClassName?: string;
  imageUrl?: string | null;
};

export function showNotification(content: ReactNode, options?: NotificationOptions & ToastOptions) {
  toastify(<NotificationCard {...options}>{content}</NotificationCard>, {
    ...options,
    containerId: options?.containerId ?? "notifications",
  });
}

export function showNotificationCard(card: ReactNode, options?: ToastOptions) {
  toastify(card, options);
}

function showErrorToast(content: ToastContent, options?: ToastOptions) {
  toastify.error(content, {
    icon: () => (
      <div
        className={clsx(
          "w-8 h-8 relative rounded-md flex items-center justify-center shrink-0",
          "text-red-dark bg-[#FFD3DB] font-medium text-lg leading-1"
        )}
      >
        {"!"}
      </div>
    ),
    ...options,
  });
}

function showWarningToast(content: ToastContent, options?: ToastOptions) {
  toastify.warn(content, {
    icon: () => (
      <div
        className={clsx(
          "w-8 h-8 relative rounded-md flex items-center justify-center shrink-0",
          "text-orange bg-orange-5 font-medium text-lg leading-1"
        )}
      >
        {"!"}
      </div>
    ),
    ...options,
  });
}

function showInfoToast(content: ToastContent, options?: ToastOptions) {
  toastify.info(content, {
    icon: false,
    ...options,
  });
}

function showSuccessToast(content: ToastContent, options?: ToastOptions) {
  toastify.success(content, {
    icon: () => (
      <div
        className={clsx(
          "w-8 h-8 relative rounded-md flex items-center justify-center shrink-0",
          "text-green-3 bg-green-5"
        )}
      >
        <CheckmarkIcon width="18" height="18" />
      </div>
    ),
    ...options,
  });
}

export const toast = {
  error: showErrorToast,
  warn: showWarningToast,
  info: showInfoToast,
  success: showSuccessToast,
};
