import { ReCaptchaProvider as ReCaptcha } from "next-recaptcha-v3";
import { AppProps } from "next/app";
import { PropsWithChildren } from "react";

import { PROP_KEY_DISABLE_CAPTCHA } from "@/utils/constants";

interface ReCaptchaProviderProps {
  pageProps: AppProps["pageProps"];
}

function ReCaptchaProvider({ pageProps, children }: PropsWithChildren<ReCaptchaProviderProps>) {
  const disableCaptcha = PROP_KEY_DISABLE_CAPTCHA in pageProps && !!pageProps[PROP_KEY_DISABLE_CAPTCHA];

  if (disableCaptcha) return <>{children}</>;
  return (
    <ReCaptcha useEnterprise useRecaptchaNet defer>
      {children}
    </ReCaptcha>
  );
}

export default ReCaptchaProvider;
