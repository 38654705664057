import { SVGProps } from "react";

export default function ExclusiveIcon(props: SVGProps<SVGSVGElement> & { unlocked?: boolean }) {
  if (props.unlocked) {
    const { width = "20", height = "22", unlocked: _, ...rest } = props;
    return (
      <svg
        {...{ width, height }}
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 22"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0444 0.0625C10.3896 0.0625 10.6694 0.342322 10.6694 0.6875V4.4375C10.6694 4.78268 10.3896 5.0625 10.0444 5.0625C9.69922 5.0625 9.4194 4.78268 9.4194 4.4375V0.6875C9.4194 0.342322 9.69923 0.0625 10.0444 0.0625ZM4.76331 2.37944C4.51923 2.13536 4.1235 2.13536 3.87942 2.37944C3.63535 2.62352 3.63535 3.01925 3.87942 3.26333L6.53107 5.91498C6.77515 6.15905 7.17088 6.15905 7.41496 5.91498C7.65904 5.6709 7.65904 5.27517 7.41496 5.03109L4.76331 2.37944ZM3.125 7.40625C1.39911 7.40625 0 8.80536 0 10.5312V12.0938H7.57876C7.85629 11.0155 8.8351 10.2188 10 10.2188C11.1649 10.2188 12.1437 11.0155 12.4212 12.0938H20V10.5312C20 8.80536 18.6009 7.40625 16.875 7.40625H3.125ZM0 20.375V13.3438H7.57876C7.77651 14.1121 8.33032 14.7374 9.0538 15.0335L8.05046 17.9299C7.94498 18.2344 8.17112 18.5521 8.49339 18.5521H11.5066C11.8289 18.5521 12.055 18.2344 11.9495 17.9299L10.9462 15.0335C11.6697 14.7374 12.2235 14.1121 12.4212 13.3438H20V20.375C20 21.2379 19.3004 21.9375 18.4375 21.9375H1.5625C0.699555 21.9375 0 21.2379 0 20.375ZM16.2094 3.26331C16.4535 3.01923 16.4535 2.6235 16.2094 2.37942C15.9653 2.13535 15.5696 2.13535 15.3255 2.37942L12.6739 5.03107C12.4298 5.27515 12.4298 5.67088 12.6739 5.91496C12.9179 6.15904 13.3137 6.15904 13.5578 5.91496L16.2094 3.26331Z"
          className="fill-current"
        />
      </svg>
    );
  }

  const { width = "20", height = "18", ...rest } = props;
  return (
    <svg {...{ width, height }} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18" fill="none">
      <path
        d="M3.125 0.71875C1.39911 0.71875 0 2.11786 0 3.84375L0 6.8125H7.57876C7.85629 5.73423 8.8351 4.9375 10 4.9375C11.1649 4.9375 12.1437 5.73423 12.4212 6.8125H20V3.84375C20 2.11786 18.6009 0.71875 16.875 0.71875H3.125Z"
        className="fill-current"
      />
      <path
        d="M0 15.7188V8.0625H7.57876C7.77651 8.83082 8.33033 9.45619 9.05381 9.75224L8.05047 12.6486C7.94498 12.9532 8.17113 13.2708 8.49339 13.2708H11.5066C11.8289 13.2708 12.055 12.9532 11.9495 12.6486L10.9462 9.75224C11.6697 9.45619 12.2235 8.83082 12.4212 8.0625H20V15.7188C20 16.5817 19.3004 17.2812 18.4375 17.2812H1.5625C0.699555 17.2812 0 16.5817 0 15.7188Z"
        className="fill-current"
      />
    </svg>
  );
}
