import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { getDefaultAuthType } from "@/components/connect/AuthModal/helpers";
import { AuthType } from "@/components/connect/AuthModal/types";
import LoginForm from "@/components/connect/LoginForm";
import SignupForm from "@/components/connect/SignupForm";
import { removeQueryParams } from "@/utils/router";
import { SS_OAUTH_REDIRECT_URL } from "@/utils/constants";
import { getAnalytics } from "@/utils/analytics";
import { BasicTrackFragment } from "@/graphql/types";

interface BountyClaimAuthFormProps {
  track: BasicTrackFragment;
  claimCode: string;
  onClose: () => void;
}

function BountyClaimAuthForm({ track, claimCode, onClose }: BountyClaimAuthFormProps) {
  const router = useRouter();

  const [authType, setAuthType] = useState<AuthType>(getDefaultAuthType());

  useEffect(() => {
    // Set the redirect url to complete the checkout after external OAuth
    const redirect = encodeURIComponent(`/${track.artists[0]?.slug}/${track.slug}?cc=${claimCode}`);
    sessionStorage.setItem(SS_OAUTH_REDIRECT_URL, redirect);
  }, [claimCode, track, router]);

  useEffect(() => {
    getAnalytics()?.track("WEB - Auth - Popin - Opened", { bountyClaim: true });

    return () => {
      getAnalytics()?.track("WEB - Auth - Popin - Closed", { bountyClaim: true });
    };
  }, []);

  return (
    <div className="shrink-0 w-full lg:w-4/5 mx-auto flex flex-col">
      {authType === "login" ? (
        <LoginForm
          onClose={onClose}
          onSectionChange={() => {
            setAuthType("signup");
            getAnalytics()?.track("WEB - Auth - Popin Section - Changed", {
              section: "signup",
              bountyClaim: true,
            });
          }}
          onSuccess={() => {
            /* Do nothing, cache updates itself */
          }}
          containerClassName="mb-6 lg:mb-0 lg:pt-10"
          externalAuthsContainerClassName="bg-purple-3 rounded-md lg:mt-4"
        />
      ) : (
        <SignupForm
          onSectionChange={() => {
            setAuthType("login");
            getAnalytics()?.track("WEB - Auth - Popin Section - Changed", {
              section: "login",
              bountyClaim: true,
            });
          }}
          onSuccess={() => {
            /* Do nothing, cache updates itself */
            router.push(
              { pathname: router.pathname, query: removeQueryParams(router.query, "signup-step") },
              undefined,
              { shallow: true }
            );
          }}
          containerClassName="mb-6 lg:mb-0 lg:pt-10"
          walletSetupContainerClassName="h-[400px] flex flex-col items-center justify-center text-grey-dark"
          externalAuthsContainerClassName="bg-purple-3 rounded-md lg:mt-4"
        />
      )}
    </div>
  );
}

export default BountyClaimAuthForm;
