import React, { useCallback, useEffect, useRef, useState } from "react";
import { apiObject } from "rudder-sdk-js";

import { useAudioPlayer } from "@/components/AudioPlayer";
import { getAnalytics } from "@/utils/analytics";
import { useWindowSize } from "@/components/WindowSizeProvider";

type Props = {
  togglePlay: (at?: number) => void;
  colours?: {
    wave?: string;
    progress?: string;
    hover?: string;
  };
  trackId?: string; // Set if overwriting context audio with another track
  trackingData?: apiObject;
  audio?: HTMLAudioElement; // Overwrites the context audio
  isPlaying?: boolean; // Overwrites the context isPlaying
  waveformArray?: number[]; // Overwrites the context waveform
};

const PROGRESS_COLOUR = "rgba(118, 123, 255, 1)";
const WAVE_COLOUR = "rgba(255, 255, 255, 0.3)";
const HOVER_COLOUR = "rgba(172, 175, 255, 1)";

export default function ProgressBar({
  togglePlay,
  colours,
  trackId,
  trackingData,
  audio: propAudio,
  isPlaying: propIsPlaying,
  waveformArray: propWaveformArray,
}: Props) {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const {
    playlist,
    audio: contextAudio,
    musicId,
    isPlaying: contextIsPlaying,
    waveformArray: contextWaveformArray,
    setSeekTime,
  } = useAudioPlayer();

  const audio = trackId && playlist && trackId !== musicId ? propAudio : contextAudio ?? propAudio;
  const isPlaying =
    trackId && playlist && trackId !== musicId ? propIsPlaying : contextIsPlaying ?? propIsPlaying;
  const waveformArray = propWaveformArray ?? contextWaveformArray ?? DEFAULT_WAVEFORM;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const hoverCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const progressCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [disabled, setDisabled] = useState(!audio?.duration || audio?.duration === 0);
  const [lastDrawnBarIndex, setLastDrawnBarIndex] = useState<number>(-1);

  /**
   * Draws the waveform on the canvas.
   *
   * @param canvas - The canvas to draw a waveform on
   * @param data - The waveform data
   * @param fill - The colour of the waveform
   * @param maxBarIndex - Index of the last bar to draw
   */
  const drawWaveform = useCallback(
    (canvas: HTMLCanvasElement, data: number[], fill: string, maxBarIndex?: number) => {
      const MAX_BAR_HEIGHT = 255;
      const BAR_WIDTH = 2;
      const BAR_GUTTER = 3;

      canvas.width = data.length * (BAR_GUTTER + BAR_WIDTH) * 0.97;
      canvas.height = isMobile ? 24 : 28;
      const ctx = canvas.getContext("2d");
      const { width } = canvas;
      const { height } = canvas;
      const halfHeight = height / 2;
      ctx?.clearRect(0, 0, width, height);
      if (ctx) ctx.fillStyle = fill;

      for (let i = 0; i < (maxBarIndex ?? data.length); i++) {
        const bar = (data[i] / MAX_BAR_HEIGHT) * halfHeight;

        ctx?.fillRect(i * (BAR_WIDTH + BAR_GUTTER), halfHeight - bar, BAR_WIDTH, bar);
        ctx?.fillRect(i * (BAR_WIDTH + BAR_GUTTER), halfHeight, BAR_WIDTH, bar);
      }
    },
    [isMobile]
  );

  /**
   * Creates the callback function for the audio onTimeUpdate event.
   *
   * Handles updating the progress bar of the waveform.
   *
   * @param canvas - The canvas to draw the progress waveform on
   */
  const onTimeUpdate = useCallback(
    (canvas: HTMLCanvasElement | null) => (e: Event) => {
      const audio = e.target as HTMLAudioElement;
      const { currentTime } = audio;
      const length = audio.duration;

      // Disable waveform if metadata not loaded
      setDisabled(!length);

      if (waveformArray) {
        // Draw the progress bar
        const maxBarIndex = waveformArray.length * (currentTime / length);
        if (canvas && (maxBarIndex > lastDrawnBarIndex + 1 || currentTime < 1)) {
          drawWaveform(canvas, waveformArray, colours?.progress ?? PROGRESS_COLOUR, maxBarIndex);
          setLastDrawnBarIndex(Math.floor(maxBarIndex));
        }
      }
    },
    [waveformArray, lastDrawnBarIndex, drawWaveform, colours?.progress]
  );

  // Clean waveform on audio change + set duration + redraw progress if necessary
  useEffect(() => {
    setLastDrawnBarIndex(-1);

    if (canvasRef.current) canvasRef.current.getContext("2d")?.clearRect(0, 0, 1000, 1000);
    if (progressCanvasRef.current) progressCanvasRef.current.getContext("2d")?.clearRect(0, 0, 1000, 1000);
    if (hoverCanvasRef.current) hoverCanvasRef.current.getContext("2d")?.clearRect(0, 0, 1000, 1000);
    if (progressCanvasRef.current && audio && audio.currentTime > 0 && waveformArray)
      drawWaveform(
        progressCanvasRef.current,
        waveformArray,
        colours?.progress ?? PROGRESS_COLOUR,
        waveformArray.length * (audio.currentTime / audio.duration)
      );
  }, [audio, waveformArray, colours?.progress, canvasRef, progressCanvasRef, hoverCanvasRef, drawWaveform]);

  // Attach timeupdate event listener to audio
  useEffect(() => {
    if (
      audio &&
      progressCanvasRef.current &&
      (!trackId || (playlist && trackId === musicId && progressCanvasRef.current))
    ) {
      const canvas = progressCanvasRef.current;
      audio.addEventListener("timeupdate", onTimeUpdate(canvas));
      return () => audio.removeEventListener("timeupdate", onTimeUpdate(canvas));
    }

    return undefined;
  }, [musicId, audio, playlist, onTimeUpdate, trackId]);

  // Draw base waveform
  useEffect(() => {
    if (canvasRef.current && waveformArray) {
      drawWaveform(canvasRef.current, waveformArray, colours?.wave ?? WAVE_COLOUR);
    }
  }, [canvasRef, waveformArray, audio, colours?.wave, drawWaveform]);

  /**
   * Draws the waveform on the canvas using the mouse move event.
   *
   * @param e - The event object
   * @param canvas - The canvas to draw the seek waveform on
   * @param colour - The colour of the waveform
   */
  function changeHoverSeek(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    canvas: HTMLCanvasElement | null,
    colour: string
  ) {
    const width = canvasRef.current?.offsetWidth ?? 1;
    const rect = (e.target as HTMLDivElement).getBoundingClientRect();
    const offset = Math.max(0, (e.clientX - rect.left) * 0.97); // x position within the element

    if (waveformArray && canvas) {
      const maxBarIndex = waveformArray.length * (offset / width);
      drawWaveform(canvas, waveformArray, colour, maxBarIndex);
    }
    const time = Math.floor(Number(audio?.duration) * (offset / width));
    if (!Number.isNaN(time) || Number.isFinite(time)) setSeekTime(time);
  }

  if (!audio) return null;

  return (
    <div
      onMouseMove={(e) => {
        if (disabled || !Number.isFinite(audio.duration)) return;
        changeHoverSeek(e, hoverCanvasRef?.current, colours?.hover ?? HOVER_COLOUR);
      }}
      onMouseLeave={() => {
        hoverCanvasRef.current?.getContext("2d")?.clearRect(0, 0, 1000, 1000);
        setSeekTime(undefined);
      }}
      onMouseUp={(e) => {
        if (disabled || !Number.isFinite(audio.duration)) return;

        const prevTime = audio.currentTime;
        const width = canvasRef.current?.offsetWidth;
        const offset = e.nativeEvent.offsetX;
        const time = Math.floor(audio.duration * (offset / Number(width)) * 0.97);
        changeHoverSeek(e, progressCanvasRef.current, colours?.progress ?? PROGRESS_COLOUR);
        audio.currentTime = time;

        if (!isPlaying) togglePlay(time);

        getAnalytics()?.track("Audio Player Music Seeked", {
          ...trackingData,
          time,
          prevTime,
          duration: audio.duration,
          tracks: playlist?.map((track) => track.slug),
          tracksIds: playlist?.map((track) => track.id),
        });
      }}
      className="w-full h-6 lg:h-7 relative cursor-pointer"
    >
      <canvas ref={canvasRef} className={"absolute inset-0 w-full h-full"}></canvas>
      <canvas ref={progressCanvasRef} className={"absolute inset-0 w-full h-full z-10"}></canvas>
      <canvas ref={hoverCanvasRef} className={"absolute inset-0 w-full h-full z-20"}></canvas>
    </div>
  );
}

const DEFAULT_WAVEFORM = [
  193, 181, 157, 188, 189, 244, 246, 241, 249, 241, 247, 213, 210, 231, 236, 247, 251, 237, 254, 241, 227,
  242, 254, 244, 239, 245, 239, 253, 242, 253, 229, 230, 249, 232, 241, 242, 246, 244, 238, 232, 243, 243,
  234, 243, 250, 239, 251, 235, 246, 232, 255, 239, 240, 249, 248, 252, 237, 248, 246, 249, 243, 227, 245,
  241, 247, 240, 252, 248, 230, 249, 228, 237, 238, 246, 247, 242, 246, 250, 242, 232, 233, 240, 249, 245,
  235, 247, 244, 255, 243, 232, 239, 245, 249, 220, 246, 251, 245, 88, 3, 0,
];
