import { NextRouter } from "next/router";

/**
 * Puts the `rc` query parameter into an object if it exists so
 * that the return of this function can be spread into a query.
 *
 * @example
 * ```ts
 * const router = useRouter();
 * router.push({
 *  pathname: "/connect",
 *  query: {
 *    login: true,
 *    ...getQueryRC(router),
 *  },
 * });
 * ```
 *
 * @param router - Next Router
 * @returns an object with the rc query parameter if it exists
 */
export function getQueryRC(router: NextRouter) {
  return router.query.rc ? { rc: router.query.rc } : {};
}

/**
 * Removes one or more query parameters from the query object.
 * This is useful for properly removing query parameters
 * that are no longer needed in the page.
 *
 * @example
 * ```ts
 * const router = useRouter();
 * router.push({
 *   pathname: "/connect",
 *   query: removeQueryParam(router.query, ["login", "rc"]),
 * });
 * ```
 *
 * @param query - Next Router query
 * @param params - Query parameters to remove
 *
 * @returns The query without the specified parameters
 */
export function removeQueryParams(
  query: NextRouter["query"],
  params: string | (keyof NextRouter["query"])[]
) {
  const rest = { ...query };
  if (typeof params === "string") {
    delete rest[params];
  } else {
    params.forEach((param) => {
      delete rest[param];
    });
  }
  return rest;
}
