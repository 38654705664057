const units: { [key: string]: number } = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

export default function getRelativeTime(
  d1: number,
  d2 = Date.now(),
  locale = "en",
  options: Intl.RelativeTimeFormatOptions = {},
  nowPeriod: number = 10 * 1000
) {
  const opt: Intl.RelativeTimeFormatOptions = { ...options, numeric: "auto" };

  // For unsupported browsers
  if (
    !Intl ||
    !Intl.RelativeTimeFormat ||
    typeof Intl.RelativeTimeFormat !== "function" ||
    Number.isNaN(d1) ||
    Number.isNaN(d2)
  ) {
    const date = Number.isNaN(d1) ? Date.now() : d1;
    return new Date(date).toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  const rtf = new Intl.RelativeTimeFormat(locale, opt);

  const elapsed = d1 - d2;

  // If less than 10 seconds ago/ahead, show "now"
  if (Math.abs(elapsed) < nowPeriod) return rtf.format(0, "seconds");

  // If more than 7 days ago, show DD/MM/YYY
  if (elapsed < 7 * 24 * 60 * 60 * -1000)
    return new Date(d1).toLocaleDateString("fr-FR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const u in units) {
    if (Math.abs(elapsed) > units[u] || u === "second")
      return rtf.format(Math.round(elapsed / units[u]), u as Intl.RelativeTimeFormatUnit);
  }

  return undefined;
}
