import { useQuery, useReactiveVar } from "@apollo/client";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { toast } from "react-toastify";

import { MeDocument } from "@/graphql/types";
import { hasAccessTokenVar } from "@/apollo/links/auth";

/**
 * A notification toast for the NoSlotsLeft error.
 * Shows an upgrade button that links to the logged
 * in user's label.
 *
 * @remarks See Warning
 */
export default function NoSlotsLeftToast() {
  const router = useRouter();
  const isLogged = useReactiveVar(hasAccessTokenVar);
  const { t } = useTranslation(["common", "notifications"]);

  const { data } = useQuery(MeDocument, { skip: !isLogged, fetchPolicy: "cache-only" });

  // WARNING: This upgrade button links to the first label returned
  //          in `me.labels`... Will break once users can have
  //          multiple labels
  return (
    <div>
      {t("notifications:notifications.messages.label.releases.slots_label")}
      {data?.me.label && (
        <button
          onClick={() => {
            toast.dismiss("no-slots-left-toast");
            if (data.me.label) router.push(`/labels/${data.me.label.slug}?section=subscription`);
          }}
          className="block text-orange-2 text-xs leading-1 font-medium uppercase tracking-wider mt-3"
        >
          {t("common:common.buttons.upgrade")}
        </button>
      )}
    </div>
  );
}
