import { SVGProps } from "react";

export default function ShareIcon(props: SVGProps<SVGSVGElement>) {
  const { width = "18", height = "19", ...rest } = props;
  return (
    <svg {...{ width, height }} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" fill="none">
      <circle
        cx="15.4973"
        cy="10.5022"
        r="2.25"
        transform="rotate(135 15.4973 10.5022)"
        className="stroke-current"
        strokeWidth="1.5"
      />
      <circle
        cx="9.49808"
        cy="15.4933"
        r="2.25"
        transform="rotate(135 9.49808 15.4933)"
        className="stroke-current"
        strokeWidth="1.5"
      />
      <circle
        cx="9.49808"
        cy="5.5022"
        r="2.25"
        transform="rotate(135 9.49808 5.5022)"
        className="stroke-current"
        strokeWidth="1.5"
      />
      <line x1="13.2711" y1="12.204" x2="11.2711" y2="14.204" className="stroke-current" strokeWidth="1.5" />
      <line
        x1="13.2104"
        y1="9.03253"
        x2="11.2104"
        y2="7.03253"
        className="stroke-current"
        strokeWidth="1.5"
      />
    </svg>
  );
}
