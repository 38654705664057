import clsx from "clsx";
import dynamic from "next/dynamic";
import { AnimatePresence, motion } from "framer-motion";

import { WalletSection } from "@/components/Wallet/types";
import { useSetModalStyles } from "@/utils/hooks/useSetModalStyles";
import { easeQuartOut } from "@/utils/constants";

const Wallet = dynamic(() => import("@/components/Wallet/Wallet"));

type Props = {
  /** Toggle to open the wallet */
  show: boolean;
  /** Open the wallet with a specific section open */
  section?: WalletSection;
  /** Close the wallet (needs to set `show` to false) */
  closeModal: () => void;
};

export default function WalletModal(props: Props) {
  useSetModalStyles(props.show);

  return (
    <AnimatePresence>
      {props.show && (
        <motion.div
          onClick={() => props.closeModal()}
          transition={{ duration: 0.3, ease: easeQuartOut }}
          className="flex justify-end items-start fixed inset-0 sm:p-6 h-screen w-screen z-9999999"
        >
          <motion.div
            initial={{ opacity: 0, backdropFilter: "blur(12px) opacity(0)" }}
            animate={{ opacity: 1, backdropFilter: "blur(12px) opacity(1)" }}
            exit={{ opacity: 0, backdropFilter: "blur(12px) opacity(0)" }}
            className={clsx(
              "absolute inset-0 bg-dark-3/10 -z-10",
              "before:absolute before:inset-0 before:-z-10 before:backdrop-blur-md"
            )}
          />
          <motion.div
            variants={{
              closed: { x: 60, opacity: 0 },
              open: { x: 0, opacity: 1 },
            }}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{ duration: 0.2, ease: easeQuartOut }}
            onClick={(e) => e.stopPropagation()}
            className={clsx(
              "relative h-full w-full sm:w-120 max-h-960px",
              "rounded-md flex flex-col overflow-hidden",
              "before:border-2 before:bg-dark-stroke",
              "before:border-gradient before:rounded-md"
            )}
          >
            <motion.div
              initial={{ backdropFilter: "blur(54px) opacity(0)" }}
              animate={{ backdropFilter: "blur(54px) opacity(1)" }}
              exit={{ backdropFilter: "blur(54px) opacity(0)" }}
              className={clsx(
                "absolute inset-0 bg-dark-3/30 -z-10 rounded-md",
                "before:absolute before:inset-0 before:-z-10 before:rounded-md before:backdrop-blur-[54px]"
              )}
            />
            <Wallet {...props} />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
