import { BuilderScarcity } from "@/components/dashboard/TrackBuilder/types";
import {
  AuctionSalesNftFragment,
  BasicPrivateUserFragment,
  BasicUserFragment,
  InstantSalesNftFragment,
  Maybe,
  SaleType,
} from "@/graphql/types";

export type APIResult<T> = { results: T[]; size: number };

/**
 * Checks if a value is defined (and not null).
 *
 * @param value - The Maybe<T> to check.
 * @returns True if the value is defined and not null, otherwise false.
 */
export function is<T>(value: Maybe<T> | undefined): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

/**
 * Method used to extract the value from a graphql Maybe<T>.
 *
 * @param value - The Maybe<T> to extract the value from.
 *
 * @returns The value if it's defined, otherwise undefined.
 */
export function unwrapMaybe<T>(value: Maybe<T> | undefined): T | undefined {
  // TODO: Add fallback parameter and correct function return type.
  if (!is(value)) return undefined;
  return value;
}

/**
 * Method used to extract all the values from a graphql Maybe<T>
 * in a given object. Only goes 1 level deep.
 *
 * @param obj - The object with Maybe<T>'s to extract values from.
 *
 * @returns A new object with values if they are not null, otherwise undefined.
 */
export function deepUnwrapMaybe<T>(obj: { [x: string]: Maybe<T> | undefined | null } | undefined | null) {
  if (!obj) return undefined;

  const newObj: { [x: string]: T | undefined } = {};
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (val !== null) {
      newObj[key] = val;
    }
  });
  return newObj;
}

/**
 * Maps basic user properties from private type to regular type.
 *
 * @param user - Private user object.
 * @returns basic user object.
 */
export function getPublicBasicUserFrom(user: BasicPrivateUserFragment): BasicUserFragment {
  return {
    id: user.id,
    slug: user.slug,
    username: user.username,
    displayName: user.displayName,
    avatarUrl: user.avatarUrl,
  };
}

/**
 * Verifies if the given object contains a user property
 */
export function hasUser<T>(
  data: T & { user?: Maybe<BasicUserFragment> }
): data is T & { user: BasicUserFragment } {
  return data.user !== undefined;
}

/**
 * Verifies if the given object contains a exclusive property
 */
export function hasExclusiveContent(scarcity: BuilderScarcity): scarcity is Required<BuilderScarcity> {
  return scarcity.exclusiveContent !== undefined;
}

/**
 * Verifies if the NFT has auction sales
 */
export function isAuctionSaleNft(
  nft: AuctionSalesNftFragment | InstantSalesNftFragment
): nft is AuctionSalesNftFragment {
  return (nft as AuctionSalesNftFragment)?.sales.results[0]?.type === SaleType.Auction;
}
