import { cva, VariantProps } from "class-variance-authority";
import { ReactNode } from "react";

const badgeVariants = cva(
  [
    "h-4 lg:h-4.5 px-1.5 flex items-center justify-center shadow-tag",
    "text-10 leading-1 font-medium uppercase tracking-wider",
  ],
  {
    variants: {
      color: {
        perks: "bg-exclusive-tag text-[#F2E6E5]",
        "purple-shiny": "bg-[#54579B]/60 text-purple",
        "purple-dark": "bg-purple/20 text-purple-2",
        "orange-dark": "bg-orange/30 text-orange-5",
        "green-light": "bg-green-5 text-green-2",
        "green-dark": "bg-green-2/75 text-green-4",
      },
      outlined: {
        true: "border",
      },
      rounded: {
        full: "rounded-full",
        slightly: "rounded",
      },
      blur: {
        true: "backdrop-blur-[2px]",
      },
    },
    compoundVariants: [
      {
        color: "green-dark",
        outlined: true,
        class: "border-green-4",
      },
      {
        color: "orange-dark",
        outlined: true,
        class: "border-orange",
      },
      {
        color: "perks",
        outlined: true,
        class: "border-orange-5",
      },
      {
        color: "purple-dark",
        outlined: true,
        class: "border-purple-2",
      },
      {
        color: "purple-shiny",
        outlined: true,
        class: "border-purple-3.5",
      },
    ],
    defaultVariants: {
      color: "purple-dark",
      rounded: "slightly",
    },
  }
);

interface BadgeProps {
  variants?: VariantProps<typeof badgeVariants>;
  children: ReactNode;
}

function Badge({ variants, children }: BadgeProps) {
  return <div className={badgeVariants(variants)}>{children}</div>;
}

export default Badge;
