import { AnonymousCreditFragment, TrackCreditFragment, UserCreditFragment } from "@/graphql/types";

/**
 * Checks if the given data is of UserCredit type.
 *
 * @param data - Object of union type TrackCredit
 * @returns boolean indicating if object is of type UserCredit
 */
export function isUserCredit(data: TrackCreditFragment): data is UserCreditFragment {
  return (data as UserCreditFragment).user !== undefined;
}

/**
 * Checks if the given data is of AnonymousCredit type.
 *
 * @param data - Object of union type TrackCredit
 * @returns boolean indicating if object is of type AnonymousCredit
 */
export function isAnonymousCredit(data: TrackCreditFragment): data is AnonymousCreditFragment {
  return (data as AnonymousCreditFragment).name !== undefined;
}
