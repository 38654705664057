import { AnimatePresence } from "framer-motion";

import ModalPortal from "@/components/ModalPortal";
import AuthModalContainer from "@/components/connect/AuthModal/Container";
import { AuthenticationDemand } from "@/components/connect/AuthModal/types";

interface AuthModalProps extends Pick<AuthenticationDemand, "onSuccess"> {
  onClose: () => void;
}

function AuthModalPresenter({ onSuccess, onClose }: AuthModalProps) {
  return (
    <ModalPortal>
      <AnimatePresence>
        {!!onSuccess && <AuthModalContainer onSuccess={onSuccess} onClose={onClose}></AuthModalContainer>}
      </AnimatePresence>
    </ModalPortal>
  );
}

export default AuthModalPresenter;
