import { ApolloProvider, NormalizedCacheObject } from "@apollo/client";
// import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import { appWithTranslation, SSRConfig } from "next-i18next";
import React, { useEffect, useRef, useState } from "react";
import App, { AppContext, AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";

import "react-toastify/dist/ReactToastify.css";
import "react-day-picker/dist/style.css";

import useDisableZoomOnInputFocus from "@/utils/hooks/useDisableZoomOnInputFocus";
import { MobileSSRContext, NotificationsContext } from "@/utils/reactContexts";
import WindowSizeProvider from "@/components/WindowSizeProvider";
import { AudioPlayerProvider } from "@/components/AudioPlayer";
import { initDatadog } from "@/utils/analytics/initDatadog";
import usePageAnalytics from "@/utils/hooks/usePageAnalytics";
import { hasAccessTokenVar } from "@/apollo/links/auth";
import { getAuthUser } from "@/utils/oAuth/storage";
import { useGetPosthog } from "@/utils/analytics";
import Layout from "@/components/hoc/Layout";
import { useApollo } from "@/apollo";
import {
  //   FINGERPRINT_API_KEY,
  //   FINGERPRINT_ENDPOINT,
  //   FINGERPRINT_SCRIPT_URL,
  NEXT_ENV,
  PIANITY_ENV,
} from "@/env";

import "@/styles/nprogress.css";
import "@/styles/toastify.css";
import "@/styles/index.css";
import ReCaptchaProvider from "@/components/hoc/ReCaptchaProvider";

export const LS_USER_CURRENCY = "pianity-user-currency";
export const LS_REFERRAL_CODE = "pianity-user-rc";
export const SS_CLAIM_CODE = "pianity-user-cc";

/* ******************************************************** */
/*               Fix for google translate node              */
/*                remove/add crashing the app               */
if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function remove(child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error("Cannot remove a child from a different parent", child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, [child]) as typeof child;
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function insert(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error("Cannot insert before a reference node from a different parent", referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, [newNode, referenceNode]) as typeof newNode;
  };
}
/* ******************************************************** */
/* ******************************************************** */

// Initialize Datadog logs
initDatadog();

function MyApp({
  Component,
  pageProps,
}: AppProps<
  SSRConfig & {
    path?: string;
    isMobileSSR?: boolean;
    initialApolloState?: NormalizedCacheObject;
  }
>) {
  const { posthog, status } = useGetPosthog();
  const router = useRouter();

  const apolloClient = useApollo(pageProps.initialApolloState);

  const didSetGroup = useRef(false);
  const [showNotifications, setShowNotifications] = useState(false);

  usePageAnalytics(pageProps.path);
  useDisableZoomOnInputFocus();

  useEffect(() => {
    if (NEXT_ENV !== "production") return;

    // Prevent Phishing by Reverse Proxy
    if (PIANITY_ENV === "production") {
      // Check that domain matches
      if (window.parent && btoa(window.parent.location.hostname) !== "cGlhbml0eS5jb20=") {
        const p = "p";
        const i = "i";
        const a = "a";
        const n = "n";
        const t = "t";
        const y = "y";
        window.location.replace(`https://${p}${i}${a}${n}${i}${t}${y}.com${window.parent.location.pathname}`);
      }
    }
  }, []);

  useEffect(() => {
    if (
      status === "ready" &&
      !didSetGroup.current &&
      pageProps.path === "/[artist]" &&
      typeof router.query.artist === "string" &&
      // Filter out logged in users
      !hasAccessTokenVar() &&
      !getAuthUser()
    ) {
      didSetGroup.current = true;
      posthog?.group("[G] Landed on Artist", router.query.artist, {
        artistSlug: router.query.artist,
      });
    }
  }, [posthog, status, pageProps.path, router.query.artist]);

  return (
    <ReCaptchaProvider pageProps={pageProps}>
      {/* <FpjsProvider
        loadOptions={{
          region: "eu",
          apiKey: FINGERPRINT_API_KEY,
          endpoint: FINGERPRINT_ENDPOINT,
          scriptUrlPattern: FINGERPRINT_SCRIPT_URL,
        }}
      > */}
      <ApolloProvider client={apolloClient}>
        <WindowSizeProvider>
          <AudioPlayerProvider>
            <MobileSSRContext.Provider value={{ isMobileSSR: !!pageProps.isMobileSSR }}>
              <NotificationsContext.Provider value={{ showNotifications, setShowNotifications }}>
                <Layout>
                  <Component {...pageProps} /> {/* destructuring the pages props */}
                </Layout>
              </NotificationsContext.Provider>
            </MobileSSRContext.Provider>
          </AudioPlayerProvider>

          <Script id="aggregate-rating" type="application/ld+json" src="/aggregate-rating.json" />
          <Script
            id="webfont-script"
            strategy="lazyOnload"
            src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js"
          />
        </WindowSizeProvider>
      </ApolloProvider>
      {/* </FpjsProvider> */}
    </ReCaptchaProvider>
  );
}

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So [[...slug]] pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (appContext: AppContext) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  // Pass the data to our page via props

  const UA = appContext.ctx.req?.headers["user-agent"];
  const isMobile = Boolean(UA?.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

  return {
    ...appProps,
    pageProps: {
      path: appContext.ctx.pathname,
    },
    isMobileSSR: isMobile,
  };
};

export default appWithTranslation(MyApp);
