import { useEffect, useState } from "react";
import clsx from "clsx";
import { useAudioPlayer } from "@/components/AudioPlayer";

interface Props {
  audio?: HTMLAudioElement;
}

/**
 * Renders the timestamp of the audio from the MusicPlayerContext.
 */
export default function AudioTimestamp({ audio: overrideAudio }: Props) {
  const { audio: ctxAudio, seekTime, musicIndex, playlist } = useAudioPlayer();
  const audio = overrideAudio ?? ctxAudio;
  const track = playlist?.[musicIndex ?? 0];

  const [time, setTime] = useState(audio?.currentTime);
  const [duration, setDuration] = useState(audio?.duration);

  // On audio load, set attach event listeners
  // for currentTime and duration
  useEffect(() => {
    function onLoadedMetadata(e: Event) {
      const audio = e.target as HTMLAudioElement;
      setDuration(audio.duration);
    }
    function onTimeUpdate(e: Event) {
      const audio = e.target as HTMLAudioElement;
      setTime(audio.currentTime);
    }

    if (audio) {
      audio.addEventListener("loadedmetadata", onLoadedMetadata);
      audio.addEventListener("timeupdate", onTimeUpdate);

      return () => {
        audio.removeEventListener("loadedmetadata", onLoadedMetadata);
        audio.removeEventListener("timeupdate", onTimeUpdate);
      };
    }

    return undefined;
  }, [audio]);

  const displayTime = overrideAudio ? time : seekTime ?? time;
  return (
    <div
      className={clsx(
        "text-xs leading-1 lg:text-sm lg:leading-1 shrink-0",
        "[font-variant-numeric:tabular-nums]"
      )}
    >
      {getDisplayTimeBySeconds(displayTime)}/
      {getDisplayTimeBySeconds(duration ?? track?.audio?.duration ?? 0)}
    </div>
  );
}

function getDisplayTimeBySeconds(seconds: number | undefined | null) {
  if (seconds === undefined || seconds === null || Number.isNaN(seconds) || !Number.isFinite(seconds))
    return "-:--";

  const minutes = Math.floor(seconds / 60);
  const secondsLeft = Math.floor(seconds % 60);
  return `${minutes}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`;
}
