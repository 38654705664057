import { datadogLogs } from "@datadog/browser-logs";

import { DATADOG_CLIENT_TOKEN, DATADOG_ENABLED, DATADOG_SITE } from "@/env";

export function initDatadog() {
  if (DATADOG_ENABLED)
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      service: "frontend",
      forwardErrorsToLogs: true,
      forwardConsoleLogs: "all",
      sampleRate: 100,
    });
}
