import { toast } from "react-toastify";
import { Mutex } from "async-mutex";

import { OAuthAccessTokenResponse, OAuthResponse } from "@/utils/oAuth/types";
import { getAnalytics, getPosthog } from "@/utils/analytics";
import { makeOAuthAPICall } from "@/utils/oAuth/helpers";
import { clearAuthTokens, hasAccessTokenVar } from "@/apollo/links/auth";
import { initializeApollo } from "@/apollo";
import { OAUTH_URI } from "@/env";

export async function refreshAccessToken(): Promise<OAuthResponse<OAuthAccessTokenResponse>> {
  const url = `${OAUTH_URI}/token`;

  return makeOAuthAPICall(url, { method: "POST", credentials: "same-origin" });
}

export async function revokeTokens(): Promise<OAuthResponse<never>> {
  const url = `${OAUTH_URI}/token/revoke`;

  return makeOAuthAPICall(url, { method: "POST", credentials: "same-origin" });
}

export const logoutUser = (() => {
  const mutex = new Mutex();

  return async function logoutUser(): Promise<void> {
    await mutex.runExclusive(async () => {
      const isAuth = hasAccessTokenVar();
      console.log("Logout method called with token:", isAuth);
      if (!isAuth) return;

      await revokeTokens();
      clearAuthTokens();

      toast.dismiss();
      getAnalytics()?.reset();
      const posthog = getPosthog();
      // WARNING: rudderstack doesn't reset posthog
      //          so it needs to be done manually
      console.log("posthog", !!posthog);
      posthog?.reset();
      posthog?.reloadFeatureFlags();

      const client = initializeApollo();
      await client.resetStore();
    });
  };
})();
