import { setContext } from "@apollo/client/link/context";
import { createClient } from "graphql-ws";
import { makeVar } from "@apollo/client";
import { i18n } from "next-i18next";
import { Mutex } from "async-mutex";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { refreshAccessToken, logoutUser } from "@/utils/oAuth/tokens";
import { OAuthAccessTokenResponse } from "@/utils/oAuth/types";
import { clearAuthUser } from "@/utils/oAuth/storage";
import { isOAuthError } from "@/utils/oAuth/helpers";
import { getAnalytics, getPosthog } from "@/utils/analytics";
import { NEXT_ENV, WS_URI } from "@/env";
import { toast } from "@/utils/toasts";
import { buildApolloLinks, initializeApollo } from "@/apollo";
import { LS_HAS_TOKEN } from "@/utils/constants";

const LS_DEV_TOKEN = "pianity-dev-token";

/**
 * Checks local storage key to see if there is an
 * access token (meaning the user can - potentially -
 * still authenticate).
 *
 * @remarks
 * Will return true regardless of wether the token is
 * expired or not.
 *
 * @returns true if the user has a token
 */
export function hasAccessToken(): boolean {
  if (typeof window === "undefined") {
    return false;
  }

  return !!localStorage.getItem(LS_HAS_TOKEN);
}

export const { authLink, hasAccessTokenVar, createWebSocketLink, saveAuthTokens, clearAuthTokens } = (() => {
  const refreshMutex = new Mutex();
  let accessToken: string | undefined;
  let expirationDate: number | undefined;

  const hasAccessTokenVar = makeVar(hasAccessToken());

  return {
    /**
     * Method to build the Apollo terminating link for Websocket connections.
     * This is used to rebuild the link when the access token is updated.
     *
     * Undefined server side or if not ws URI in env.
     */
    createWebSocketLink: () => {
      if (typeof window === "undefined") return undefined;
      return new GraphQLWsLink(
        createClient({
          url: WS_URI,
          connectionParams: () => {
            const token = accessToken;
            return {
              authorization: token ? `Bearer ${token}` : undefined,
            };
          },
        })
      );
    },

    /**
     * Context Setter to get the access token
     * (refreshing it if necessary) for the Auth Link.
     */
    authLink: setContext(async (_, { headers }) => {
      // SERVER SIDE
      if (typeof window === "undefined") {
        const SSR_TOKEN = process.env.SERVER_API_TOKEN;
        if (!SSR_TOKEN) throw new Error("SERVER_API_TOKEN env var isn't set");

        return { token: SSR_TOKEN };
      }

      // CLIENT SIDE
      // Run token check in a mutex lock to prevent
      // multiple concurrent token refreshes.
      await refreshMutex.runExclusive(async () => {
        const hasRefreshToken = localStorage.getItem(LS_HAS_TOKEN);

        // Fetch token from localStorage for easier local development
        if (NEXT_ENV === "development") {
          const token = localStorage.getItem(LS_DEV_TOKEN);
          if (token) {
            accessToken = token;
          }
        }

        // Check that access_token is either missing or expired (with a
        // 20 seconds penalty to avoid expiration during request)
        // Also ignore http origins since secure refresh cookie won't be set
        const requiresRefresh =
          hasRefreshToken &&
          !window.origin.startsWith("http://") &&
          (!accessToken || !expirationDate || Number(expirationDate) - 1000 * 20 < Date.now());
        if (requiresRefresh) {
          const response = await refreshAccessToken();
          if (!isOAuthError(response)) {
            saveAuthTokens(response);
          } else {
            // The refresh failed, log out the user
            await logoutUser();
            toast.error(i18n?.t("notifications:notifications.messages.errors.auth.expired"));
          }
        }
      });

      // Set the token in context headers
      // to be used by the HTTP Link
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          "x-rs-anonymous-id": getPosthog()?.get_distinct_id(),
        },
      };
    }),

    /**
     * Sets the auth tokens to be used by the authLink
     * in a protected JS closure.
     *
     * @param data - the reponse data from OAuth token endpoint
     */
    saveAuthTokens(data: OAuthAccessTokenResponse) {
      accessToken = data.access_token;

      // Update Apollo links with new tokens
      // NOTE: This is necessary for the websocket link
      initializeApollo().setLink(buildApolloLinks());

      if (typeof window !== "undefined") {
        // data.expires_in is in seconds
        expirationDate = Date.now() + data.expires_in * 1000;
        localStorage.setItem(LS_HAS_TOKEN, String(true));
        hasAccessTokenVar(true);
        if (NEXT_ENV === "development") {
          localStorage.setItem(LS_DEV_TOKEN, accessToken);
        }
      }
    },

    /**
     * Clears the tokens used by the authLink,
     * effectively logging the user out.
     *
     * @param reload - Set to true to do a shallow page reload.
     * This is necessary when the page might rely on the
     * hasAccessToken method to determine if the user is logged in.
     */
    clearAuthTokens() {
      accessToken = undefined;
      expirationDate = undefined;
      console.log("Cleared auth tokens");

      // Update Apollo links without tokens
      // NOTE: This is necessary for the websocket link
      initializeApollo().setLink(buildApolloLinks());

      if (typeof window !== "undefined") {
        getAnalytics()?.track("Logged Out");

        clearAuthUser();
        localStorage.removeItem(LS_HAS_TOKEN);
        localStorage.removeItem(LS_DEV_TOKEN);
        hasAccessTokenVar(false);
      }
    },

    hasAccessTokenVar,
  };
})();
