import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren } from "react";
import clsx from "clsx";

import CloseButton from "@/components/ui/svg/CloseButton";
import { easeQuartOut } from "@/utils/constants";
import ModalPortal from "@/components/ModalPortal";
import { useSetModalStyles } from "@/utils/hooks/useSetModalStyles";

interface Props {
  /** Title of the modal */
  title: string;
  /** Toggle to make modal appear/disappear */
  show: boolean;
  /** Element placed between the title and the bottom sheet */
  header?: React.ReactNode;
  /**
   * Removes the overflow-y: auto css class from the container.
   * Will set overflow-y: hidden unless set to "visible".
   */
  disableOverflowYAuto?: boolean | "visible";
  /** Callback when user cancels or closes modal */
  close: () => void;
}

export type BottomSheetProps = Omit<Props, "show" | "close">;

/**
 * Animated bottom sheet modal (designed for mobile)
 * with a scrollable list for children.
 */
export default function BottomSheet({
  title,
  show,
  header,
  disableOverflowYAuto,
  close,
  children,
}: PropsWithChildren<Props>) {
  useSetModalStyles(show);

  const animationVariants = {
    initial: { y: "100%" },
    animate: { y: 0 },
    exit: { y: "100%" },
  };

  function getContainerOverflow() {
    if (disableOverflowYAuto === "visible") return "overflow-y-visible";
    return disableOverflowYAuto ? "overflow-y-hidden" : "overflow-y-auto";
  }

  return (
    <ModalPortal>
      <AnimatePresence exitBeforeEnter>
        {show && (
          <motion.div
            className={clsx(
              "fixed inset-0 h-full w-full",
              "flex flex-col justify-end z-[999999]",
              "bg-dark-3 bg-dark-bg-mobile lg:bg-dark-bg"
            )}
            onClick={(e) => {
              e.stopPropagation();
              close();
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={clsx(
                "w-full pt-4 sm:pt-8 px-5 sm:px-8 flex items-center",
                "justify-between gap-5 flex-shrink-0"
              )}
            >
              <div className={"text-white text-3xl leading-1 font-medium"}>{title}</div>
              <div
                onClick={close}
                className={clsx(
                  "text-white cursor-pointer flex justify-center items-center",
                  "rounded-full box-border border-2 border-white",
                  "border-opacity-40 h-[35px] w-[35px] sm:w-12 sm:h-12",
                  "lg:h-[35px] lg:w-[35px] z-60"
                )}
              >
                <CloseButton width="16" height="16" className={"sm:w-4 sm:h-4 lg:w-3 lg:h-3"} />
              </div>
            </motion.div>
            {header ? <div className="flex-shrink-0">{header}</div> : <div className="h-14" />}
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={animationVariants}
              transition={{ ease: easeQuartOut }}
              className={clsx("w-full flex-grow flex flex-col", getContainerOverflow())}
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className={clsx(
                  "min-h-0 flex-grow flex flex-col",
                  "bg-dark-card shadow-medium rounded-t-lg relative",
                  !disableOverflowYAuto && "overflow-y-auto",
                  "before:border-[1.5px] before:bg-dark-stroke",
                  "before:border-gradient before:rounded-t-lg"
                )}
              >
                {children}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
}
