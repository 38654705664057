import NextLink, { LinkProps } from "next/link";
import { ParsedUrlQuery } from "querystring";
import { PropsWithChildren } from "react";
import { useRouter } from "next/router";
import { UrlObject } from "url";

/**
 * Same as NextRouter's Link, but keeps `rc` in the query parameters.
 *
 * Should always be used instead of Next Router's Link so the
 * user never loses their referrer's code.
 */
export default function Link({ href, children }: PropsWithChildren<LinkProps>) {
  const router = useRouter();

  const { rc } = router.query;

  let url = href;
  if (typeof href === "string" && rc) {
    url += (href.includes("?") ? "&rc=" : "?rc=") + rc;
  } else if (typeof href === "object" && rc) {
    Object.assign(url, { query: { ...((url as UrlObject)?.query as ParsedUrlQuery), rc } });
  }

  return <NextLink href={url}>{children}</NextLink>;
}
