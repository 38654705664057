export const NO_IMAGE_URL = "/assets/misc/no-image-dark.svg";

/**
 * Default easing for all transitions on Pianity.
 *
 * `[0.165, 0.84, 0.44, 1]`
 */
export const easeQuartOut = [0.165, 0.84, 0.44, 1];

export const DEFAULT_WAVEFORM_URLS = [
  {
    size: 150,
    url: "https://tracks.fra1.digitaloceanspaces.com/dj-vadim/think-im-going-crazy/waveform_data_150",
  },
  {
    size: 100,
    url: "https://tracks.fra1.digitaloceanspaces.com/dj-vadim/think-im-going-crazy/waveform_data_100",
  },
  {
    size: 50,
    url: "https://tracks.fra1.digitaloceanspaces.com/dj-vadim/think-im-going-crazy/waveform_data_50",
  },
];

export const DEFAULT_WAVEFORM_VALUES = [
  {
    size: 150,
    values: [
      200, 201, 159, 150, 168, 202, 175, 241, 238, 244, 252, 235, 249, 240, 248, 245, 249, 193, 189, 237, 237,
      241, 234, 250, 253, 233, 248, 255, 252, 237, 249, 219, 235, 253, 244, 251, 234, 242, 252, 231, 252, 254,
      251, 241, 251, 218, 221, 251, 250, 251, 221, 242, 246, 237, 249, 239, 248, 235, 244, 227, 238, 251, 241,
      242, 229, 246, 247, 243, 244, 252, 247, 231, 249, 248, 221, 254, 248, 237, 237, 244, 253, 245, 251, 255,
      235, 247, 246, 254, 239, 250, 241, 232, 231, 243, 246, 241, 253, 242, 235, 250, 253, 248, 223, 247, 248,
      237, 220, 240, 245, 241, 241, 251, 230, 253, 242, 255, 248, 250, 235, 228, 231, 226, 253, 247, 255, 241,
      227, 245, 251, 243, 251, 255, 247, 223, 244, 234, 242, 251, 249, 250, 204, 246, 249, 251, 239, 254, 15,
      4, 1, 0,
    ],
  },
  {
    size: 100,
    values: [
      193, 181, 157, 188, 189, 244, 246, 241, 249, 241, 247, 213, 210, 231, 236, 247, 251, 237, 254, 241, 227,
      242, 254, 244, 239, 245, 239, 253, 242, 253, 229, 230, 249, 232, 241, 242, 246, 244, 238, 232, 243, 243,
      234, 243, 250, 239, 251, 235, 246, 232, 255, 239, 240, 249, 248, 252, 237, 248, 246, 249, 243, 227, 245,
      241, 247, 240, 252, 248, 230, 249, 228, 237, 238, 246, 247, 242, 246, 250, 242, 232, 233, 240, 249, 245,
      235, 247, 244, 255, 243, 232, 239, 245, 249, 220, 246, 251, 245, 88, 3, 0,
    ],
  },
  {
    size: 50,
    values: [
      191, 176, 222, 248, 250, 234, 225, 246, 249, 253, 239, 254, 247, 250, 252, 234, 245, 246, 250, 240, 248,
      243, 249, 248, 244, 251, 250, 255, 247, 252, 239, 248, 248, 255, 244, 237, 247, 249, 253, 242, 241, 252,
      246, 254, 242, 247, 239, 253, 173, 2,
    ],
  },
];

export const SS_OAUTH_REDIRECT_URL = "pianity-oauth-redirect";
export const SS_OAUTH_FOLLOW_ARTIST = "pianity-oauth-follow-artist";
export const SS_OAUTH_FOLLOW_USER = "pianity-oauth-follow-user";
export const SS_OAUTH_LIKE_TRACK = "pianity-oauth-like-track";

export const SS_INSTA_REDIRECT_URL = "pianity-insta-redirect";

export const LS_AUTH_PREFER_LOGIN = "pianity-auth-prefer-login";
export const LS_HAS_TOKEN = "pianity-logged-in";
export const LS_ARTIST_REGISTER = "pianity-artist-register";
export const LS_PROFILE_EDIT = "pianity-profile-edit";
export const LS_EMAIL_VERIFY_REDIRECT_URL = "pianity-verify-redirect";

export const PROP_KEY_DISABLE_NAV = "_disableNav";
export const PROP_KEY_DISABLE_CAPTCHA = "_disableCaptcha";
