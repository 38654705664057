import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useFormContext, useFormState } from "react-hook-form";

interface TwoFACodeProps {
  email?: string;
}

function TwoFACode({ email }: TwoFACodeProps) {
  const { t } = useTranslation("connect");

  const { control, register } = useFormContext();
  const { errors } = useFormState({ control, name: "code" });

  return (
    <div className="flex flex-col">
      <span className="text-base leading-1.1 lg:text-lg lg:leading-1.1 font-medium mb-2 lg:mb-3">
        {t("connect.forms.2fa.title")}
      </span>
      <label htmlFor="auth-code" className="text-sm leading-1.2 lg:text-base lg:leading-1.2 mb-6">
        {t("connect.forms.2fa.description")}
      </label>

      <div className="bg-dark-8/25 rounded-lg shadow-small flex flex-col">
        <div
          className={clsx(
            "p-3 lg:p-4 text-sm leading-1.1 lg:text-base lg:leading-1.1",
            "font-medium truncate"
          )}
        >
          {email}
        </div>

        <div
          className={clsx(
            "shadow-input-dark rounded flex items-center pr-3 lg:pr-4",
            "relative before:border-2 before:border-gradient before:rounded",
            errors.code ? "before:bg-red bg-red-2/15" : "bg-dark-8/25 before:bg-dark-stroke shadow-input-dark"
          )}
        >
          <input
            type="text"
            id="auth-code"
            inputMode="numeric"
            autoComplete="one-time-code"
            autoFocus
            placeholder="826409"
            minLength={6}
            {...register("code", {
              required: true,
              pattern: /[0-9]+/,
              minLength: 6,
            })}
            className={clsx(
              "pl-3 lg:pl-4 text-sm leading-1.2 lg:text-base lg:leading-1.2",
              "h-10 lg:h-12 grow outline-none bg-transparent"
            )}
          />

          {errors.code && (
            <span className="text-right text-sm leading-1.2 text-red shrink-0">
              {errors.code.message || t("connect.forms.errors.2fa.format")}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default TwoFACode;
