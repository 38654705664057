import { ReactNode } from "react";
import ReactDOM from "react-dom";

/** Props for {@link ModalPortal} */
interface ModalPortalProps {
  /** Elements to render in portal */
  children: ReactNode;
}

/**
 * Renders its children inside a portal attached to a
 * div with ID `"modal-root"`.
 *
 * Renders nothing server side.
 *
 * @throws if no DOM element has ID `"modal-root"`
 *
 * @param props - Component props
 * @returns
 */
function ModalPortal({ children }: ModalPortalProps) {
  if (typeof window === "undefined") return null;

  const portal = document.getElementById("modal-root");

  if (!portal) {
    throw new Error('<div id="modal-root" /> is missing from DOM');
  }

  return ReactDOM.createPortal(children, portal);
}

export default ModalPortal;
