import { MetaMaskInpageProvider } from "@metamask/providers";

import { MetamaskWalletData } from "@/utils/walletConnection/common";

declare global {
  interface Window {
    ethereum: MetaMaskInpageProvider;
  }
}

export async function getWalletData(): Promise<MetamaskWalletData | null> {
  if (!window.ethereum) {
    return null;
  }

  const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });

  if (!accounts || !Array.isArray(accounts)) {
    throw new Error("Couldn't get accounts");
  }

  const { utils } = await import("ethers");

  const address = utils.getAddress(accounts[0]);

  return {
    type: "metamask",
    address,
  };
}

/**
 * Signs data with Metamask and mutates the walletData object to add the public key if it wasn't
 * already present.
 */
export async function sign(walletData: MetamaskWalletData, data: string): Promise<string> {
  const signedData = await window.ethereum.request<string>({
    method: "personal_sign",
    params: [data, walletData.address],
  });

  if (!signedData) {
    throw new Error("Couldn't sign data");
  }

  if (!walletData.publicKey) {
    const { utils } = await import("ethers");

    const hash = utils.hashMessage(data);
    const recoveredKey = utils.recoverPublicKey(utils.arrayify(hash), signedData);
    walletData.publicKey = Buffer.from(utils.arrayify(recoveredKey));
  }

  return signedData;
}
