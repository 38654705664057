import { makeOAuthAPICall } from "@/utils/oAuth/helpers";
import { OAUTH_URI } from "@/env";
import {
  OAuthAccessTokenResponse,
  OAuthAuthMethodResponse,
  OAuthAuthMethods,
  OAuthAuthorizationCodeRequestArgs,
  OAuthAuthorizationCodeTokenRequest,
  OAuthRefreshTokenTokenRequest,
  OAuthResponse,
  OAuthWalletChallengeArgs,
  OAuthWalletChallengeResponse,
} from "@/utils/oAuth/types";

export const OAUTH_CLIENT_ID = "PianityWebApp";

/**
 * Request the authorization code from the API
 * according to the type and method.
 * This code is required to get the access token.
 *
 * @param authType - The type of the auth request (login or signup).
 * @param method - The method to request the authorization code with.
 * @param args - The arguments to pass to the API depending on which type of
 * auth you are performing.
 * @param clientId - The client ID of the application. Defaults to "PianityWebApp"
 * @param redirectURI - The redirect URI of the application. Defaults to "https://pianity.com/"
 *
 * @remarks handles the error response from the OAuth API.
 *
 * @returns The authorization reponse containing the code.
 */
export async function requestAuthorizationCode(
  authType: "login" | "signup",
  method: OAuthAuthMethods,
  args: OAuthAuthorizationCodeRequestArgs,
  clientId = OAUTH_CLIENT_ID,
  redirectURI = "https://pianity.com"
): Promise<OAuthResponse<OAuthAuthMethodResponse>> {
  const url = `${OAUTH_URI}/method/${method}/${authType}`;

  return makeOAuthAPICall(
    url,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ client_id: clientId, redirect_uri: redirectURI, args }),
    },
    method
  );
}

/**
 * Request the access token from the OAuth API.
 *
 * @param request - The request to send to the OAuth API. Will changed
 * between simple and refresh token requests.
 *
 * @remarks handles the error response from the OAuth API.
 *
 * @returns The access token response.
 */
export async function requestAccessToken(
  request: OAuthAuthorizationCodeTokenRequest | OAuthRefreshTokenTokenRequest
): Promise<OAuthResponse<OAuthAccessTokenResponse>> {
  const url = `${OAUTH_URI}/token`;

  return makeOAuthAPICall(url, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(request),
  });
}

/**
 * Fetches the wallet challenge from the OAuth API.
 *
 * @param request - The request to send to the OAuth API.
 *
 * @returns The wallet challenge response.
 */
export async function requestWalletChallenge(
  request: OAuthWalletChallengeArgs
): Promise<OAuthResponse<OAuthWalletChallengeResponse>> {
  const url = `${OAUTH_URI}/method/wallet/${request.type}/challenge`;

  return makeOAuthAPICall(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request.payload),
  });
}
