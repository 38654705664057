import { BasicArtistFragment } from "@/graphql/types";

/**
 * Joins the array of artists to make a string of artist names.
 * Joins like so:
 *
 * "Artist 1, Artist 2 & Artist 3"
 *
 * @param artists - array of artists
 */
export function getArtistNames(artists: BasicArtistFragment[] | undefined) {
  if (!artists) return undefined;

  if (artists.length < 2) return artists[0]?.name;

  return `${artists
    .slice(0, -1)
    .map((a) => a.name)
    .join(", ")} & ${artists.slice(-1)[0].name}`;
}
