import { ApolloLink } from "@apollo/client";

import { ReportErrorDocument } from "@/graphql/types";
import { initializeApollo } from "@/apollo";
import { API_VERSION, NEXT_ENV } from "@/env";

function getMajorSemVer(version: string): number {
  return parseInt(version.toLowerCase().replace("v", "").split(".")[0]);
}

let SENT_WARNING = false;
/**
 * Checks if the backend is using a compatible API version
 *
 * @remarks
 * Uses NEXT_PUBLIC_API_VERSION env variable to check against.
 * Forces a page refresh if the version is of a different major.
 */
export const versionCheckLink = new ApolloLink((operation, forward) => {
  // Not applicable for subscriptions
  if (
    operation.query.definitions.some(
      (def) => def.kind === "OperationDefinition" && def.operation === "subscription"
    )
  )
    return forward(operation);

  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers && typeof window !== "undefined") {
      const apiVersion = headers.get("x-api-version") as string | undefined;
      if (apiVersion && API_VERSION && getMajorSemVer(apiVersion) > getMajorSemVer(API_VERSION)) {
        const lastRefresh = localStorage.getItem("pianity-last-refresh");
        if (lastRefresh !== API_VERSION) {
          // Set last refresh version to avoid infinite loop
          localStorage.setItem("pianity-last-refresh", API_VERSION);
          // Refresh client
          window.location.reload();
        } else if (!SENT_WARNING) {
          // ERROR: Infinite loop detected. API_VERSION must be bumped.
          const client = initializeApollo();
          client
            .mutate({
              mutation: ReportErrorDocument,
              variables: {
                exception: `Current Server API: v${apiVersion}\nCurrent Client API: v${API_VERSION}`,
                path: "Frontend: API Versioning",
                message: "Client and Server differ in API versions",
              },
            })
            .catch((error) => {
              if (NEXT_ENV !== "production" || typeof window === "undefined") {
                const log = { message: "Client and Server differ in API versions", error };
                console.log("Report Error Error", JSON.stringify(log));
              }
            });
          SENT_WARNING = true;
        }
      }
    }
    return response;
  });
});
