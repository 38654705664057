import { ButtonHTMLAttributes, ForwardedRef, forwardRef, PropsWithChildren } from "react";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

import { TailwindProperty, twClass } from "@/utils/tailwind";
import LoadingIndicator from "@/components/ui/svg/LoadingIndicator";

const buttonVariants = cva("flex items-center justify-center font-medium relative", {
  variants: {
    intent: {
      primary: "bg-purple text-white",
      purple: "bg-purple text-white",
      "purple-light": "bg-purple-3 text-white",
      white: "bg-white text-grey-dark",
      black: "bg-grey-dark text-white",
      green: "bg-green-3 text-white",
      orange: "bg-orange text-white",
      light: "bg-purple-6 text-grey-dark",
    },
    gradient: {
      true: "",
    },
    shadow: {
      true: "hover:shadow-button",
    },
    shade: {
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
    },
    loading: {
      true: "relative",
    },
    disabled: {
      true: "opacity-60 pointer-events-none",
    },
    fullWidth: {
      true: "w-full",
    },
    round: {
      true: "rounded-full",
    },
    outlined: {
      true: "bg-transparent border-2",
    },
  },
  compoundVariants: [
    {
      intent: "primary",
      gradient: true,
      class: "bg-gradient-brand",
    },
    {
      intent: "primary",
      outlined: true,
      class: "border-purple text-purple",
    },
    {
      intent: "purple",
      outlined: true,
      class: "border-purple text-purple",
    },
    {
      intent: "purple-light",
      outlined: true,
      class: "border-purple-3 text-white",
    },
    {
      intent: "white",
      outlined: true,
      class: "border-white text-white",
    },
    {
      intent: "light",
      outlined: true,
      class: "border-purple-6 text-purple-6",
    },
    {
      intent: "black",
      outlined: true,
      class: "border-grey-dark text-grey-dark",
    },
    {
      intent: "green",
      outlined: true,
      class: "border-green-3 text-green-3",
    },
    { intent: "purple", shade: 2, class: "bg-purple-2" },
    { intent: "purple", shade: 3, class: "bg-purple-3" },
    { intent: "purple", shade: 4, class: "bg-purple-4" },
    { intent: "purple", shade: 5, class: "bg-purple-5" },
    { intent: "purple", shade: 6, class: "bg-purple-6" },
  ],
  defaultVariants: {
    intent: "primary",
    shadow: true,
    fullWidth: false,
    round: true,
  },
});

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variants?: VariantProps<typeof buttonVariants>;
  height: TailwindProperty<number>;
  paddingX: TailwindProperty<number>;
  loading?: boolean;
}

function getButtonClasses({ variants, height, paddingX, loading, disabled, className }: ButtonProps) {
  const pxClass = twClass("px-", paddingX);
  const heightClass = twClass("h-", height);
  className = clsx(className, pxClass, heightClass);

  return twMerge(buttonVariants({ ...variants, loading, disabled, className }));
}

function Button(
  {
    variants,
    height,
    paddingX,
    loading,
    className,
    children,
    disabled,
    type = "button",
    ...props
  }: PropsWithChildren<ButtonProps>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const classes = getButtonClasses({ variants, height, paddingX, loading, disabled, className });
  return (
    <button ref={ref} className={classes} disabled={loading || disabled} type={type} {...props}>
      {loading && (
        <div className="absolute w-[18px] h-[18px]">
          <LoadingIndicator height="18" width="18" />
        </div>
      )}
      {loading && <div className="invisible">{children}</div>}
      {!loading && children}
    </button>
  );
}

export default forwardRef(Button);
export { getButtonClasses };
