import React, { Dispatch, SetStateAction } from "react";

/** ****************** */
/*   NOTIF CONTEXT   */
/** ****************** */

type NotificationsContext = {
  showNotifications: boolean;
  setShowNotifications: Dispatch<SetStateAction<boolean>>;
};

export const NotificationsContext = React.createContext<NotificationsContext>({
  showNotifications: false,
  setShowNotifications: () => undefined,
});

/** ******************* */
/*   MOBILE CONTEXT   */
/** ******************* */

type MobileSSRContext = {
  isMobileSSR: boolean;
};

export const MobileSSRContext = React.createContext<MobileSSRContext>({ isMobileSSR: true });

/** ******************* */
/*   CHAT CONTEXT    */
/** ******************* */

type ChatContext = {
  setShowChat: Dispatch<SetStateAction<boolean>>;
};

export const ChatContext = React.createContext<ChatContext>({
  setShowChat: () => undefined,
});
