import React from "react";

import Link from "@/components/hoc/CustomLink";

export default function TransLink({
  href,
  shallow,
  children,
  ...props
}: Omit<React.HTMLProps<HTMLAnchorElement>, "href"> & {
  href: React.ComponentProps<typeof Link>["href"];
  shallow?: boolean;
}) {
  return (
    <Link href={href || ""} shallow={shallow}>
      <a {...props}>{children}</a>
    </Link>
  );
}
