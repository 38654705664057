import { ApolloCache } from "@apollo/client";
import { DeepPartial } from "react-hook-form";

import { BuilderScarcity, TrackBuilderFormData } from "@/components/dashboard/TrackBuilder/types";
import { exhaustiveSwitch } from "@/utils/exhaustiveSwitch";
import { deepUnwrapMaybe } from "@/utils/graphql";
import { isUserCredit } from "@/utils/credits";
import {
  BasicBuilderTrackFragment,
  BuilderNftFragment,
  BuilderNftInput,
  BuilderReleasesInput,
  CreateTrackMutation,
  DeleteTrackMutation,
  DetailedBuilderTrackFragment,
  GetBuilderTracksDocument,
  GetBuilderTracksQuery,
  GetBuilderTracksQueryVariables,
  Rarity,
  ReleaseState,
  SaleType,
  SearchTrackBuilderFragment,
  TrackCreditRole,
  TrackRightsType,
  UnlockableContent,
} from "@/graphql/types";

type UpdateBuilderTrackResultParams =
  | {
      tracks: GetBuilderTracksQuery["tracks"] | undefined;
      track: BasicBuilderTrackFragment;
      action: "add";
    }
  | {
      tracks: GetBuilderTracksQuery["tracks"] | undefined;
      track: { id: string };
      action: "remove";
    };

function updateBuilderTracksResult({ tracks, track, action }: UpdateBuilderTrackResultParams) {
  if (action === "add") {
    return {
      results: [track, ...(tracks?.results ?? [])],
      size: (tracks?.size ?? 0) + 1,
    };
  }
  return {
    results: tracks?.results?.filter((b) => b.id !== track.id) ?? [],
    size: (tracks?.size ?? 1) - 1,
  };
}

/**
 * Creates an Apollo Cache Update function for the
 * CreateTrack mutation
 *
 * Adds the created builder to the list of the
 * GetBuilderTracks with the variables you pass.
 *
 * @example
 * ```
 * getUpdateCacheOnCreateTrackMutation({
 *   mintStates: [MintState.Intent],
 * })
 * ```
 * Will add the created builder to the corresponding
 * query's cache.
 */
export function getUpdateCacheOnCreateTrackMutation(variables: GetBuilderTracksQueryVariables) {
  return (cache: ApolloCache<unknown>, { data }: { data?: CreateTrackMutation | null | undefined }) => {
    const createTrack = data?.trackCreate;
    if (createTrack?.id) {
      cache.updateQuery({ query: GetBuilderTracksDocument, variables }, (data) => ({
        tracks: updateBuilderTracksResult({ track: createTrack, tracks: data?.tracks, action: "add" }),
      }));
    }
  };
}

/**
 * Creates an Apollo Cache Update function for the DeleteTrack mutation
 *
 * Removes the builder with `builderId` from the list of the
 * GetTracksQuery with the variables you pass.
 *
 * @example
 * ```
 * getUpdateCacheOnTrackDeleteMutation("builder-id", {
 *   mintStates: [MintState.Requested],
 *   trackFilter: { builderIds: ["builder-id"] },
 * })
 * ```
 * Will remove builder with id "builder-id" from the
 * corresponding query's cache.
 */
export function getUpdateCacheOnTrackDeleteMutation(
  builderId: string,
  variables: GetBuilderTracksQueryVariables
) {
  return (cache: ApolloCache<unknown>, { data }: { data?: DeleteTrackMutation | null | undefined }) => {
    if (data?.trackDelete) {
      cache.updateQuery({ query: GetBuilderTracksDocument, variables }, (data) => ({
        tracks: updateBuilderTracksResult({
          track: { id: builderId },
          tracks: data?.tracks,
          action: "remove",
        }),
      }));
    }
  };
}

/**
 * Checks if a mint builder and optionnally one of its
 * releases are ready to be scheduled for mint.
 *
 * @param mb - mint builder
 * @param release - mint builder release
 * @returns true if ready to schedule, false otherwise
 */
export function isReadyToSchedule(
  mb: DetailedBuilderTrackFragment | undefined,
  release?: BuilderScarcity & { state?: ReleaseState }
): boolean {
  if (!mb) return false;
  if (!mb.artists || mb.artists.length === 0) return false;
  if (!mb.title) return false;
  if (!mb.description) return false;
  if (!mb.genres || mb.genres.length === 0) return false;
  if (!mb.legal.credits || mb.legal.credits.length === 0) return false;
  if (
    mb.legal.credits.reduce(
      (acc, cur) => acc + (isUserCredit(cur) && cur.royalties ? cur.royalties : 0),
      0
    ) !== 1_000_000
  )
    return false;
  if (!mb.legal.rightsType || (mb.legal.rightsType !== TrackRightsType.Original && !mb.legal.rights))
    return false;
  if (!mb.audio?.flac.url) return false;
  if (!mb.thumbnail?.fullResUrl) return false;
  if (release) {
    if (release.state && release.state !== ReleaseState.Draft) return false;
    if (!release.scarcity || !release.nfts || release.nfts.length === 0) return false;
    if (
      release.nfts.some(
        (n) =>
          !n.startAt ||
          (n.saleType === SaleType.Auction && !n.limitDate) ||
          (!n.price && n.saleType !== SaleType.Bounty)
      )
    )
      return false;
  }

  return true;
}

/**
 * Maps an existing mint builder to the default values used
 * to fill up a ReactHookForm.
 *
 * @param builder - mint builder
 *
 * @returns default values used for the useForm hook
 */
export function getDefaultBuilderValues(
  builder: DetailedBuilderTrackFragment | undefined,
  fallback?: DeepPartial<TrackBuilderFormData>
): DeepPartial<TrackBuilderFormData> {
  return {
    title: builder?.title ?? fallback?.title ?? "",
    description: builder?.description ?? fallback?.description ?? "",
    genres: builder ? !!builder?.genres.length : !!fallback?.genres,
    audio: fallback?.audio,
    cover: fallback?.cover,
    isrcCode: builder?.legal.isrcCode ?? fallback?.isrcCode ?? "",
    credits:
      builder?.legal.credits?.map((credit) => ({
        id: credit.id,
        role: credit.role ?? TrackCreditRole.Artist,
        ...deepUnwrapMaybe(credit),
        anonymous: !isUserCredit(credit),
        royalties: isUserCredit(credit) && credit.royalties ? credit.royalties / 10_000 : 0,
      })) ??
      fallback?.credits ??
      [],
    rights: {
      title: builder?.legal.rights?.title ?? fallback?.rights?.title ?? "",
      originalPerformer:
        builder?.legal.rights?.originalPerformer ?? fallback?.rights?.originalPerformer ?? "",
      publisher: builder?.legal.rights?.publisher ?? fallback?.rights?.publisher ?? "",
      label: builder?.legal.rights?.label ?? fallback?.rights?.label ?? "",
      year: builder?.legal.rights?.year ?? fallback?.rights?.year ?? 0,
      composers: builder?.legal.rights?.composers?.map((c) => ({ name: c })) ??
        fallback?.rights?.composers ?? [{ name: "" }],
    },
    scarcities: getScarcitiesFromBuilder(builder) ?? fallback?.scarcities ?? [],
  };
}

function getScarcitiesFromBuilder(builder: DetailedBuilderTrackFragment | undefined) {
  return builder?.releases.map((r) => ({
    releaseId: r.id,
    state: r.buildState?.state,
    scarcity: r.rarity,
    bounty: r.bounty
      ? {
          public: r.bounty.isPublic,
          howToClaim: r.bounty.howToClaim,
          allowPianityBounties: r.bounty.allowPianityBounties,
        }
      : undefined,
    exclusiveContent: mapUnlockableToExclusive(r.unlockableContent),
    nfts: mapNFTsToInput(r.buildState?.nfts),
  }));
}

function mapNFTsToInput(nfts: BuilderNftFragment[] | undefined | null): BuilderNftInput[] | undefined {
  return nfts?.map((n) => ({
    range: n.range,
    startAt: n.startAt,
    saleType: n.saleType,
    limitDate: n.limitDate,
    price: n.price,
  }));
}

function mapUnlockableToExclusive(content: UnlockableContent | undefined | null) {
  if (!content) return undefined;

  return {
    driveUrl: content.folderId ? `https://drive.google.com/drive/folders/${content.folderId}` : undefined,
    perks: content.perks?.map((p) => ({ text: p })) ?? [],
    images: content.mediaUrls?.map((m) => ({ url: m })),
  };
}

export function mapBuilderScarcitiesToReleaseInput(scarcities: BuilderScarcity[]): BuilderReleasesInput {
  return scarcities.reduce((acc, scarcity) => {
    let { nfts } = scarcity;
    if (!nfts && scarcity.bounty) {
      nfts = [
        {
          range: getScarcityCount(scarcity.scarcity),
          saleType: SaleType.Bounty,
          price: 0,
        },
      ];
    }
    acc[scarcity.scarcity.toLowerCase() as keyof BuilderReleasesInput] = {
      nfts,
      bounty: scarcity.bounty ?? null,
      unlockableContent: scarcity.exclusiveContent
        ? {
            perks: scarcity.exclusiveContent.perks.map((p) => p.text),
            driveUrl: scarcity.exclusiveContent.driveUrl ?? undefined,
            medias: scarcity.exclusiveContent.images?.map((image, index) => ({
              newMedia: image.file?.[0]?.size ? image.file[0] : undefined,
              existingMediaIndex: index,
            })),
          }
        : null,
    };
    return acc;
  }, {} as BuilderReleasesInput);
}

export function getScarcityCount(scarcity: Rarity): number {
  switch (scarcity) {
    case Rarity.Unique:
      return 1;
    case Rarity.Legendary:
      return 10;
    case Rarity.Epic:
      return 100;
    case Rarity.Rare:
      return 1000;

    default:
      return exhaustiveSwitch(scarcity);
  }
}

export function getScarcityIndex(scarcity: Rarity): number {
  switch (scarcity) {
    case Rarity.Unique:
      return 0;
    case Rarity.Legendary:
      return 1;
    case Rarity.Epic:
      return 2;
    case Rarity.Rare:
      return 3;

    default:
      return exhaustiveSwitch(scarcity);
  }
}

export function getMostAdvancedRelease(
  releases: SearchTrackBuilderFragment["releases"]
): SearchTrackBuilderFragment["releases"][0] | undefined {
  const states = releases.reduce((acc, cur) => {
    acc[cur.buildState] = cur;
    return acc;
  }, {} as Record<ReleaseState, SearchTrackBuilderFragment["releases"][0]>);

  return (
    states[ReleaseState.MintConfirmed] ??
    states[ReleaseState.MintWaitingTrackTransaction] ??
    states[ReleaseState.MintWaitingThumbnailTransaction] ??
    states[ReleaseState.MintStarted] ??
    states[ReleaseState.MintError] ??
    states[ReleaseState.Blocked] ??
    states[ReleaseState.Scheduled] ??
    states[ReleaseState.Draft]
  );
}

export function getMostAdvancedReleaseBuilder(
  releases: DetailedBuilderTrackFragment["releases"]
): DetailedBuilderTrackFragment["releases"][0] | undefined {
  const states = releases.reduce((acc, cur) => {
    if (cur.buildState) {
      acc[cur.buildState?.state] = cur;
    }
    return acc;
  }, {} as Record<ReleaseState, DetailedBuilderTrackFragment["releases"][0]>);

  return (
    states[ReleaseState.MintConfirmed] ??
    states[ReleaseState.MintWaitingTrackTransaction] ??
    states[ReleaseState.MintWaitingThumbnailTransaction] ??
    states[ReleaseState.MintStarted] ??
    states[ReleaseState.MintError] ??
    states[ReleaseState.Blocked] ??
    states[ReleaseState.Scheduled] ??
    states[ReleaseState.Draft]
  );
}
