import { OAUTH_CLIENT_ID, requestAccessToken, requestAuthorizationCode } from "@/utils/oAuth/requests";
import { Currency, DetailedPrivateUserFragment, MeDocument } from "@/graphql/types";
import { LS_AUTH_PREFER_LOGIN } from "@/utils/constants";
import { getCurrencyCode } from "@/utils/currencies";
import { saveAuthTokens } from "@/apollo/links/auth";
import { saveAuthUser } from "@/utils/oAuth/storage";
import { isOAuthError } from "@/utils/oAuth/helpers";
import { initializeApollo } from "@/apollo";
import {
  OAuthAuthMethods,
  OAuthAuthorizationCodeRequestArgs,
  OAuthAuthorizationCodeTokenRequest,
  OAuthResponse,
} from "@/utils/oAuth/types";

/**
 * Attempts to log in the user with the given arguments.
 *
 * @param type - The type of the connect request (login or signup).
 * @param method - The method to request the authorization code with.
 * @param args - The arguments to pass to the API call.
 *
 * @returns - The logged in user object, the OAuth error if there was one
 * or undefined if there was an unknown error.
 */
export async function connectWithOAuth(
  type: "login" | "signup",
  method: OAuthAuthMethods,
  args: OAuthAuthorizationCodeRequestArgs
): Promise<OAuthResponse<DetailedPrivateUserFragment> | undefined> {
  // Request the AuthCode from the server
  const authorizationResponse = await requestAuthorizationCode(type, method, args);
  if (isOAuthError(authorizationResponse)) {
    return authorizationResponse;
  } else {
    // Create the Access Token Request
    const request: OAuthAuthorizationCodeTokenRequest = {
      grant_type: "authorization_code",
      code: authorizationResponse.code,
      redirect_uri: authorizationResponse.redirect_uri,
      client_id: OAUTH_CLIENT_ID,
    };

    // Request the Access Token
    const accessResponse = await requestAccessToken(request);
    if (!isOAuthError(accessResponse)) {
      // Handle storage of the tokens
      saveAuthTokens(accessResponse);
      // Prefer login next time
      localStorage.setItem(LS_AUTH_PREFER_LOGIN, "true");

      const client = initializeApollo();
      // Fetch the user's data
      const query = await client
        .query({
          query: MeDocument,
          variables: { currency: getCurrencyCode() as string as Currency },
        })
        .catch(() => undefined);

      // Return logged in user
      if (query?.data) {
        // Save basic info to local storage
        saveAuthUser(query.data.me);
        return query.data.me;
      }
    } else {
      return accessResponse;
    }
  }

  return undefined;
}
