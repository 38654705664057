import confetti, { Options, CreateTypes } from "canvas-confetti";
import { MutableRefObject, useEffect, useRef } from "react";

interface Args {
  delay?: number;
}

/**
 * Creates a full screen confetti effect.
 *
 * @remarks injects a canvas as the first child of
 * the #modal-root div to appear on top of all other
 * DOM elements.
 */
export function useConfetti(args?: Args) {
  const confettiRef = useRef<CreateTypes>();

  useEffect(() => {
    if (args?.delay) {
      const timeout = setTimeout(() => fireConfetti(confettiRef), args.delay);
      return () => clearTimeout(timeout);
    } else {
      fireConfetti(confettiRef);
    }

    return undefined;
  }, [args?.delay]);
}

export function fireConfetti(ref: MutableRefObject<CreateTypes | undefined>) {
  if (!ref) return;

  async function fire(particleRatio: number, opts: Options) {
    await ref.current?.({ origin: { y: 0.7 }, ...opts, particleCount: Math.floor(200 * particleRatio) });
  }

  if (!ref.current) {
    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.style.position = "fixed";
    canvas.style.top = "0";
    canvas.style.left = "0";
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    canvas.style.pointerEvents = "none";
    canvas.style.zIndex = "999999999";
    document.getElementById("modal-root")?.prepend(canvas);
    ref.current = confetti.create(canvas, { resize: true, useWorker: true });
  }

  const promise = fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });

  // Clear canvas after animation
  promise.finally(() => {
    const modalRoot = document.getElementById("modal-root");
    const canvas = modalRoot?.querySelector("canvas");
    if (canvas) document.getElementById("modal-root")?.removeChild(canvas);
  });
}
