import { RequireAtLeastOne } from "@/utils/types";

const twBreakpoints = ["base", "xs", "sm", "md", "lg", "2lg", "xl", "2xl"] as const;
type TailwindBreakpoint = (typeof twBreakpoints)[number];

export type TailwindProperty<T> = RequireAtLeastOne<{ [key in TailwindBreakpoint]: T }>;

/**
 * Builds a className string using the given breakpoint values.
 *
 * @param property - Tailwind property with optional breakpoints
 * @returns className string
 */
export function twClass<T>(prefix: string, property: TailwindProperty<T>) {
  return Object.entries(property)
    .map(([key, value]) => {
      if (value === undefined) return "";
      if (key === "base") return `${prefix}${value}`;
      return `${key}:${prefix}${value}`;
    })
    .join(" ");
}

export function smallestTWProperty<T>(property: TailwindProperty<T>) {
  for (const bp of twBreakpoints) {
    if (property[bp] !== undefined) return property[bp];
  }
  throw new Error("At least one breakpoint key is required");
}

export function largestTWProperty<T>(property: TailwindProperty<T>) {
  for (let i = twBreakpoints.length - 1; i >= 0; i--) {
    const bp = twBreakpoints[i];
    if (property[bp] !== undefined) return property[bp];
  }
  throw new Error("At least one breakpoint key is required");
}
