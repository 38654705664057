// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { SENTRY_DSN, NEXT_ENV } from "@/env";
import * as Sentry from "@sentry/nextjs";

// Init Sentry
Sentry.init({
  environment: NEXT_ENV,
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  beforeSend(event, hint) {
    // Note: Required to ignore safe throw used to cancel route change on unsaved form data.
    if (hint.originalException === "Route change safely aborted. Please ignore.") return null;
    return event;
  },
});
