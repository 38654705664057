import { SVGProps } from "react";

export default function BountyIcon(props: SVGProps<SVGSVGElement>) {
  const { width = "21", height = "26", ...rest } = props;
  return (
    <svg {...{ width, height }} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 26" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.9373 2.19648C0.867361 2.34685 0.1219 3.33611 0.272271 4.40606L3.06788 24.2979C3.21825 25.3678 4.20751 26.1133 5.27746 25.9629L18.9693 24.0386C20.0393 23.8883 20.7847 22.899 20.6344 21.8291L17.8388 1.93724C17.6884 0.867301 16.6991 0.12184 15.6292 0.27221L1.9373 2.19648ZM12.2997 10.6685L9.52627 6.51586L8.00494 11.2721L3.8523 14.0455L8.60852 15.5668L11.3819 19.7194L12.9032 14.9632L17.0559 12.1898L12.2997 10.6685Z"
        className="fill-current"
      />
    </svg>
  );
}
