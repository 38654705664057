import { i18n } from "next-i18next";

import {
  OAuthAuthMethods,
  OAuthError,
  OAuthErrorCode,
  OAuthErrorData,
  OAuthResponse,
} from "@/utils/oAuth/types";
import { ReportErrorDocument } from "@/graphql/types";
import { toast } from "@/utils/toasts";
import { getAnalytics, getPosthog } from "@/utils/analytics";
import { initializeApollo } from "@/apollo";
import { NEXT_ENV } from "@/env";

// Inexisting accounts for these methods should be presented
// with the sign up flow instead of an error.
const IGNORE_NOT_FOUND_METHODS = ["google", "apple"];

function getCorrectAuthMethodString(method: OAuthAuthMethods | undefined): string | undefined {
  switch (method) {
    case "wallet":
      return i18n?.t("connect:connect.notifications.errors.method.with.wallet");
    case "google":
      return "Google";
    case "apple":
      return "Apple";
    default:
      return i18n?.t("connect:connect.notifications.errors.method.with.password");
  }
}

function reportError(error: OAuthError, path?: string): void {
  if (NEXT_ENV === "development") {
    console.error("OAuth Error", error);
    return;
  }

  initializeApollo()?.mutate({
    mutation: ReportErrorDocument,
    variables: {
      exception: `
        DESCRIPTION: ${error.error_description}\n\n
        DATA: ${JSON.stringify(error.error_data, undefined, 2)}\n\n
        PostHog Distinct ID: ${getPosthog()?.get_distinct_id()}
      `,
      path: `Frontend: OAuth error - ${path}`,
      message: error.error,
    },
  });
}

/**
 * Check if the OAuth response is an error.
 * To be used when receiving a response from the OAuth API.
 *
 * @param response - The response to check.
 *
 * @returns true if the response is an error, false otherwise.
 */
export function isOAuthError<T>(response: OAuthResponse<T>): response is OAuthError {
  return (response as OAuthError).error !== undefined;
}

/**
 * Handle an error from the OAuth API by notifying the user
 * with an appropriate error message.
 *
 * @param error - The error to handle.
 * @param method - Method used for the request.
 *
 * @remarks
 * Uses a generic error message when the error is unknown.
 */
function handleOAuthError(error: OAuthError, method?: OAuthAuthMethods, path?: string): OAuthError {
  let errorMessage = i18n?.t("notifications:notifications.messages.errors.default");
  const i18nBaseKey = "connect:connect.notifications.errors.";
  switch (error.error) {
    case "requires_2fa":
      // Needs more user input to complete the authentication process.
      return error;

    case "incorrect_password":
      errorMessage = i18n?.t(i18nBaseKey + "login.password");
      break;

    case "not_found":
      if (method && IGNORE_NOT_FOUND_METHODS.includes(method)) return error;
      errorMessage = i18n?.t(i18nBaseKey + "login.account", { context: method });
      break;

    case "wrong_authentification_method":
      errorMessage = i18n?.t(i18nBaseKey + "method.email", {
        method: getCorrectAuthMethodString(error.error_data?.correct_method),
      });
      break;

    case "bad_credentials":
      if (method && IGNORE_NOT_FOUND_METHODS.includes(method)) return error;
      errorMessage = i18n?.t(i18nBaseKey + "login.credentials");
      break;

    case "invalid_jwt":
      errorMessage = i18n?.t(i18nBaseKey + "external.expired");
      break;

    case "invalid_email":
      errorMessage = i18n?.t(i18nBaseKey + "invalid.email");
      break;

    case "invalid_password":
      errorMessage = i18n?.t(i18nBaseKey + "invalid.password");
      break;

    case "invalid_username":
      errorMessage = i18n?.t(i18nBaseKey + "invalid.username");
      break;

    case "invalid_2fa":
      errorMessage = i18n?.t(i18nBaseKey + "invalid.2fa");
      break;

    case "email_already_used":
      errorMessage = i18n?.t(i18nBaseKey + "used.email");
      break;

    case "undeliverable_email":
      errorMessage = i18n?.t(i18nBaseKey + "invalid.email_undeliverable");
      break;

    case "username_already_used":
      errorMessage = i18n?.t(i18nBaseKey + "used.username");
      break;

    case "public_key_already_used":
      errorMessage = i18n?.t(i18nBaseKey + "used.wallet");
      break;

    case "captcha_failed":
      errorMessage = i18n?.t(i18nBaseKey + "captcha");
      break;

    case "user_deactivated":
      errorMessage = i18n?.t(i18nBaseKey + "login.suspended");
      break;

    // NOTE: Invalid Grant is for all errors on the /auth/token endpoint
    case "invalid_grant":
    case "unsupported_grant_type":
      errorMessage = i18n?.t("notifications:notifications.messages.errors.auth.expired");
      break;

    default:
      errorMessage = i18n?.t("notifications:notifications.messages.errors.default");
      reportError(error, path);
      break;
  }
  toast.error(errorMessage ?? "Oops... something went wrong.", {
    toastId: "oauth-error-notification",
  });
  getAnalytics()?.track("WEB - Auth - Error - Displayed", {
    errorCode: error.error,
    errorDescription: error.error_description,
    errorData: error.error_data,
    method,
    path,
  });

  return error;
}

/**
 * Makes a fetch call to the given url and handles all possible
 * error outcomes.
 *
 * @param url - The url to send the request to.
 * @param options - The options for the fetch call.
 *
 * @returns The response from the fetch call or undefined if there was an error.
 */
export async function makeOAuthAPICall<T>(
  url: string,
  options: RequestInit,
  method?: OAuthAuthMethods
): Promise<OAuthResponse<T>> {
  const anonID = getPosthog()?.get_distinct_id();
  options = anonID ? { ...options, headers: { ...options.headers, "x-rs-anonymous-id": anonID } } : options;
  try {
    const response = (await fetch(url, options).then((res) => res.json())) as OAuthResponse<T>;
    if (isOAuthError(response)) {
      return handleOAuthError(response, method, url);
    } else return response;
  } catch (err: unknown) {
    if (NEXT_ENV === "development") {
      console.error(err);
    }

    const error = {
      error: "unknown" as OAuthErrorCode,
      error_description: "Something went wrong...",
      error_data: err as OAuthErrorData,
    };
    return handleOAuthError(error, method, url);
  }
}
