import { SVGProps } from "react";

export default function Chevron(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none" {...props}>
      <path
        d="M11 6.60712L6.75258 1.75292C6.35417 1.29759 5.64583 1.29759 5.24742 1.75292L1 6.60712"
        className="stroke-current"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
