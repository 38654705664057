import { TFunction, useTranslation } from "next-i18next";
import { useReactiveVar, useSubscription } from "@apollo/client";
import { useRouter } from "next/router";
import { toast as toastify } from "react-toastify";
import dynamic from "next/dynamic";
import { useEffect } from "react";

import { useResendVerifyEmailMutation } from "@/utils/mutationHooks";
import { LS_REFERRAL_CODE, SS_CLAIM_CODE } from "@/pages/_app";
import { logoutUser } from "@/utils/oAuth/tokens";
import { showNotification, toast } from "@/utils/toasts";
import {
  WalletApproveState,
  DetailedPrivateUserFragment,
  Maybe,
  OnMeVerifiedDocument,
  UserTransactionType,
} from "@/graphql/types";
import { usePhoneVerification } from "@/components/phone-verification/Provider";
import { hasAccessTokenVar } from "@/apollo/links/auth";

const LabelInvitationToast = dynamic(() => import("@/components/notifications/toasts/LabelInvitation"));
const UnverifiedEmailToast = dynamic(() => import("@/components/notifications/toasts/UnverifiedEmail"));
const ArConnectErrorToast = dynamic(() => import("@/components/notifications/toasts/ArConnectError"));

export const TOAST_ID_WALLET_ERROR = "arconnect-not-signed-error";
export const TOAST_ID_WALLET_WARNING = "arconnect-pending-warning";

export default function useDisplayUserState(me: DetailedPrivateUserFragment | undefined) {
  const isLogged = useReactiveVar(hasAccessTokenVar);
  const { t } = useTranslation("common");
  const router = useRouter();

  const { data } = useSubscription(OnMeVerifiedDocument, {
    fetchPolicy: "network-only",
    skip: !isLogged,
  });
  const [resendVerifyEmailMutation] = useResendVerifyEmailMutation();

  const { demandPhoneVerification } = usePhoneVerification();

  useEffect(() => {
    if (data?.meSubscription.isEmailVerified) {
      toastify.dismiss("unverified-email");
    }
  }, [data]);

  useEffect(() => {
    async function resendVerifyEmail() {
      const res = await resendVerifyEmailMutation();
      if (!res.errors) toast.success(t("common.utils.warnings.unverified.sent"));
    }

    // Do not display user state when still on connect page
    if (router.asPath.startsWith("/connect")) return;

    // Verify users who might have manually switched their URL
    // in an attempt to bypass the phone verification
    if (me?.limited) {
      demandPhoneVerification({
        onSuccess() {
          // Do nothing
        },
        onQuit() {
          logoutUser();
        },
      });
      return;
    }

    // Do not display state notifications if claiming a bounty/giveaway
    if (!!localStorage.getItem(LS_REFERRAL_CODE) || !!sessionStorage.getItem(SS_CLAIM_CODE)) return;

    if (me) {
      if (!me.isEmailVerified && me.createdAt < new Date(Date.now() - 1000 * 60 * 60 * 24).toISOString()) {
        showNotification(<UnverifiedEmailToast onClick={resendVerifyEmail} />, {
          tag: "account",
          level: "important",
          containerId: "notifications",
          toastId: "unverified-email",
          closeButton: false,
          draggable: false,
          autoClose: false,
        });
      } else {
        toastify.dismiss("unverified-email");
      }

      // Wallet User
      if (me.transactionType === UserTransactionType.Wallet) {
        const state = me.walletApproveState;
        const isArConnect = !!me.arconnectPublicModulus;

        if (state === WalletApproveState.Failed || state === WalletApproveState.NotSent) {
          showNotification(<ArConnectErrorToast />, {
            autoClose: false,
            toastId: TOAST_ID_WALLET_ERROR,
            tag: isArConnect ? "arconnect" : "metamask",
            level: "error",
            closeButton: false,
            draggable: false,
          });
        }
      }

      if (me.artist && me.artist.invitesFromLabel?.results.length > 0) {
        for (const label of me.artist.invitesFromLabel.results) {
          showNotification(<LabelInvitationToast {...{ label }} />, {
            autoClose: false,
            toastId: "label-invite-" + label.id,
            tag: "label",
            level: "info",
            imageUrl: label.owner.avatarUrl,
            closeButton: false,
            draggable: false,
          });
        }
      }
    }
  }, [me, router, demandPhoneVerification, resendVerifyEmailMutation, t]);
}

type WalletUser = {
  transactionType: UserTransactionType;
  walletApproveState?: Maybe<WalletApproveState>;
  arconnectPublicModulus?: Maybe<string>;
};

/**
 * Checks a user's wallet approval state
 *
 * @remarks
 * Will display a notification is the user is not approved
 *
 * @param user - user to check
 * @param t - translation function
 * @returns true if user has approved wallet, false otherwise
 */
export function checkUserWalletApproveState(user: WalletUser, t: TFunction, context: string) {
  if (user.transactionType !== UserTransactionType.Wallet) return true;

  const isArConnect = !!user.arconnectPublicModulus;
  if (user.walletApproveState === WalletApproveState.Pending)
    showNotification(t("notifications:notifications.messages.arconnect.pending", { context }), {
      toastId: TOAST_ID_WALLET_WARNING,
      containerId: "errors",
      tag: isArConnect ? "arconnect" : "metamask",
      level: "important",
    });
  else if (user.walletApproveState !== WalletApproveState.Confirmed)
    showNotification(<ArConnectErrorToast />, {
      toastId: TOAST_ID_WALLET_ERROR,
      containerId: "errors",
      tag: isArConnect ? "arconnect" : "metamask",
      level: "error",
    });

  return user.walletApproveState === WalletApproveState.Confirmed;
}
