import Transaction from "arweave/web/lib/transaction";
import { Dispatch, SetStateAction } from "react";
import { MutationTuple } from "@apollo/client";
import { toast } from "react-toastify";

import { getWarp, sign, walletDataToPayload } from "@/utils/walletConnection/common";
import { WalletData, arconnect, metamask } from "@/utils/walletConnection";
import { TOAST_ID_WALLET_ERROR } from "@/utils/hooks/useDisplayUserState";
import { PIANITY_API_ADDRESS, PIANITY_ERC1155_ID } from "@/env";
import { requestWalletChallenge } from "@/utils/oAuth/requests";
import { cancelablePromise } from "@/utils/cancelablePromise";
import { exhaustiveSwitch } from "@/utils/exhaustiveSwitch";
import { isOAuthError } from "@/utils/oAuth/helpers";
import { getCurrencyCode } from "@/utils/currencies";
import { getPosthog } from "@/utils/analytics";
import {
  WalletApproveState,
  CreateWalletApproveTransactionMutation,
  CreateWalletApproveTransactionMutationVariables,
  Currency,
  MeDocument,
  MeQuery,
  MeQueryVariables,
  ReportErrorMutation,
  ReportErrorMutationVariables,
  ValidateTransactionMutation,
  ValidateTransactionMutationVariables,
} from "@/graphql/types";

/**
 * Returns a signed transaction on success, undefined on error.
 */
async function sendApprovalInteraction(
  walletData: WalletData,
  setCancelWalletInteraction: Dispatch<SetStateAction<(() => void) | undefined>>
): Promise<string> {
  const erc1155 = (await getWarp()).contract(PIANITY_ERC1155_ID);

  const makeTaskCancelable = <T, U extends Array<unknown>>(
    task: (...args: U) => Promise<T>
  ): (() => Promise<T>) => {
    return async (...args: U): Promise<T> => {
      const { promise, cancel } = cancelablePromise(task(...args));
      setCancelWalletInteraction(() => cancel);
      try {
        const result = await promise;
        return result;
      } finally {
        setCancelWalletInteraction(undefined);
      }
    };
  };

  switch (walletData.type) {
    case "arconnect": {
      const cancelableSigner = makeTaskCancelable((tx: Transaction) =>
        arconnect.signTx(walletData.publicKey, tx)
      );

      erc1155.connect({ type: "arweave", signer: cancelableSigner });
      break;
    }

    case "metamask": {
      const { InjectedEthereumSigner } = await import("warp-contracts-plugin-signature");
      const { providers } = await import("ethers");

      // NOTE: warp-contracts-plugin-signature forces us to use an older version of `ethers` (v5),
      // which isn't up to date with `MetaMaskInpageProvider` defined in `@metamask/providers`,
      // which we use to type `window.ethereum` in `src/utils/walletConnection/metamask.ts`.
      // The type error below is a result of this and is safe to ignore.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const provider = new providers.Web3Provider(window.ethereum as any);
      const signer = new InjectedEthereumSigner(provider);

      if (walletData.publicKey) {
        signer.publicKey = walletData.publicKey;
      } else {
        await makeTaskCancelable(async () =>
          metamask.sign(walletData, "Please sign this message to finalize your registration.")
        )();

        if (!walletData.publicKey) {
          throw new Error("Couldn't recover public key from signature");
        }

        signer.publicKey = walletData.publicKey;
      }

      const originalSign = signer.sign;
      signer.sign = makeTaskCancelable(originalSign.bind(signer));

      // NOTE: warp-contracts messes up the type of the argument, the type error is safe to ignore.
      // The bug was reported here <https://github.com/warp-contracts/warp-arbundles/issues/1>.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      erc1155.connect(signer as any);
      break;
    }

    default:
      exhaustiveSwitch(walletData);
  }

  const setApprovalInput = {
    function: "setApprovalForAll",
    target: PIANITY_API_ADDRESS,
    approved: true,
  };

  const interaction = await erc1155.writeInteraction(setApprovalInput);

  if (!interaction) {
    // TODO:noom properly handle error instead of throwing an empty error
    throw new Error("Couldn't post transaction");
  }

  return interaction.originalTxId;
}

export async function signWalletDataForSignup({
  walletData,
  email,
}: {
  walletData: WalletData;
  email: string;
}) {
  const walletDataPayload = walletDataToPayload(walletData);

  const challenge = await requestWalletChallenge({
    type: "signup",
    payload: { ...walletDataPayload, email },
  });

  if (isOAuthError(challenge)) {
    throw new Error(`OAuth error received in place of challenge: ${JSON.stringify(challenge)}`);
  }

  // Sign the challenge with the user's wallet
  return sign(walletData, challenge.challenge).then((challengeSignature) => ({
    email,
    challenge: challenge.challenge,
    signature: challengeSignature,
    ...walletDataPayload,
  }));
}

export async function createApprovalTransaction(
  walletData: WalletData,
  createTransactionMutation: MutationTuple<
    CreateWalletApproveTransactionMutation,
    CreateWalletApproveTransactionMutationVariables
  >[0],
  validateTransactionMutation: MutationTuple<
    ValidateTransactionMutation,
    ValidateTransactionMutationVariables
  >[0],
  reportErrorMutation: MutationTuple<ReportErrorMutation, ReportErrorMutationVariables>[0],
  setCancelWalletInteraction: Dispatch<SetStateAction<(() => void) | undefined>>
) {
  const createTxMutationResult = await createTransactionMutation();

  if (!createTxMutationResult.data) {
    return;
  }

  const { id: transactionIntentId } = createTxMutationResult.data.createWalletApproveTransaction;

  const transactionId = await sendApprovalInteraction(walletData, setCancelWalletInteraction);

  const signedTxMut = await validateTransactionMutation({
    variables: {
      transactionIntentId,
      transactionId,
    },
    update: (cache, { data }) => {
      const currency = getCurrencyCode() as string as Currency;
      const meQuery = cache.readQuery<MeQuery, MeQueryVariables>({
        variables: { currency },
        query: MeDocument,
      });

      if (data && meQuery) {
        toast.dismiss(TOAST_ID_WALLET_ERROR);
        cache.writeQuery<MeQuery, MeQueryVariables>({
          query: MeDocument,
          variables: { currency },
          data: { me: { ...meQuery.me, walletApproveState: WalletApproveState.Pending } },
        });
      }
    },
  });

  if (signedTxMut.errors) {
    const variables = {
      exception: `\n\nPostHog Distinct ID: ${getPosthog()?.get_distinct_id()}`,
      path: "Frontend: internal",
      message: "ArConnect Sign ERROR",
    };
    await reportErrorMutation({ variables });
  }
}
