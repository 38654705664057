import clsx from "clsx";
import { SVGProps } from "react";

export default function LoadingIndicator(props: SVGProps<SVGSVGElement>) {
  const { width = "13", height = "13", className, ...rest } = props;

  return (
    <svg
      {...rest}
      {...{ width, height }}
      className={clsx(className, "animate-spin")}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        d="M6.5 2C5.60999 2 4.73996 2.26392 3.99994 2.75839C3.25991 3.25285 2.68314 3.95566 2.34254 4.77792C2.00195 5.60019 1.91283 6.50499 2.08647 7.37791C2.2601 8.25082 2.68869 9.05264 3.31802 9.68198C3.94736 10.3113 4.74918 10.7399 5.6221 10.9135C6.49501 11.0872 7.39981 10.9981 8.22208 10.6575C9.04434 10.3169 9.74715 9.74009 10.2416 9.00007C10.7361 8.26004 11 7.39002 11 6.5"
        stroke="url(#paint0_linear_1742_5662)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1742_5662"
          x1="15"
          y1="5"
          x2="6.25"
          y2="3.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" stopOpacity="0" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
}
