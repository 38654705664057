import { FieldFunctionOptions, FieldPolicy, InMemoryCache, Reference } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";

export default function getApolloInMemoryCache() {
  return new InMemoryCache({
    possibleTypes: {
      NftInterface: ["Nft", "NftPlaylist"],
      UserInterface: ["User", "PrivateUser"],
      TrackInterface: ["Track", "TrackPlaylist"],
      TrackCredit: ["UserCredit", "AnonymousCredit"],
      CreditInterface: ["UserCredit", "AnonymousCredit"],
      PlaylistElement: ["TrackPlaylist", "NftPlaylist"],
      EventWallet: ["CoinbasePTYCharge", "Withdraw", "Reward", "SaleBuy", "PIAMove", "SaleSell"],
      Notification: [
        "PaymentNotification",
        "WalletNotification",
        "LeaderboardNotification",
        "WaitlistNotification",
        "WaitlistPIAMoveNotification",
        "WaitlistReportNotification",
        "WaitlistVoteNotification",
        "ArtistNotification",
        "ArtistWaitlistNotification",
        "LabelNotification",
        "UserNotification",
        "DashboardNotification",
        "BuilderTrackNotification",
        "TrackNotification",
        "ReleaseNotification",
        "NFTNotification",
        "NFTUserNotification",
      ],
      NotificationInterface: [
        "PaymentNotification",
        "WalletNotification",
        "LeaderboardNotification",
        "WaitlistNotification",
        "WaitlistPIAMoveNotification",
        "WaitlistReportNotification",
        "WaitlistVoteNotification",
        "ArtistNotification",
        "ArtistWaitlistNotification",
        "LabelNotification",
        "UserNotification",
        "DashboardNotification",
        "BuilderTrackNotification",
        "TrackNotification",
        "ReleaseNotification",
        "NFTNotification",
        "NFTUserNotification",
      ],
      StatisticInterface: ["ArtistStatistic", "LabelStatistic", "TrackStatistic"],
      SearchItemInterface: ["SearchArtist", "SearchLabel", "SearchTrack", "SearchUser", "SearchPlaylist"],
      FeedItemInterface: [
        "ArtistFollowFeedItem",
        "UserFollowFeedItem",
        "TrackLikeFeedItem",
        "PlaylistLikeFeedItem",
        "PlaylistCreateFeedItem",
        "TrackReleaseFeedItem",
        "TrackSoldoutFeedItem",
        "NftBoughtFeedItem",
        "NftSoldFeedItem",
        "NftListedFeedItem",
      ],
    },
    typePolicies: {
      Query: {
        fields: {
          leaderboardRankings: offsetLimitPagination(["leaderboard"]),
          artists: {
            keyArgs: ["states", "searchQuery", "slug", "id", "ids", "verified", "sort", "limit", "offset"],
          },
          releases: {
            keyArgs: [
              "id",
              "ids",
              "releaseFilter",
              "genres",
              "sort",
              "filter",
              "sizeOnly",
              "withInvisible",
              "buildStateMintState",
            ],
            merge: false,
          },
          marketReleases: {
            keyArgs: [
              "sort",
              "rarity",
              "genreIds",
              "saleType",
              "marketType",
              "primary",
              "minPrice",
              "maxPrice",
              "trackIds",
              "labelIds",
              "artistIds",
              "currency",
            ],
          },
          waitlistTracks: {
            keyArgs: ["id", "slug", "sort", "upcoming", "artistVerified", "genres", "votedFor"],
            merge: false,
          },
          walletEvents: {
            keyArgs: ["filter", "currency"],
          },
          playlists: {
            keyArgs: ["ids", "slug", "searchQuery", "likedByMe", "trackId", "filter", "sort"],
          },
          payments: {
            keyArgs: [
              "bidFilter",
              "bid",
              "releaseFilter",
              "saleFilter",
              "invertSaleFilter",
              "step",
              "currency",
              "featuredLimit",
            ],
          },
          tracks: {
            keyArgs: [
              "id",
              "ids",
              "currency",
              "trackFilter",
              "searchQuery",
              "filter",
              "slug",
              "sizeOnly",
              "withUnreleased",
              "buildStateMintState",
            ],
          },
          users: {
            keyArgs: ["id", "ids", "slug", "searchQuery", "email"],
          },
          notifications: {
            keyArgs: ["read"],
          },
          labels: {
            keyArgs: ["id", "ids", "slug"],
          },
          nfts: {
            keyArgs: ["id", "nftFilter"],
          },
          sales: {
            keyArgs: ["id", "saleFilter"],
          },
          giveaway: {
            keyArgs: ["giveawayCode"],
          },
          labelStatistics: {
            keyArgs: ["sort"],
            merge: false,
          },
          trackStatistics: {
            keyArgs: ["sort"],
            merge: false,
          },
          artistStatistics: {
            keyArgs: ["sort"],
            merge: false,
          },
          searchIndexes: { keyArgs: ["query", "filter"] },
          searchTrackIndex: offsetLimitPagination(["query", "filter"]),
          searchMyTrackIndex: offsetLimitPagination(["query", "filter", "sort"]),
          searchArtistIndex: offsetLimitPagination(["query", "filter"]),
          searchLabelIndex: offsetLimitPagination(["query", "filter"]),
          searchUserIndex: offsetLimitPagination(["query", "filter"]),
          searchPlaylistIndex: offsetLimitPagination(["query", "filter"]),
        },
      },
      WaitlistTrackResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      PlaylistResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      PlaylistElementResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      FeedItemResult: {
        keyFields: false,
        merge: false,
      },
      TrackAudio: {
        keyFields: false,
        merge: true,
      },
      Release: {
        fields: {
          nfts: {
            keyArgs: ["sort", "nftFilter", "filter"],
          },
        },
      },
      TrackResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: offsetLimitPagination(),
        },
      },
      ReleaseResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      Nft: {
        fields: {
          sales: {
            keyArgs: ["saleFilter", "filter"],
          },
        },
      },
      NftResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      SaleResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      BidResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      PrivateUserResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      PrivateUser: {
        fields: {
          createdTracks: {
            keyArgs: ["filter"],
            merge: false,
          },
        },
      },
      UserResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      MintBuilderResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      MintBuilderReleaseResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      LabelStatisticResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: false,
          },
        },
      },
      TrackStatisticResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: false,
          },
        },
      },
      ArtistStatisticResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: false,
          },
        },
      },
      EventWalletResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      PaymentResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
      NotificationResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: offsetLimitPagination(),
        },
      },
      ArtistResult: {
        keyFields: false,
        merge: false,
        fields: {
          results: {
            keyArgs: false,
            merge: mergeWithoutDuplicates,
          },
        },
      },
    },
  });
}

/** Merges existing and incoming apollo data while removing any duplicate objects
 * detected by their same `id` property
 *
 * @param existing - Existing array of items in the cache
 * @param incoming - Incoming array of items from server request
 * @param options - Apollo field function options
 *
 * @returns The concatenation of existing and incoming with all duplicates removed
 * while giving priority to incoming items
 */
function mergeWithoutDuplicates(
  existing: Reference[],
  incoming: Reference[],
  { readField }: FieldFunctionOptions
) {
  const merged = [...(existing ?? []), ...incoming];

  const ids = new Set();
  const deduped: Reference[] = [];

  for (const j in merged) {
    // Iterate in reverse to keep incoming objects
    const i = merged.length - 1 - +j;
    // Use the index when ID is not available
    const id = readField("id", merged[i]) ?? i;
    if (!ids.has(id)) {
      // Keep array in original order by using
      // unshift instead of push
      deduped.unshift(merged[i]);
      ids.add(id);
    }
  }

  return deduped;
}

// A basic field policy that uses options.args.{skip,limit} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.
export function skipLimitPagination<T = Reference>(
  keyArgs: FieldPolicy<unknown>["keyArgs"] = false
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      let merged = existing ? existing.slice(0) : [];

      if (incoming) {
        if (args) {
          // Assume an skip of 0 if args.skip omitted.
          const { skip = 0 } = args;
          if (skip === 0 && incoming.length === 0) {
            // If we're skipping 0 items, and incoming is empty, we know
            // the data is now empty, so we can replace the existing array.
            merged = [];
          } else {
            for (let i = 0; i < incoming.length; ++i) {
              merged[skip + i] = incoming[i];
            }
          }
        } else {
          // It's unusual (probably a mistake) for a paginated field not
          // to receive any arguments, so you might prefer to throw an
          // exception here, instead of recovering by appending incoming
          // onto the existing array.
          merged.push.apply(merged, [...incoming]);
        }
      }

      return merged;
    },
  };
}
