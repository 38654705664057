import { createContext, useCallback, useContext, useState } from "react";

import AuthenticationModal from "@/components/connect/AuthModal/Presenter";
import { AuthenticationDemand } from "@/components/connect/AuthModal/types";
import { DetailedPrivateUserFragment } from "@/graphql/types";
import {
  SS_OAUTH_FOLLOW_ARTIST,
  SS_OAUTH_FOLLOW_USER,
  SS_OAUTH_LIKE_TRACK,
  SS_OAUTH_REDIRECT_URL,
} from "@/utils/constants";

type Context = {
  demandAuthentication(args: AuthenticationDemand): void;
};

const AuthenticationContext = createContext<Context>({
  demandAuthentication: () => undefined,
});

const AuthModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [onSuccess, setOnSuccess] = useState<AuthenticationDemand["onSuccess"]>();
  const [onClose, setOnClose] = useState(() => () => setOnSuccess(undefined));

  const demandAuthentication = useCallback(
    ({
      onSuccess,
      onClose,
      externalAuthRedirect,
      externalAuthArtistFollowId,
      externalAuthUserFollowId,
      externalAuthLikeId,
    }: AuthenticationDemand) => {
      if (onSuccess) {
        setOnSuccess(() => (user: DetailedPrivateUserFragment) => {
          onSuccess(user);
          setOnSuccess(undefined);
        });
      }

      if (onClose) {
        setOnClose(() => () => {
          onClose();
          setOnClose(() => () => setOnSuccess(undefined));
          setOnSuccess(undefined);
        });
      }

      if (externalAuthRedirect)
        sessionStorage.setItem(SS_OAUTH_REDIRECT_URL, encodeURIComponent(externalAuthRedirect));
      if (externalAuthArtistFollowId)
        sessionStorage.setItem(SS_OAUTH_FOLLOW_ARTIST, externalAuthArtistFollowId);
      if (externalAuthUserFollowId) sessionStorage.setItem(SS_OAUTH_FOLLOW_USER, externalAuthUserFollowId);
      if (externalAuthLikeId) sessionStorage.setItem(SS_OAUTH_LIKE_TRACK, externalAuthLikeId);
    },
    [setOnSuccess]
  );

  return (
    <AuthenticationContext.Provider value={{ demandAuthentication }}>
      <AuthenticationModal onSuccess={onSuccess} onClose={onClose} />
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);
export default AuthModalProvider;
