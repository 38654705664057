import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { easeQuartOut } from "@/utils/constants";
import { getAnalytics } from "@/utils/analytics";
import { useAudioPlayer } from "@/components/AudioPlayer/Provider";

type Props = {
  audio?: HTMLAudioElement;
  /**
   * Class name of the container/button
   * @defaultValue "w-9 h-9 hidden lg:flex items-center justify-center relative cursor-pointer
   * rounded-full lg:hover:bg-gradient-hover shrink-0"
   */
  className?: string;
};

export default function VolumeControl({ audio, className }: Props) {
  const [showVolume, setShowVolume] = useState(false);
  const [showHandle, setShowHandle] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);

  const { volume, setVolume, toggleVolume } = useAudioPlayer();

  const volumeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!audio) return;
    audio.volume = volume;
  }, [audio, volume]);

  function changeVolume(e: React.MouseEvent<HTMLDivElement, MouseEvent>, track = false) {
    if (!volumeRef.current) return;

    const width = volumeRef.current.offsetWidth;
    const rect = (e.target as HTMLDivElement).getBoundingClientRect();
    const offset = e.clientX - rect.left; // x position within the element
    let volume = offset / width;
    if (volume > 1) volume = 1;
    else if (volume < 0) volume = 0;
    setVolume(volume);
    if (track) {
      getAnalytics()?.track("Audio Player Music Volume Set", { volume });
    }
  }

  function getVolumeIcon() {
    if (volume === 0) return "/assets/icons/player/volume-muted.svg";
    else if (volume >= 0.6) return "/assets/icons/player/volume.svg";
    return "/assets/icons/player/volume-low.svg";
  }

  return (
    <div
      className={
        className ??
        clsx(
          "w-9 h-9 hidden lg:flex items-center justify-center relative",
          "cursor-pointer rounded-full lg:hover:bg-dark-hover shrink-0"
        )
      }
      onMouseMove={() => setShowVolume(true)}
      onMouseLeave={() => setShowVolume(false)}
    >
      <div className="flex items-center justify-center w-5 h-5">
        <img
          className={"min-w-4 filter brightness-0 invert contrast-101"}
          onClick={toggleVolume}
          alt="volume"
          src={getVolumeIcon()}
        />
      </div>
      <AnimatePresence>
        {showVolume && (
          <motion.div
            initial={{ opacity: 0, y: 10, x: "-50%" }}
            animate={{ opacity: 1, y: 0, x: "-50%" }}
            exit={{ opacity: 0, y: 10, x: "-50%" }}
            transition={{ ease: easeQuartOut }}
            className="absolute -top-3/5 left-1/2 z-10"
          >
            <div
              className={clsx(
                "h-5.5 w-[124px] bg-dark-6 rounded-full shadow-large flex",
                "justify-center items-center"
              )}
              onMouseEnter={() => setShowHandle(true)}
              onMouseLeave={() => setShowHandle(false)}
            >
              <div
                ref={volumeRef}
                className="relative w-[108px] h-[6px] py-2 cursor-pointer z-10"
                onMouseMove={(e) => {
                  if (isSeeking) {
                    changeVolume(e);
                  }
                }}
                onMouseDown={(e) => {
                  setIsSeeking(true);
                  changeVolume(e);
                }}
                onMouseUp={(e) => {
                  changeVolume(e, true);
                  setIsSeeking(false);
                  localStorage.setItem("volume_preference", volume + "");
                }}
                onMouseLeave={() => {
                  setIsSeeking(false);
                }}
              >
                <div
                  className={clsx(
                    "absolute h-[6px] inset-x-0 top-1/2 -translate-y-1/2",
                    "bg-dark-6 rounded-full"
                  )}
                ></div>
                <div
                  className="absolute left-0 top-1/2 -translate-y-1/2 h-[6px] bg-purple rounded-full"
                  style={{ width: volume * 100 + "%" }}
                ></div>
                {showHandle && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: easeQuartOut }}
                    className={clsx(
                      "absolute w-[10px] h-[10px] rounded-full bg-white",
                      "pointer-events-none top-1/2 -translate-y-1/2",
                      "-translate-x-1/2 z-20"
                    )}
                    style={{ left: volume * 100 + "%" }}
                  />
                )}
              </div>
            </div>
            <svg
              width="26"
              height="15"
              viewBox="0 0 26 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-1/2 -translate-x-1/2 -bottom-1.5"
            >
              <path
                d="M15.2958 13.7408C13.8658 14.5251 12.1342 14.5251 10.7042 13.7408L3.35467 9.7097C-0.976554 7.3341 0.710534 0.749996 5.65047 0.749997L20.3495 0.749998C25.2895 0.749998 26.9765 7.3341 22.6453 9.7097L15.2958 13.7408Z"
                className="fill-dark-6"
              />
            </svg>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
