import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren } from "react";
import clsx from "clsx";

import { easeQuartOut } from "@/utils/constants";
import ModalPortal from "@/components/ModalPortal";
import { useSetModalStyles } from "@/utils/hooks/useSetModalStyles";

interface Props {
  /** Toggle to make modal appear/disappear */
  show: boolean;
  /** Callback when user cancels or closes modal */
  close: () => void;
}

export type BottomSheetProps = Omit<Props, "show" | "close">;

/**
 * Animated bottom sheet modal (designed for mobile)
 * with a scrollable list for children.
 */
export default function BottomMenu({ show, close, children }: PropsWithChildren<Props>) {
  useSetModalStyles(show);

  return (
    <ModalPortal>
      <AnimatePresence>
        {show && (
          <motion.div
            className={clsx("fixed inset-0 h-full w-full flex flex-col justify-end p-3 sm:p-6 z-[99999999]")}
            onClick={(e) => {
              e.stopPropagation();
              close();
            }}
          >
            <motion.div
              initial={{ opacity: 0, backdropFilter: "opacity(0) blur(20px)" }}
              animate={{ opacity: 1, backdropFilter: "opacity(1) blur(20px)" }}
              exit={{ opacity: 0, backdropFilter: "opacity(0) blur(20px)" }}
              transition={{ ease: easeQuartOut, duration: 0.3 }}
              className={clsx(
                "absolute inset-0 bg-grey-dark/[0.01]",
                "before:absolute before:inset-0 before:-z-10 before:rounded-md before:backdrop-blur-[20px]"
              )}
            />
            <motion.div
              initial={{ y: "80%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "80%", opacity: 0 }}
              transition={{ ease: easeQuartOut, duration: 0.3 }}
              className={clsx(
                "w-full flex flex-col bg-dark-5/60 bg-dark-glass shadow-larger rounded-[20px]",
                "z-10 overflow-hidden relative",
                "before:border-2 before:bg-dark-stroke",
                "before:border-gradient before:rounded-[20px]"
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="z-10">{children}</div>
              <motion.div
                initial={{ opacity: 0, backdropFilter: "opacity(0) blur(40px)" }}
                animate={{ opacity: 1, backdropFilter: "opacity(1) blur(40px)" }}
                exit={{ opacity: 0, backdropFilter: "opacity(0) blur(40px)" }}
                transition={{ ease: easeQuartOut, duration: 0.3 }}
                className={clsx(
                  "absolute inset-0 -z-10 before:absolute before:inset-0 before:-z-10",
                  "before:rounded-[20px] before:backdrop-blur-[20px]"
                )}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
}
