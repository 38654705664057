import { i18n } from "next-i18next";

import { toast } from "@/utils/toasts";

export function copyToClipboard(txt: string) {
  navigator.clipboard.writeText(txt).then(
    () => {
      toast.success(i18n?.t("common:common.info.clipboard.copy") ?? "Copied to clipboard");
    },
    () => {
      toast.error(i18n?.t("common:common.info.clipboard.nocopy") ?? "Failed to copy");
    }
  );
}
