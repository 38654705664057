import { SVGProps } from "react";

export default function CheckmarkIcon(props: SVGProps<SVGSVGElement>) {
  const { width = "14", height = "14", ...rest } = props;
  return (
    <svg {...{ width, height }} {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2559 5.24408C17.5814 5.56951 17.5814 6.09715 17.2559 6.42259L8.92259 14.7559C8.59715 15.0814 8.06951 15.0814 7.74408 14.7559L3.57741 10.5893C3.25197 10.2638 3.25197 9.73618 3.57741 9.41074C3.90285 9.08531 4.43048 9.08531 4.75592 9.41074L8.33333 12.9882L16.0774 5.24408C16.4028 4.91864 16.9305 4.91864 17.2559 5.24408Z"
        className="fill-current"
      />
    </svg>
  );
}
