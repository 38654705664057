import { SVGProps } from "react";

export default function NextButton(props: SVGProps<SVGSVGElement>) {
  const { width = "13", height = "16", ...rest } = props;
  return (
    <svg {...{ width, height }} {...rest} viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.866086 2.88388C0.377931 2.39573 0.377931 1.60427 0.866086 1.11612C1.35424 0.627961 2.1457 0.627961 2.63385 1.11612L7.92675 6.40901C8.80543 7.28769 8.80543 8.71231 7.92675 9.59099L2.63385 14.8839C2.1457 15.372 1.35424 15.372 0.866086 14.8839C0.377931 14.3957 0.377931 13.6043 0.866086 13.1161L5.9822 8L0.866086 2.88388ZM11.25 1C10.6977 1 10.25 1.44772 10.25 2V14C10.25 14.5523 10.6977 15 11.25 15H11.75C12.3023 15 12.75 14.5523 12.75 14V2C12.75 1.44772 12.3023 1 11.75 1H11.25Z"
        className="fill-current"
      />
    </svg>
  );
}
