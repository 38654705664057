import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useContext } from "react";
import Head from "next/head";
import clsx from "clsx";

import { useWindowSize } from "@/components/WindowSizeProvider";
import { MobileSSRContext } from "@/utils/reactContexts";

interface MobileDisabledProps {
  children: React.ReactNode;
}

function MobileDisabled({ children }: MobileDisabledProps) {
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  const { t } = useTranslation("common");

  const { isMobileSSR } = useContext(MobileSSRContext);
  const router = useRouter();

  if (
    (isMobile || isMobileSSR) &&
    (router.asPath.includes("/admin/") ||
      router.asPath === "/admin" ||
      router.asPath.includes("/dashboard/") ||
      router.asPath.includes("/labels/"))
  ) {
    return (
      <div
        className={clsx(
          "w-screen h-screen bg-dark-bg-mobile text-white flex",
          "flex-col justify-center items-center"
        )}
      >
        <Head>
          <title>Dashboard - Pianity</title>
        </Head>
        <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.875 6C0.875 3.16954 3.16954 0.875 6 0.875H44C46.8305 0.875 49.125 3.16954 49.125 6V28C49.125 30.8305 46.8305 33.125 44 33.125H28V38H31C31.5523 38 32 38.4477 32 39C32 39.5523 31.5523 40 31 40H28H22H19C18.4477 40 18 39.5523 18 39C18 38.4477 18.4477 38 19 38H22V33.125H6C3.16954 33.125 0.875 30.8305 0.875 28V6ZM6 3.125C4.41218 3.125 3.125 4.41218 3.125 6V28C3.125 29.5878 4.41218 30.875 6 30.875H44C45.5878 30.875 46.875 29.5878 46.875 28V6C46.875 4.41218 45.5878 3.125 44 3.125H6Z"
            className="fill-current"
          />
        </svg>

        <div className="text-22 text-center mt-8 max-w-3/4">
          {t("common.utils.warnings.desktop-only_dashboard")}
        </div>
      </div>
    );
  }

  return <>{children}</>;
}

export default MobileDisabled;
