import { ToastContainer } from "react-toastify";
import React, { useState } from "react";
import dynamic from "next/dynamic";

import { ChatContext } from "@/utils/reactContexts";
import PhoneVerificationProvider from "@/components/phone-verification/Provider";
import PrivateBountyClaim from "@/components/modals/bounty/PrivateBountyClaim";
import ChatModal from "@/components/chat/ChatModal";
import { WalletProvider } from "@/components/Wallet";
import AuthModalProvider from "@/components/connect/AuthModal";
import PublicBountyClaimProvider from "@/components/modals/bounty/PublicBountyClaim";
import MobileDisabled from "@/components/hoc/MobileDisabled";
import { PROP_KEY_DISABLE_NAV } from "@/utils/constants";

const Footer = dynamic(() => import("@/components/Footer"));
const Navbar = dynamic(() => import("@/components/navbar"));

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const [showChat, setShowChat] = useState<boolean>(false);

  // Check PROP_KEY_DISABLE_NAV from children
  // and disable the navbar if it's true
  const disableNavbar = React.Children.toArray(children).some(
    (child) => React.isValidElement(child) && child.props[PROP_KEY_DISABLE_NAV]
  );

  return (
    <PhoneVerificationProvider>
      <AuthModalProvider>
        <PublicBountyClaimProvider>
          <WalletProvider>
            <ChatContext.Provider value={{ setShowChat }}>
              <div className="flex flex-col w-screen">
                <ToastContainer
                  containerId="notifications"
                  enableMultiContainer
                  hideProgressBar
                  pauseOnHover
                  closeOnClick={false}
                  position="top-right"
                  autoClose={4000}
                  role="notification"
                />
                <ToastContainer
                  pauseOnHover
                  enableMultiContainer
                  hideProgressBar
                  closeOnClick={false}
                  position="bottom-center"
                  draggableDirection="y"
                  newestOnTop
                  autoClose={6000}
                />

                {!disableNavbar && <Navbar />}

                <ChatModal show={!!showChat} closeModal={() => setShowChat(false)} />
                <PrivateBountyClaim />

                <div className="flex flex-col min-h-screen">
                  <MobileDisabled>{children}</MobileDisabled>
                </div>

                {!disableNavbar && <Footer />}
              </div>
            </ChatContext.Provider>
          </WalletProvider>
        </PublicBountyClaimProvider>
      </AuthModalProvider>
    </PhoneVerificationProvider>
  );
}
