import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren, useEffect, useRef } from "react";
import { easeQuartOut } from "@/utils/constants";
import OutsideAlerter from "@/components/hoc/OutsideAlerter";
import BottomMenu from "@/components/modals/BottomMenu";
import { exhaustiveSwitch } from "@/utils/exhaustiveSwitch";

interface Props {
  show: boolean;
  mobile: boolean;
  direction?: "up" | "right" | "down" | "left";
  tipColor?: string;
  onClose: () => void;
}

export default function ToolTip({
  show,
  mobile,
  tipColor = "#767BFF",
  direction = "up",
  onClose,
  children,
}: PropsWithChildren<Props>) {
  const desktopContainerRef = useRef<HTMLDivElement | null>(null);

  // Re-position the tooltip container if it is
  // outside of the viewport.
  useEffect(() => {
    // Ignore when tooltip is horizontal
    if (direction !== "up" && direction !== "down") return;

    if (desktopContainerRef.current && desktopContainerRef.current.parentElement && show) {
      const screenPadding = 24;

      const container = desktopContainerRef.current;
      const parentRect = desktopContainerRef.current.parentElement.getBoundingClientRect();
      const dropdownRect = container.getBoundingClientRect();

      if (dropdownRect.x < 0) {
        // Out on the left
        container.style.setProperty(
          "transform",
          `translateX(calc(-50% + ${parentRect.x + screenPadding}px))`,
          "important"
        );
      } else if (dropdownRect.right > window.innerWidth) {
        // Out on the right
        container.style.setProperty(
          "transform",
          `translateX(calc(-50% - ${dropdownRect.right - window.innerWidth + screenPadding}px))`,
          "important"
        );
      }
    }
  }, [show, direction]);

  if (mobile) {
    return (
      <BottomMenu show={show} close={onClose}>
        {children}
      </BottomMenu>
    );
  }

  return (
    <OutsideAlerter callback={onClose}>
      <div
        className={(() => {
          switch (direction) {
            case "up":
              return "absolute bottom-[calc(100%+8px)] left-1/2 -translate-x-1/2 z-20 cursor-auto";
            case "right":
              return "absolute top-1/2 left-full -translate-y-1/2 z-20 cursor-auto";
            case "down":
              return "absolute top-[calc(100%+8px)] left-1/2 -translate-x-1/2 z-20 cursor-auto";
            case "left":
              return "absolute top-1/2 right-full -translate-y-1/2 z-20 cursor-auto";
            default:
              return exhaustiveSwitch(direction);
          }
        })()}
        ref={desktopContainerRef}
      >
        <AnimatePresence>
          {show && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={getTooltipVariants(direction)}
              transition={{ ease: easeQuartOut }}
            >
              <div className="shadow-tooltip">{children}</div>

              {["right", "left"].includes(direction) && (
                <svg
                  width="26"
                  height="15"
                  viewBox="0 0 26 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  {...getArrowTipProps(direction)}
                >
                  <path
                    d="M 15.29580020904541 13.740799903869629 C 13.865799903869629 14.525099754333496 12.134200096130371 14.525099754333496 10.70419979095459 13.740799903869629 L 3.3546700477600098 9.709699630737305 C 13.529552459716797 9.8005952835083 11.744794845581055 9.715455055236816 22.645299911499023 9.709699630737305 L 15.29580020904541 13.740799903869629 Z "
                    fill={tipColor}
                    transform="matrix(1 0 0 1 0 0)"
                  />
                </svg>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {show && ["up", "down"].includes(direction) && (
        <motion.svg
          width="26"
          height="15"
          viewBox="0 0 26 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...getArrowTipProps(direction)}
          transition={{ ease: easeQuartOut, delay: 0.1 }}
        >
          <path
            d="M 15.29580020904541 13.740799903869629 C 13.865799903869629 14.525099754333496 12.134200096130371 14.525099754333496 10.70419979095459 13.740799903869629 L 3.3546700477600098 9.709699630737305 C 13.529552459716797 9.8005952835083 11.744794845581055 9.715455055236816 22.645299911499023 9.709699630737305 L 15.29580020904541 13.740799903869629 Z "
            fill={tipColor}
            transform="matrix(1 0 0 1 0 0)"
          />
        </motion.svg>
      )}
    </OutsideAlerter>
  );
}

function getTooltipVariants(direction: "up" | "right" | "down" | "left") {
  switch (direction) {
    case "up":
      return {
        hidden: {
          opacity: 0,
          y: 12,
        },
        visible: {
          opacity: 1,
          y: 0,
        },
      };
    case "right":
      return {
        hidden: {
          opacity: 0,
          x: 12,
        },
        visible: {
          opacity: 1,
          x: 0,
        },
      };
    case "down":
      return {
        hidden: {
          opacity: 0,
          y: -12,
        },
        visible: {
          opacity: 1,
          y: 0,
        },
      };
    case "left":
      return {
        hidden: {
          opacity: 0,
          x: -12,
        },
        visible: {
          opacity: 1,
          x: 0,
        },
      };
    default:
      return exhaustiveSwitch(direction);
  }
}

function getArrowTipProps(direction: "up" | "right" | "down" | "left") {
  switch (direction) {
    case "up":
      return {
        initial: {
          opacity: 0,
          y: -12,
          x: "-50%",
        },
        animate: {
          opacity: 1,
          y: 0,
          x: "-50%",
        },
        exit: {
          opacity: 0,
          y: -12,
          x: "-50%",
        },
        className: "absolute left-1/2 bottom-[calc(100%+3px)] z-20",
      };
    case "right":
      return {
        className: "absolute top-1/2 -translate-y-1/2 -left-[10px] z-20 rotate-90",
      };
    case "down":
      return {
        initial: {
          opacity: 0,
          y: 12,
          x: "-50%",
        },
        animate: {
          opacity: 1,
          y: 0,
          x: "-50%",
        },
        exit: {
          opacity: 0,
          y: 12,
          x: "-50%",
        },
        className: "absolute left-1/2 -translate-x-1/2 top-[calc(100%-6px)] z-20 rotate-180",
      };
    case "left":
      return {
        className: "absolute top-1/2 -translate-y-1/2 -right-[10px] z-20 -rotate-90",
      };
    default:
      return exhaustiveSwitch(direction);
  }
}
