import clsx from "clsx";
import { i18n } from "next-i18next";
import { useEffect } from "react";
import { CountdownRenderProps } from "react-countdown";

import LoadingIndicator from "@/components/ui/svg/LoadingIndicator";

type timeUnits = Pick<CountdownRenderProps, "days" | "hours" | "minutes" | "seconds" | "milliseconds">;

interface RendererOptions {
  /** Wether to display days in long or short format */
  days?: "short" | "long";
  /** Wether to display hours in long or short format */
  hours?: "short" | "long";
  /** Wether to display minutes in long or short format */
  minutes?: "short" | "long";
  /** Wether to display seconds in long or short format */
  seconds?: "short" | "long";
  /**
   * Set to true to always display seconds. Otherwise
   * only displayed when 0 days left.
   */
  showSeconds?: boolean;
  /** Set to true to not display units which are 0 */
  removeZeroes?: boolean;
  /** Function to determine when to show a rounded outline */
  outlined?: (units: timeUnits) => boolean;
  /** Prefix to the countdown */
  prefix?: string;
  /** Suffix to the countdown */
  suffix?: string;
  /**
   * HTML title tag given to the countdown element
   * @defaultValue "Countdown ends Saturday, 01 April 2022, 16:00" localized
   */
  htmlTitle?: string;
  /** Sets default text color. Is overwritten by displayOrange/Red methods */
  textColour?: string;
  /** Function to determine when to make countdown orange */
  displayOrange?: (units: timeUnits) => boolean;
  /**
   * Function to determine when to make countdown red
   *
   * @remarks This function will always take precedence over
   * `displayOrange`.
   */
  displayRed?: (units: timeUnits) => boolean;
  /**
   * Function called every time the countdown is updated
   */
  onTimeUpdate?: (units: timeUnits) => void;
}

/**
 * Creates the render function used by React Countdown with
 * a default format of 3d 2h42m when over 1 day away and
 * 2h42m55s otherwise.
 *
 * @remarks
 * Makes the countdown orange when less than 1 hour away.
 * Makes the countdown red when less than 10 minutes away.
 * Seconds countdown font-type is Mono to avoid text movement.
 *
 * @returns A Render function for ReactCountdown
 */
export default function getCountdownRenderer(completed?: JSX.Element, options: RendererOptions = {}) {
  const {
    days: dF = "long",
    hours: hF = "short",
    minutes: mF = "short",
    seconds: sF = "short",
    showSeconds,
    removeZeroes,
    prefix = "",
    suffix = "",
    outlined,
    htmlTitle,
    textColour: _textColour,
    displayOrange = ({ days, hours, minutes, seconds }) =>
      days === 0 && hours === 0 && (minutes > 0 || seconds > 0),
    displayRed = ({ days, hours, minutes }) => days === 0 && hours === 0 && minutes < 10,
    onTimeUpdate,
  } = options;

  return function CountdownRenderer({
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
    completed: isCompleted,
    props: { date },
  }: CountdownRenderProps) {
    function padZero(time: number) {
      return ("" + time).padStart(2, "0");
    }
    const units = { days, hours, minutes, seconds, milliseconds };
    onTimeUpdate?.(units);

    if (!date) return null;

    if (isCompleted && completed) {
      // Render a completed state
      return completed;
    } else if (Number.isNaN(seconds)) {
      return <span>{i18n?.t("common.sales.unknown")}</span>;
    } else {
      let textColour = _textColour ?? "";
      if (displayOrange(units)) textColour = "text-orange";
      if (displayRed(units)) textColour = "text-red-3";

      // Render a countdown
      return (
        <span
          className={clsx(
            textColour,
            "cursor-default w-max",
            outlined?.(units) &&
              ` py-[3px] lg:py-[5px] px-2 border border-${textColour.replace("text-", "")} rounded-full`
          )}
          title={
            htmlTitle ??
            (i18n?.t("common.sales.end_tooltip") ?? "") +
              " " +
              new Date(date).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                weekday: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
          }
        >
          {prefix ? prefix + " " : ""}
          <span className="lowercase">
            {days > 0
              ? days +
                (dF === "long" ? " " : "") +
                (i18n?.t(`common.utils.time.${dF}.day`, { count: days }) ?? "") +
                " "
              : ""}
            {hours > 0 || (!removeZeroes && days > 0)
              ? hours +
                (hF === "long" ? " " : "") +
                (i18n?.t(`common.utils.time.${hF}.hour`, { count: hours }) ?? "")
              : ""}
            {minutes > 0 || (!removeZeroes && (hours > 0 || days > 0))
              ? padZero(minutes) +
                (mF === "long" ? " " : "") +
                (i18n?.t(`common.utils.time.${mF}.minute`, { count: minutes }) ?? "")
              : ""}
            <span className="font-mono">
              {showSeconds || days === 0
                ? padZero(seconds) +
                  (sF === "long" ? " " : "") +
                  (i18n?.t(`common.utils.time.${sF}.second`, { count: seconds }) ?? "")
                : ""}
            </span>
          </span>
          {suffix ? suffix + " " : ""}
        </span>
      );
    }
  };
}

export function CountdownRefresher({
  className,
  width = "9",
  height = "9",
  onCall,
}: {
  className?: string;
  width?: string;
  height?: string;
  onCall: () => void;
}) {
  useEffect(() => {
    onCall();
  }, [onCall]);

  return (
    <span className={className}>
      <LoadingIndicator width={width} height={height} />
    </span>
  );
}
