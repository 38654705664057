import { useTranslation } from "next-i18next";
import clsx from "clsx";
import Image from "next/image";

import ScarcityBadge from "@/components/releases/cards/MarketCard/ScarcityBadge";
import NftBadge from "@/components/releases/cards/NftCard/NftBadge";
import TrackThumbnail from "@/components/tracks/TrackThumbnail";
import { BasicTrackFragment, Rarity } from "@/graphql/types";
import ArtistNames from "@/components/reusable/ArtistNames";
import ExclusiveIcon from "@/components/ui/svg/ExclusiveIcon";
import { AudioPlayerTrack } from "@/components/AudioPlayer";
import { useWindowSize } from "@/components/WindowSizeProvider";
import Link from "@/components/hoc/CustomLink";
import Badge from "@/components/ui/Badge";
import BountyIcon from "@/components/ui/svg/BountyIcon";

interface Props {
  track: BasicTrackFragment;
  scarcities: Rarity[];
  /** Displays NFT number + scarcity[0] */
  nftNumber?: number;
  tag?: JSX.Element;
  isNew?: boolean;
  hasExclusiveContent?: boolean;
  isBounty?: boolean;
  hideArtistNames?: boolean;
  /**
   * Playlist to play when clicking play
   * Just plays the track if left undefined
   */
  playlist?: Omit<AudioPlayerTrack, "streamSource">[];
  /**
   * Index of the track in the playlist.
   */
  playlistIndex?: number;
  /**
   * Where the card is being used.
   * Sent to analytics as the stream's source.
   */
  streamSource: string;
  /** Forces desktop layout (full card) */
  fullCard?: boolean;
  onShowPerks?: () => void;
}

export default function CardInfo({
  track,
  scarcities,
  nftNumber,
  tag,
  isNew,
  hasExclusiveContent,
  isBounty,
  hideArtistNames,
  playlist,
  playlistIndex,
  streamSource,
  fullCard,
  onShowPerks,
}: Props) {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const firstArtist = track.artists[0];
  return (
    <div
      className={clsx(
        "flex items-center relative z-10",
        fullCard ? "flex-col items-stretch" : "lg:flex-col lg:items-stretch mb-0.5 lg:mb-0"
      )}
    >
      {/* MARK: Header */}
      <div className={clsx(fullCard ? "p-2 w-full" : "p-1.5 lg:p-2 w-1/3 lg:w-full", "shrink-0 self-start")}>
        <div
          className={clsx(
            "w-full square-ratio rounded-md relative",
            "shadow-[inset_0px_4px_4px_rgba(0,0,0,0.15)]",
            "drop-shadow-image backdrop-blur-0 overflow-hidden"
          )}
        >
          {/* MARK: Song Artwork */}
          <TrackThumbnail
            track={track}
            playlist={playlist}
            playlistIndex={playlistIndex}
            streamSource={streamSource}
            nextSizes="384px"
          />

          {/* MARK: Release Scarcity */}
          {!nftNumber && <ScarcityBadge scarcities={scarcities} />}
          {/* MARK: Nft Number */}
          {nftNumber && (!isMobile || fullCard) && scarcities[0] && (
            <div className="absolute left-2 top-2 z-20">
              <NftBadge number={nftNumber} scarcity={scarcities[0]} />
            </div>
          )}

          {(!isMobile || fullCard) && (
            <div
              className={clsx(
                "absolute bottom-2 flex flex-wrap gap-1 z-20",
                fullCard && isMobile ? "left-[calc(20%+16px)]" : "left-2"
              )}
            >
              {isNew && <NewBadge />}
              {isBounty && <BountyBadge />}
              {hasExclusiveContent && <ExclusiveContentBadge onClick={() => onShowPerks?.()} />}
              {tag}
            </div>
          )}
          {!isMobile && (isNew || isBounty || hasExclusiveContent || !!tag) && (
            <div className="absolute inset-x-0 bottom-0 w-full h-1/3 bg-gradient-to-t from-black" />
          )}
        </div>
      </div>

      {/* MARK: Body */}
      <div
        className={clsx(
          "grow flex flex-col mx-3",
          nftNumber && (!isMobile || fullCard) && scarcities[0] ? "pt-5" : "pt-3",
          "lg:mx-0 px-2 lg:px-6 lg:pb-8 min-w-0"
        )}
      >
        {/* MARK: Song Artists */}
        {!hideArtistNames && firstArtist && (
          <div className="flex items-center pb-1.5 lg:cursor-pointer">
            {firstArtist.avatarUrl && (
              <div className="mr-2 h-6 w-6 lg:h-7 lg:w-7 shrink-0">
                <Image
                  src={firstArtist.avatarUrl}
                  alt={firstArtist.name}
                  width={isMobile ? 24 : 28}
                  height={isMobile ? 24 : 28}
                  className="rounded-full"
                />
              </div>
            )}
            <ArtistNames
              artists={track.artists}
              withLinks
              allowNextLink
              artistClassName={clsx(
                "text-13 leading-1.25 lg:text-15 lg:leading-1.25",
                "font-normal min-w-0 flex hover:underline"
              )}
            />
          </div>
        )}

        {/* MARK: Song Title */}
        <Link href={`/${firstArtist?.slug}/${track.slug}`}>
          <a>
            <h2
              className={clsx(
                "lg:cursor-pointer tracking-normal wrap-word",
                track.title.length > 35
                  ? [
                      "text-17 leading-1.1 lg:text-22 lg:leading-0.95 xl:text-20",
                      "xl:leading-0.95 2xl:text-22 2xl:leading-0.95",
                    ]
                  : [
                      "text-lg leading-1.1 lg:text-2xl lg:leading-0.95 xl:text-22",
                      "xl:leading-0.95 2xl:text-2xl 2xl:leading-0.95",
                    ],
                "font-medium hover:underline [text-decoration-thickness:from-font]"
              )}
            >
              {track.title}
            </h2>
          </a>
        </Link>

        {isMobile && !fullCard && (
          <div className="min-w-0 mt-3 mb-2.5 flex flex-wrap gap-1">
            {isNew && <NewBadge />}
            {isBounty && <BountyBadge />}
            {hasExclusiveContent && <ExclusiveContentBadge onClick={() => onShowPerks?.()} />}
            {tag}
          </div>
        )}
      </div>
    </div>
  );
}

function ExclusiveContentBadge({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation("common");
  return (
    <button onClick={() => onClick()}>
      <Badge variants={{ color: "perks", outlined: true, blur: true }}>
        <div className="shrink-0">
          <ExclusiveIcon width={12} height={8.72} />
        </div>
        <span className="ml-1">{t("common.sales.card.perks")}</span>
      </Badge>
    </button>
  );
}

function BountyBadge() {
  const { t } = useTranslation("common");
  return (
    <Badge variants={{ color: "green-dark", outlined: true, blur: true }}>
      <div className="shrink-0">
        <BountyIcon width={12} height={8.72} />
      </div>
      <span className="ml-1">{t("common.utils.things.bounty")}</span>
    </Badge>
  );
}

function NewBadge() {
  const { t } = useTranslation("common");
  return (
    <Badge variants={{ color: "purple-shiny", outlined: true, blur: true }}>
      <span className={"bg-dark-gradient bg-clip-text text-transparent"}>{t("common.sales.new")}</span>
    </Badge>
  );
}
