import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";

import { useWindowSize } from "@/components/WindowSizeProvider";
import ArtistNames from "@/components/reusable/ArtistNames";
import ConditionalLink from "@/components/hoc/ConditionalLink";
import { easeQuartOut } from "@/utils/constants";
import { AudioPlayerTrack } from "@/components/AudioPlayer";
import { getArtistNames } from "@/utils/getArtistNames";

interface Props {
  track: AudioPlayerTrack;
  minimized?: boolean;
}

/**
 * Renders the track cover with artist names and title
 * to be displayed on the music player.
 *
 * Title is marqueed to fit the space only on desktop.
 */
export default function TrackInfo({ track, minimized }: Props) {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const containerRef = useRef<HTMLDivElement | null>(null);
  // Makes the track title scroll when it overflows
  const [useMarquee, setUseMarquee] = useState(false);

  // Only set the marquee when the title element
  // is larger than its parent element
  useEffect(() => {
    if (
      (!isMobile || minimized) &&
      containerRef.current &&
      containerRef.current.scrollWidth > containerRef.current.offsetWidth
    ) {
      setUseMarquee(true);
    } else {
      setUseMarquee(false);
    }
  }, [track, isMobile, minimized]);

  let thumbnailSize = 48;
  if (isMobile && minimized) thumbnailSize = 40;
  else if (isMobile) thumbnailSize = 64;
  return (
    <motion.div
      layout={isMobile}
      transition={{ ease: easeQuartOut }}
      key={`reset-${isMobile}${minimized}`}
      className="grow flex items-center min-w-0"
    >
      <ConditionalLink
        href={
          !minimized && track.slug && (track.hasWaitlistSlug || track.artists[0])
            ? `/${track.hasWaitlistSlug ? "toplist" : track.artists[0]?.slug}/${track.slug}`
            : undefined
        }
        anchorClassName="shrink-0"
        className={clsx("shrink-0 relative lg:h-12 lg:w-12 z-10 mr-3", minimized ? "h-10 w-10" : "h-16 w-16")}
      >
        <motion.img
          layout
          transition={{ ease: easeQuartOut }}
          src={track.thumbnail?.minifiedImageUrl ?? "/assets/misc/no-image.svg"}
          alt={track.title}
          width={thumbnailSize}
          height={thumbnailSize}
          className="rounded shrink-0"
        />
      </ConditionalLink>
      {(isMobile || !minimized) && (
        <motion.div
          layout={isMobile}
          key={`reset-${isMobile}${minimized}`}
          className={"flex flex-col grow min-w-0 mr-2" + (minimized ? " lg:hidden" : "")}
        >
          {!minimized && (
            <ArtistNames
              artists={track.artists}
              artistClassName={clsx(
                "block font-normal text-sm leading-1.2 lg:text-13",
                "lg:leading-1.25 truncate lg:hover:text-purple"
              )}
              withLinks
              allowNextLink
              verifiedIconProps={{
                width: "12",
                height: "11.92",
                className: "flex-shrink-0 ml-1 inline",
              }}
            />
          )}
          <ConditionalLink
            href={
              !minimized && track.slug && (track.hasWaitlistSlug || track.artists[0])
                ? `/${track.hasWaitlistSlug ? "toplist" : track.artists[0]?.slug}/${track.slug}`
                : undefined
            }
            className="overflow-hidden lg:hover:text-purple relative"
          >
            <div
              ref={containerRef}
              className={clsx(
                "mt-0.5 text-15 leading-1.2 lg:text-sm lg:leading-1.2",
                "font-medium",
                !useMarquee &&
                  (minimized
                    ? "overflow-x-auto whitespace-nowrap"
                    : "lg:overflow-x-auto lg:whitespace-nowrap")
              )}
            >
              {/* TODO: Make marquee animation length depend on text length to keep speed consistent */}
              <div
                key={track.title}
                className={clsx(
                  useMarquee &&
                    "inline-block line-clamp-none overflow-visible whitespace-nowrap animate-marquee-left",
                  !useMarquee && !minimized && isMobile && "line-clamp-2 lg:line-clamp-none"
                )}
              >
                <span className={useMarquee ? "mr-4" : undefined}>
                  {(minimized ? getArtistNames(track.artists) + " - " : "") + track.title}
                </span>
                {useMarquee && (
                  <span>{(minimized ? getArtistNames(track.artists) + " - " : "") + track.title}</span>
                )}
              </div>
            </div>
            {useMarquee && <div className={"absolute inset-y-0 right-0 bg-gradient-to-l from-dark-4 w-5"} />}
          </ConditionalLink>
        </motion.div>
      )}
    </motion.div>
  );
}
