import { ApolloError, MutationHookOptions, TypedDocumentNode, useMutation } from "@apollo/client";
import { i18n } from "next-i18next";

import { DocumentNode, GraphQLError } from "graphql";
import NoSlotsLeftToast from "@/components/notifications/toasts/NoSlotsLeft";
import { LS_REFERRAL_CODE, SS_CLAIM_CODE } from "@/pages/_app";
import { getAnalytics, getPosthog } from "@/utils/analytics";
import { toast } from "@/utils/toasts";
import { logoutUser } from "@/utils/oAuth/tokens";
import { ResolverErrors } from "@/resolverError";
import { initializeApollo } from "@/apollo";

/**
 * Re-exporting all GraphQL Mutation Hooks with a custom error handler
 */

import {
  AcceptLabelInvitationDocument,
  AddBankAccountDocument,
  ChangePasswordDocument,
  ChunkThumbnailChunkUploadDocument,
  ChunkTrackChunkUploadDocument,
  ClaimNftGiveawayDocument,
  CreateWalletApproveTransactionDocument,
  CreateAutoCheckoutDocument,
  CreateBankTransferWithdrawRequestDocument,
  CreateCheckoutAuctionDocument,
  CreateCheckoutInstantSaleDocument,
  CreateCoinbaseChargeDocument,
  CreateSaleDocument,
  DeclineLabelInvitationDocument,
  DetachPaymentMethodDocument,
  EndThumbnailChunkUploadDocument,
  EndTrackChunkUploadDocument,
  InviteArtistToLabelDocument,
  LinkToDiscordDocument,
  MarkNotificationsAsReadDocument,
  ModifyReleaseVisibilityDocument,
  PayWithWalletDocument,
  RemoveArtistInvitationDocument,
  RemoveBankAccountDocument,
  ReportErrorDocument,
  ReportTrackDocument,
  ResendVerifyEmailDocument,
  SendResetPasswordEmailDocument,
  ValidateTransactionDocument,
  StartMintDocument,
  StartThumbnailChunkUploadDocument,
  StartTrackChunkUploadDocument,
  StopSaleDocument,
  UnlinkFromDiscordDocument,
  UpdateArtistDocument,
  UpdateMeDocument,
  UpdateMyArtistDocument,
  UploadMyAvatarDocument,
  VerifyEmailDocument,
  ToggleTrackLikeDocument,
  CreatePlaylistDocument,
  UpdatePlaylistDocument,
  DeletePlaylistDocument,
  TogglePlaylistLikeDocument,
  AcceptCollabInvitationDocument,
  DeclineCollabInvitationDocument,
  Setup2FaDocument,
  Enable2FaDocument,
  Disable2FaDocument,
  Regennerate2FaRecoveryCodesDocument,
  AppendToPlaylistDocument,
  MarkAllNotificationsAsReadDocument,
  CreateTrackDocument,
  UpdateTrackDocument,
  DeleteTrackDocument,
} from "@/graphql/types";

/**
 * Builds a mutation hook with a custom error handler
 *
 * @param doc - Mutation Document
 * @returns mutation hook with custom error handler
 */
const catchMutationError =
  <T, U>(doc: DocumentNode | TypedDocumentNode<T, U>) =>
  (params?: MutationHookOptions<T, U>) =>
    useMutation(doc, {
      ...params,
      onError: (error: ApolloError) => {
        onApolloError(error);
        if (params) params.onError?.(error);
      },
    });

export const onApolloError = ({ graphQLErrors, networkError }: ApolloError, analyticsOnly = false) => {
  graphQLErrors?.forEach((unsafeError) => {
    const error: ResolverErrors = unsafeError.extensions?.exception?.kind;
    const valErrors = unsafeError.extensions?.exception?.validationErrors?.[0];

    getAnalytics()?.track("Received Error", { error });

    if (!analyticsOnly) {
      switch (error) {
        case "InvalidAuthToken":
          logoutUser();
          break;
        case "InvalidEmailToken":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.invalid-email"));
          break;
        case "InvalidUsername":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.invalid-username"));
          break;
        case "WrongPassword":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.wrong-password"));
          break;
        case "Invalid2FAToken":
          toast.error(i18n?.t("notifications:notifications.messages.account.2fa.invalid"));
          break;
        case "Unregistered":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.unregistered"));
          break;
        case "RegisteredWithArConnect":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.arconnect-registered"));
          break;
        case "EmailAlreadyUsed":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.email-already-used"));
          break;
        case "UsernameAlreadyUsed":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.username-already-used"));
          break;
        case "PublicKeyAlreadyUsed":
          toast.error(
            i18n?.t("notifications:notifications.messages.errors.mutations.public-key-already-used")
          );
          break;
        case "DiscordAlreadyUsed":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.discord-already-used"));
          break;
        case "BidTooLow":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.bid-too-low"));
          break;
        case "UnactiveSale":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.unactive-sale"));
          break;
        case "NotEnoughPTY":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.not-enough-pty"));
          break;
        case "BadRoyaltiesSum":
          toast.error(i18n?.t("notifications:notifications.messages.admin.track.bad-royalties-sum"));
          break;
        case "RoyaltiesUserEmailNotFound":
          toast.error(
            i18n?.t("notifications:notifications.messages.errors.mutations.royalties-email-not-found")
          );
          break;
        case "NftAlreadyInSale":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.nft-already-in-sale"));
          break;
        case "UnsupportedMimeType":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.unsupported-mime"));
          break;
        case "CanNotBidOnYourOwnSale":
          toast.error(i18n?.t("notifications:notifications.messages.market.artist.self-bid"));
          break;
        case "PriceTooLow":
          toast.error(i18n?.t("notifications:notifications.messages.market.collector.minimum"));
          break;
        case "WithdrawAmountTooSmall":
          toast.error(i18n?.t("notifications:notifications.messages.account.wallet.withdrawal.minimum"));
          break;
        case "WithdrawCurrencyMismatch":
          toast.error(i18n?.t("notifications:notifications.messages.account.wallet.withdrawal.mismatch"));
          break;
        case "MissingAddress":
          toast.error(i18n?.t("notifications:notifications.messages.account.wallet.withdrawal.address"));
          break;
        case "NotEnoughVotes":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.not-enough-votes"));
          break;
        case "VotesMaxExceeded":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.max-votes"));
          break;
        case "MaxTracksSubmitted":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.max-tracks"));
          break;
        case "InvalidInvite":
          toast.error(i18n?.t("notifications:notifications.messages.account.artist.label.invite.invalid"));
          break;
        case "ArtistNotInvited":
          toast.error(i18n?.t("notifications:notifications.messages.label.invites.artist_out"));
          break;
        case "ArtistNotInLabel":
          toast.error(i18n?.t("notifications:notifications.messages.label.releases.extern"));
          break;
        case "ArtistAlreadyInLabel":
          toast.error(i18n?.t("notifications:notifications.messages.label.invites.artist_in"));
          break;
        case "LabelHasMaxArtists":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.label-max-artists"));
          break;
        case "NoSlotsLeft":
          toast.warn(<NoSlotsLeftToast />, {
            autoClose: false,
            toastId: "no-slots-left-toast",
          });
          break;
        case "SlotAlreadyFull":
          toast.error(i18n?.t("notifications:notifications.messages.label.releases.slots_full"));
          break;
        case "WrongSentDate":
          toast.error("An invitation email has already been sent recently");
          break;

        // Schedule Errors
        case "ReleaseDateInvalid":
        case "ReleaseDateTooSoon":
          toast.error(i18n?.t("notifications:notifications.messages.admin.release.schedule.invalid_date"), {
            toastId: "release-date-invalid",
          });
          break;
        case "TrackIncomplete":
          toast.error(i18n?.t("notifications:notifications.messages.admin.release.schedule.incomplete"), {
            toastId: "track-incomplete",
          });
          break;

        case "NoNftLeft":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.no-nft-left"));
          localStorage.removeItem(LS_REFERRAL_CODE);
          break;
        case "NoAvailableNFTForThisRelease":
          toast.error(i18n?.t("notifications:notifications.messages.playlists.add.unavailable_unknown"));
          break;
        case "AlreadyClaimed":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.already-claimed"));
          localStorage.removeItem(LS_REFERRAL_CODE);
          sessionStorage.removeItem(SS_CLAIM_CODE);
          break;
        case "BountyClaimLimitReached":
          toast.error(i18n?.t("notifications:notifications.messages.errors.mutations.max-claims"));
          break;
        case "OtherAccountAlreadyClaimed":
          toast.error(
            i18n?.t("notifications:notifications.messages.errors.mutations.already-claimed_other-account")
          );
          localStorage.removeItem(LS_REFERRAL_CODE);
          sessionStorage.removeItem(SS_CLAIM_CODE);
          break;
        case "InvalidSignature":
          // TEMPORARY TO DEBUG UNTIL FIXED
          toast.error(i18n?.t("notifications:notifications.messages.errors.default"));
          break;
        case "StateNotIntent":
          // Dashboard
          toast.error("This track is no longer modifiable");
          reportError(unsafeError);
          break;
        case "InvalidUnlockableFolderUrl":
          toast.error(i18n?.t("notifications:notifications.messages.admin.release.perks.url.invalid"));
          reportError(unsafeError);
          break;
        case "ValidationError":
          if (valErrors?.constraints) {
            const message = Object.values(valErrors.constraints).join(", ");
            // Capitalize backend error string
            toast.error(message.charAt(0).toUpperCase() + message.slice(1));
          } else {
            reportError(unsafeError);
            toast.error(i18n?.t("notifications:notifications.messages.errors.default"));
          }
          break;
        default:
          reportError(unsafeError);
          if (unsafeError.message.includes("Access denied!"))
            toast.error(i18n?.t("notifications:notifications.messages.errors.auth.denied"));
          else toast.error(i18n?.t("notifications:notifications.messages.errors.default"));
          break;
      }
    } else {
      reportError(unsafeError);
    }
  });

  if (networkError) {
    // Ignore failed to fetch errors
    if (networkError.message === "Failed to fetch" || networkError.message === "Load failed") return;
    // Clear bodyText if too large (containing HTML text)
    if ("bodyText" in networkError && networkError.bodyText?.length > 100)
      networkError.bodyText = "- redacted -";

    getAnalytics()?.track("Received Network Error", {
      message: networkError.message,
      name: networkError.name,
    });
    initializeApollo()?.mutate({
      mutation: ReportErrorDocument,
      variables: {
        exception:
          JSON.stringify(networkError, undefined, 2) +
          `\n\nPostHog Distinct ID: ${getPosthog()?.get_distinct_id()}`,
        path: "Frontend: Network Error - " + networkError.name,
        message: networkError.message,
      },
    });
    if (!analyticsOnly) {
      toast.error(i18n?.t("notifications:notifications.messages.errors.network"));
    }
  }
};

function reportError(graphQLError: GraphQLError) {
  // Only report in production mode
  if (process.env.NODE_ENV !== "production") return;

  const error: ResolverErrors = graphQLError.extensions?.exception?.kind;
  const stacktrace = graphQLError.extensions?.exception?.stacktrace;
  const variables = {
    exception:
      (stacktrace ? stacktrace.join("\n") : JSON.stringify(graphQLError, undefined, 2)) +
      `\n\nPostHog Distinct ID: ${getPosthog()?.get_distinct_id()}`,
    path: `Frontend: ${String(graphQLError.path?.[0] ?? "unknown")}`,
    message: error ?? graphQLError.message,
  };
  initializeApollo()?.mutate({ mutation: ReportErrorDocument, variables });
}

export const useReportErrorMutation = catchMutationError(ReportErrorDocument);

export const useStartMintMutation = catchMutationError(StartMintDocument);
export const useCreateTrackMutation = catchMutationError(CreateTrackDocument);
export const useUpdateTrackMutation = catchMutationError(UpdateTrackDocument);
export const useDeleteTrackMutation = catchMutationError(DeleteTrackDocument);

export const useModifyReleaseVisibilityMutation = catchMutationError(ModifyReleaseVisibilityDocument);

export const useCreateWalletApproveTransactionMutation = catchMutationError(
  CreateWalletApproveTransactionDocument
);
export const useValidateTransactionMutation = catchMutationError(ValidateTransactionDocument);

export const useVerifyEmailMutation = catchMutationError(VerifyEmailDocument);
export const useResendVerifyEmailMutation = catchMutationError(ResendVerifyEmailDocument);

export const useSendResetPasswordEmailMutation = catchMutationError(SendResetPasswordEmailDocument);
export const useChangePasswordMutation = catchMutationError(ChangePasswordDocument);

export const useUpdateMeMutation = catchMutationError(UpdateMeDocument);
export const useUploadMyAvatarMutation = catchMutationError(UploadMyAvatarDocument);

export const useUpdateArtistMutation = catchMutationError(UpdateArtistDocument);
export const useUpdateMyArtistMutation = catchMutationError(UpdateMyArtistDocument);

export const useCreateCheckoutAuctionMutation = catchMutationError(CreateCheckoutAuctionDocument);
export const useCreateCheckoutInstantSaleMutation = catchMutationError(CreateCheckoutInstantSaleDocument);
export const useCreateAutoCheckoutMutation = catchMutationError(CreateAutoCheckoutDocument);
export const useCreateCoinbaseChargeMutation = catchMutationError(CreateCoinbaseChargeDocument);

export const useCreateSaleMutation = catchMutationError(CreateSaleDocument);
export const useStopSaleMutation = catchMutationError(StopSaleDocument);

export const usePayWithWalletMutation = catchMutationError(PayWithWalletDocument);
export const useDetachPaymentMethodMutation = catchMutationError(DetachPaymentMethodDocument);

export const useCreateBankTransferWithdrawRequestMutation = catchMutationError(
  CreateBankTransferWithdrawRequestDocument
);
export const useAddBankAccountMutation = catchMutationError(AddBankAccountDocument);
export const useRemoveBankAccountMutation = catchMutationError(RemoveBankAccountDocument);

export const useStartThumbnailChunkUploadMutation = catchMutationError(StartThumbnailChunkUploadDocument);
export const useChunkThumbnailChunkUploadMutation = catchMutationError(ChunkThumbnailChunkUploadDocument);
export const useEndThumbnailChunkUploadMutation = catchMutationError(EndThumbnailChunkUploadDocument);
export const useStartTrackChunkUploadMutation = catchMutationError(StartTrackChunkUploadDocument);
export const useChunkTrackChunkUploadMutation = catchMutationError(ChunkTrackChunkUploadDocument);
export const useEndTrackChunkUploadMutation = catchMutationError(EndTrackChunkUploadDocument);

export const useClaimNftGiveawayMutation = catchMutationError(ClaimNftGiveawayDocument);

export const useReportTrackMutation = catchMutationError(ReportTrackDocument);

export const useMarkNotificationsAsReadMutation = catchMutationError(MarkNotificationsAsReadDocument);
export const useMarkAllNotificationsAsReadMutation = catchMutationError(MarkAllNotificationsAsReadDocument);

export const useLinkToDiscordMutation = catchMutationError(LinkToDiscordDocument);
export const useUnlinkFromDiscordMutation = catchMutationError(UnlinkFromDiscordDocument);

export const useInviteArtistToLabelMutation = catchMutationError(InviteArtistToLabelDocument);
export const useRemoveArtistInvitationMutation = catchMutationError(RemoveArtistInvitationDocument);
export const useAcceptLabelInvitationMutation = catchMutationError(AcceptLabelInvitationDocument);
export const useDeclineLabelInvitationMutation = catchMutationError(DeclineLabelInvitationDocument);

export const useToggleTrackLikeMutation = catchMutationError(ToggleTrackLikeDocument);

export const useCreatePlaylistMutation = catchMutationError(CreatePlaylistDocument);
export const useUpdatePlaylistMutation = catchMutationError(UpdatePlaylistDocument);
export const useAppendToPlaylistMutation = catchMutationError(AppendToPlaylistDocument);
export const useDeletePlaylistMutation = catchMutationError(DeletePlaylistDocument);
export const useTogglePlaylistLikeMutation = catchMutationError(TogglePlaylistLikeDocument);

export const useAcceptCollabInvitationMutation = catchMutationError(AcceptCollabInvitationDocument);
export const useDeclineCollabInvitationMutation = catchMutationError(DeclineCollabInvitationDocument);

export const useSetup2FAMutation = catchMutationError(Setup2FaDocument);
export const useEnable2FAMutation = catchMutationError(Enable2FaDocument);
export const useDisable2FAMutation = catchMutationError(Disable2FaDocument);
export const useRegenerate2FARecoveryCodesMutation = catchMutationError(Regennerate2FaRecoveryCodesDocument);
