import { Dispatch, SetStateAction } from "react";

import { LS_PLAYER_REPEAT } from "@/components/AudioPlayer/Provider";
import { AudioPlayerTrack } from "@/components/AudioPlayer";
import { getArtistNames } from "@/utils/getArtistNames";
import { ReportErrorDocument, TrackStreamEventDocument } from "@/graphql/types";
import { getAnalytics } from "@/utils/analytics";
import { initializeApollo } from "@/apollo";
import { NEXT_ENV } from "@/env";

interface CreateAudioArgs {
  /**
   * Track to use for the audio element's source
   * @remarks
   * Will check if the browser can play flac
   * Otherwise falls back to MP3
   */
  track: AudioPlayerTrack;
  /** Audio Element's onplay callback */
  onplay: (event: Event) => void;
  /** Audio Element's onpause callback */
  onpause: (event: Event) => void;
  /** Audio Element's onended callback */
  onended: (event: Event) => void;
}

/**
 * Creates an HTML audio element and loads it with the appropriate src
 * Also attaches the event callbacks given
 *
 * @returns Audio element
 */
export function createAudio({ track, onplay, onpause, onended }: CreateAudioArgs) {
  if (!track) return undefined;

  const newAudio = new Audio();
  newAudio.preload = "metadata";

  // Checks for audio supported types
  if (newAudio.canPlayType("audio/flac") && track.audio?.flac.url) newAudio.src = track.audio.flac.url;
  else if (newAudio.canPlayType("audio/mpeg") && track.audio?.mp3.url) {
    newAudio.src = track.audio.mp3.url;
    getAnalytics()?.track("Audio Player FLAC not supported", { fallback: "mp3" });
  } else {
    getAnalytics()?.track("Audio Player FLAC not supported", { fallback: "none" });
    const client = initializeApollo();
    client
      .mutate({
        mutation: ReportErrorDocument,
        variables: {
          exception: `This browser does not support audio/flac or audio/mpeg...\nNo fallback possible.`,
          path: "Frontend: HTML Audio",
          message: "Client cannot play audio",
        },
      })
      .catch((error) => {
        if (NEXT_ENV !== "production" || typeof window === "undefined") {
          const log = { message: "Client cannot play audio", error };
          console.log("Report Error Error", JSON.stringify(log));
        }
      });
  }

  newAudio.onplay = onplay;
  newAudio.onpause = onpause;
  newAudio.onended = onended;

  const userVolume = localStorage.getItem("volume_preference");
  newAudio.volume = userVolume ? parseFloat(userVolume) : 0.75;

  newAudio.load();

  // Set MediaSession metadata
  if ("mediaSession" in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: track.title,
      artist: getArtistNames(track.artists),
      artwork: track.thumbnail?.minifiedImageUrl
        ? [{ src: track.thumbnail?.minifiedImageUrl, sizes: "400x400", type: "image/jpg" }]
        : undefined,
    });
  }

  if (track.startPlayingAt) newAudio.currentTime = track.startPlayingAt;
  return newAudio;
}

type AudioEventsArgs = {
  track: AudioPlayerTrack;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  musicIndex: number;
  setMusicIndex: Dispatch<SetStateAction<number>>;
  playlist: AudioPlayerTrack[];
  playlistId?: string;
  setPlaylist: Dispatch<SetStateAction<AudioPlayerTrack[] | undefined>>;
};

export const { getAudioEvents } = (() => {
  let streamingTrackId: string | undefined;
  let streamTimeout: PauseableTimeout | undefined;

  function startStreamCountdown(track: AudioPlayerTrack, isWaitlist = false) {
    if (track.id !== streamingTrackId) {
      // Start new countdown if the track is different
      streamingTrackId = track.id;
      if (streamTimeout) {
        streamTimeout.pause();
      }

      streamTimeout = new PauseableTimeout(() => {
        trackStreamEvent(track, isWaitlist);
        streamTimeout = undefined;
      }, 30_000);
    } else if (streamTimeout) {
      // Otherwise, resume the countdown
      streamTimeout.resume();
    }
  }

  function trackStreamEvent(track: AudioPlayerTrack, isWaitlist = false) {
    const client = initializeApollo();
    client.mutate({
      mutation: TrackStreamEventDocument,
      variables: { trackId: track.id },
    });

    // Track through analytics
    getAnalytics()?.track("WEB - Audio Player - Song - Streamed", {
      track: track.title,
      trackSlug: track.slug,
      artist: track.artists[0]?.slug,
      artists: track.artists.map((a) => a.slug),
      streamSource: track.streamSource,
      isWaitlist,
    });
  }

  return {
    getAudioEvents({
      track,
      setIsPlaying,
      musicIndex,
      setMusicIndex,
      playlist,
      playlistId,
      setPlaylist,
    }: AudioEventsArgs) {
      return {
        onplay: (event: Event) => {
          setIsPlaying(true);

          // Count streams
          startStreamCountdown(track, track.hasWaitlistSlug);

          getAnalytics()?.track("Audio Player Music Played", {
            track: track.slug,
            artists: track.artists.map((a) => a.slug),
            time: (event.target as HTMLAudioElement)?.currentTime,
            duration: (event.target as HTMLAudioElement)?.duration,
            index: musicIndex,
            tracks: playlist.map((track) => track.slug),
            tracksIds: playlist.map((track) => track.id),
            playlistId,
          });
        },
        onpause: (event: Event) => {
          setIsPlaying(false);

          // Pause stream
          if (streamTimeout) {
            streamTimeout.pause();
          }

          getAnalytics()?.track("Audio Player Music Paused", {
            track: track.slug,
            artists: track.artists.map((a) => a.slug),
            time: (event.target as HTMLAudioElement)?.currentTime,
            duration: (event.target as HTMLAudioElement)?.duration,
            index: musicIndex,
            tracks: playlist.map((track) => track.slug),
            tracksIds: playlist.map((track) => track.id),
            playlistId,
          });
        },
        onended: (event: Event) => {
          const time = (event.target as HTMLAudioElement)?.currentTime;
          const duration = (event.target as HTMLAudioElement)?.duration;

          if (streamTimeout) {
            // Clear stream timer
            streamTimeout.pause();
            streamTimeout = undefined;
            streamingTrackId = undefined;
            if (duration && duration < 30 && time && time > 0.98 * duration) {
              // Count stream for short tracks
              trackStreamEvent(track, track.hasWaitlistSlug);
            }
          }

          getAnalytics()?.track("Audio Player Music Ended", {
            track: track.slug,
            artists: track.artists.map((a) => a.slug),
            time,
            duration,
            index: musicIndex,
            tracks: playlist.map((music) => music.slug),
            tracksIds: playlist.map((track) => track.id),
            playlistId,
          });

          const repeat = localStorage.getItem(LS_PLAYER_REPEAT);
          // Check if more songs in playlist
          if (musicIndex < playlist.length - 1) {
            setMusicIndex((i) => i + 1);

            // Check if loop/repeat is on, otherwise finish play session.
            // WARNING: Setting playlist to undefined instead of []
            //          causes loop on single track playlists... !?
          } else if (repeat !== "1") setPlaylist([]);
          else if (playlist.length === 1) {
            // Play again when single track is looped
            (event.target as HTMLAudioElement).play();
          } else setMusicIndex(0);
        },
      };
    },
  };
})();

class PauseableTimeout {
  private start: number;

  private remaining: number;

  private callback: () => void;

  private timeout: NodeJS.Timeout | undefined;

  constructor(callback: () => void, delay: number) {
    this.remaining = delay;
    this.callback = callback;

    this.start = Date.now();
    this.resume();
  }

  resume() {
    // Ignore if already running
    if (this.timeout) return;

    this.start = Date.now();
    this.timeout = setTimeout(() => {
      this.timeout = undefined;
      this.callback();
    }, this.remaining);
  }

  pause() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
      this.remaining -= Date.now() - this.start;
    }
  }
}
