import { AnimatePresence, motion } from "framer-motion";
import { useReactiveVar } from "@apollo/client";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import clsx from "clsx";

import ModalPortal from "@/components/ModalPortal";
import { useWindowSize } from "@/components/WindowSizeProvider";
import BottomSheet from "@/components/modals/BottomSheet";
import PublicBountyClaimView from "@/components/modals/bounty/PublicBountyClaim/ClaimView";
import { PublicClaimPayload } from "@/components/modals/bounty/PublicBountyClaim/Provider";
import { BasicNftFragment } from "@/graphql/types";
import { hasAccessTokenVar } from "@/apollo/links/auth";
import { useTrackingData } from "@/components/TrackingDataProvider";
import { getArtistNames } from "@/utils/getArtistNames";

import { getAnalytics } from "@/utils/analytics";
import CloseButton from "@/components/ui/svg/CloseButton";

interface PublicBountyClaimModalProps {
  payload: PublicClaimPayload | undefined;
  onClaimed: (nft: BasicNftFragment) => void;
  onClose: () => void;
}

function PublicBountyClaimModal({ payload, onClaimed, onClose }: PublicBountyClaimModalProps) {
  const isLogged = useReactiveVar(hasAccessTokenVar);
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  const { t } = useTranslation("common");

  const { updateData, clearData } = useTrackingData();

  useEffect(() => {
    if (!payload) {
      clearData();
      return undefined;
    }

    const trackingData = {
      track: payload.track.title,
      trackId: payload.track.id,
      trackSlug: payload.track.slug,
      artists: getArtistNames(payload.track.artists),
      artistsIds: payload.track.artists.map((artist) => artist.id),
      artistsSlugs: payload.track.artists.map((artist) => artist.slug),
      bountyReleaseScarcity: payload.release.rarity,
    };
    updateData(trackingData);
    getAnalytics()?.track("WEB - Public Bounty Claim - Popin - Opened", trackingData);

    return () => {
      getAnalytics()?.track("WEB - Public Bounty Claim - Popin - Closed", trackingData);
    };
  }, [payload, updateData, clearData]);

  if (isMobile) {
    return (
      <BottomSheet
        title={t("common.buttons.claim")}
        show={!!payload}
        close={onClose}
        header={<div className="h-4" />}
        disableOverflowYAuto={isLogged ? "visible" : false}
      >
        {!!payload && <PublicBountyClaimView payload={payload} onClaimed={onClaimed} onClose={onClose} />}
      </BottomSheet>
    );
  }

  return (
    <ModalPortal>
      <AnimatePresence>
        {!!payload && (
          <motion.div
            className={clsx(
              "fixed inset-0 h-full w-full px-5 lg:px-0",
              "flex flex-col items-center justify-center z-9999999"
            )}
            onClick={onClose}
          >
            <motion.div
              initial={{ backdropFilter: "blur(12px) opacity(0)" }}
              animate={{ backdropFilter: "blur(12px) opacity(1)" }}
              exit={{ backdropFilter: "blur(12px) opacity(0)" }}
              className={clsx(
                "absolute inset-0 bg-dark-3/10 -z-10",
                "before:absolute before:inset-0 before:-z-10 before:backdrop-blur-md"
              )}
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={clsx(
                "bg-dark-4/40 bg-dark-glass backdrop-blur-[48px] shadow-larger rounded-xl relative",
                "border-2 border-dark-6/50",
                "w-[978px] h-[640px] flex items-center justify-center text-white"
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={onClose}
                className={clsx(
                  "hidden lg:flex items-center justify-center absolute top-6 right-6",
                  "w-10 h-10 rounded-full border-2 border-purple-3/40 hover:border-purple"
                )}
              >
                <CloseButton width="16" height="16" />
              </button>
              <PublicBountyClaimView payload={payload} onClaimed={onClaimed} onClose={onClose} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
}

export default PublicBountyClaimModal;
