import { AnimatePresence } from "framer-motion";
import dynamic from "next/dynamic";
import { useEffect } from "react";

import { PhoneVerificationCallbacks } from "@/components/phone-verification/types";
import { getAnalytics } from "@/utils/analytics";
import ModalPortal from "@/components/ModalPortal";

const PhoneVerification = dynamic(() => import("@/components/phone-verification/Modal/PhoneVerification"));

interface PhoneVerificationModalProps extends Pick<PhoneVerificationCallbacks, "onSuccess"> {
  onClose: () => void;
}

function PhoneVerificationModal({ onSuccess, onClose }: PhoneVerificationModalProps) {
  useEffect(() => {
    getAnalytics()?.track("WEB - Auth - Phone Verification Popin - Opened");
    return () => {
      getAnalytics()?.track("WEB - Auth - Phone Verification Popin - Closed");
    };
  }, []);

  return (
    <ModalPortal>
      <AnimatePresence>
        {!!onSuccess && <PhoneVerification onSuccess={onSuccess} onClose={onClose} />}
      </AnimatePresence>
    </ModalPortal>
  );
}

export default PhoneVerificationModal;
