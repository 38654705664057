import clsx from "clsx";
import Image from "next/image";

import { BasicNftFragment, BasicReleaseFragment, BasicTrackFragment } from "@/graphql/types";
import ArtistNames from "@/components/reusable/ArtistNames";

interface Props {
  nft: BasicNftFragment | undefined;
  release: BasicReleaseFragment | undefined;
  track: BasicTrackFragment;
}

export default function TinyNftCard({ track, release, nft }: Props) {
  return (
    <div className="z-10 w-full h-20 rounded-lg p-[2px] bg-dark-stroke">
      <div
        className={clsx(
          "w-full h-full p-2.5",
          "rounded-lg flex items-center",
          // "shadow-checkout-nft-card-mobile",
          "bg-dark-7"
        )}
      >
        <div className="w-15 h-15 shrink-0">
          <Image
            src={track.thumbnail?.minifiedImageUrl ?? "/assets/misc/no-image.svg"}
            alt=""
            width={60}
            height={60}
            className="rounded"
          />
        </div>

        <div className="mx-3 grow flex flex-col min-w-0">
          <ArtistNames artists={track.artists} />
          <span className="text-lg leading-1.1 font-medium truncate">{track.title}</span>
        </div>

        {release && (
          <div
            className={
              "-mr-5 z-10 shrink-0 w-9 h-9 rounded-full flex items-center justify-center bg-purple-6"
            }
          >
            <Image
              src={`/assets/icons/rarity/${release.rarity.toLowerCase()}.png`}
              alt=""
              width={18}
              height={18}
            />
          </div>
        )}
        {release && nft && (
          <div
            className={clsx(
              "h-9 mr-1 pl-5 pr-3",
              "rounded-full border-3 border-dark-8",
              "flex items-center",
              "text-xs leading-1 font-medium text-white"
            )}
          >
            {`${String(nft.number).padStart(2, "0")}/${release.editions}`}
          </div>
        )}
      </div>
    </div>
  );
}
