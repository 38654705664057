import { SVGProps } from "react";

export default function HelpIcon(props: SVGProps<SVGSVGElement>) {
  const { width = "13", height = "12", ...rest } = props;
  return (
    <svg {...{ width, height }} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12" fill="none">
      <path
        d="M6.54482 2.96411C5.27685 2.96411 4.41233 3.77923 4.6017 5.05543H5.8038C5.68029 4.33912 6.1002 4.08388 6.54482 4.08388C7.01413 4.08388 7.21173 4.38852 7.21173 4.69316C7.21173 5.16247 6.70949 5.36831 6.35544 5.82939C6.08374 6.18343 6.0261 6.40574 6.0261 6.91622H7.08C7.08823 6.3975 7.35994 6.26577 7.62341 6.05993C8.06802 5.71412 8.5291 5.33538 8.5291 4.57789C8.5291 3.77923 7.88689 2.96411 6.54482 2.96411ZM5.8285 7.44317V8.89227H7.2776V7.44317H5.8285Z"
        className="fill-current"
      />
      <rect
        x="1.24102"
        y="0.74102"
        width="10.518"
        height="10.518"
        rx="5.25898"
        className="stroke-current"
        strokeWidth="1"
      />
    </svg>
  );
}
