import clsx from "clsx";
import Image from "next/image";

import { useTranslation } from "next-i18next";
import { Rarity } from "@/graphql/types";
import { getScarcityCount } from "@/utils/mintBuilder";
import { getScarcityKey } from "@/utils/scarcity";

interface Props {
  number: number;
  scarcity: Rarity;
}

export default function NftBadge({ number, scarcity }: Props) {
  const { t } = useTranslation("common");

  const lowercased = getScarcityKey(scarcity);
  return (
    <div
      className={clsx(
        "pl-3 pr-5 lg:pl-4 lg:pr-6 h-9 lg:h-12 flex items-center",
        "rounded-full bg-black bg-opacity-70",
        "shadow-[0px_2px_2px_rgba(28,15,125,0.04),0px_2px_10px_rgba(28,15,125,0.04)]"
      )}
    >
      <div className="shrink-0 w-[26px] h-[26px] lg:w-[30px] lg:h-[30px]">
        <Image src={`/assets/icons/rarity/${lowercased}.png`} width={30} height={30} alt="" />
      </div>

      <div className="ml-2 flex flex-col items-start">
        <span className="text-white text-xs leading-1.25 lg:text-sm lg:leading-1.25 font-medium">
          {t(`common.rarity.${lowercased}`)}
        </span>
        <span
          className={clsx(
            "text-purple-4 text-10 leading-1.25 lg:text-xs",
            "lg:leading-1.25 font-medium tracking-wider"
          )}
        >{`${number}/${getScarcityCount(scarcity)}`}</span>
      </div>
    </div>
  );
}
