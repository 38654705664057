import { onError } from "@apollo/client/link/error";
import { i18n } from "next-i18next";

import { ReportErrorDocument } from "@/graphql/types";
import { logoutUser } from "@/utils/oAuth/tokens";
import { getPosthog } from "@/utils/analytics";
import { initializeApollo } from "@/apollo";
import { NEXT_ENV } from "@/env";
import { toast } from "@/utils/toasts";

// Note: There is no error handling here. We instead use the HOF
// found in @/utils/mutationHooks
export const errorLink = onError((res) => {
  const { graphQLErrors, networkError } = res;

  if (typeof window === "undefined" || NEXT_ENV === "development") {
    if (graphQLErrors || networkError) console.log("Errors for operation:", res.operation.operationName);
    if (graphQLErrors) graphQLErrors.map(({ message }) => console.log(message));
    if (networkError) console.log(JSON.stringify(networkError));
  }

  if (res.graphQLErrors?.[0]?.extensions?.code === "UNAUTHORIZED") {
    if (res.operation.operationName === "me") {
      // Clear token if the query responds with Unauthorized (because of an invalid/missing token)
      logoutUser();
      toast.error(i18n?.t("notifications:notifications.messages.errors.auth.expired"));
    }

    if (NEXT_ENV === "development") {
      // Do not report errors to API in development
      return;
    }
  }

  if (
    res.operation.query.definitions.some(
      (def) => def.kind === "OperationDefinition" && def.operation === "query"
    )
  ) {
    // Log network error for queries
    if (networkError) {
      // Ignore failed to fetch errors
      if (networkError.message === "Failed to fetch" || networkError.message === "Load failed") return;
      // Clear bodyText if too large (containing HTML text)
      if ("bodyText" in networkError && networkError.bodyText?.length > 100) {
        networkError.bodyText = "- redacted -";
      }
      if (
        "result" in networkError &&
        (networkError.result?.length > 100 || typeof networkError.result === "object")
      ) {
        networkError.result = "- redacted -";
      }

      const apolloClient = initializeApollo();
      apolloClient
        .mutate({
          mutation: ReportErrorDocument,
          variables: {
            exception:
              JSON.stringify(networkError, undefined, 2) +
              `\n\nPostHog Distinct ID: ${getPosthog()?.get_distinct_id() ?? "N/A (SERVER)"}\n\nOperation: ${
                res.operation.operationName
              }`,
            path: `Frontend: ${networkError.name}`,
            message: networkError.message,
          },
        })
        .catch((error) => {
          if (NEXT_ENV !== "production" || typeof window === "undefined") {
            const log = { message: networkError.message, error };
            console.log("Report Error Error", JSON.stringify(log));
          }
        });
    }
  }
});
