import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Trans, useTranslation } from "next-i18next";
import Countdown from "react-countdown";
import { useEffect } from "react";
import clsx from "clsx";

import { hasAccessTokenVar } from "@/apollo/links/auth";
import { PublicClaimPayload } from "@/components/modals/bounty/PublicBountyClaim/Provider";
import RotatedNftCard from "@/components/releases/cards/NftCard/Rotated";
import {
  BasicNftFragment,
  ClaimNftGiveawayDocument,
  GetMyBountyClaimingStatusDocument,
} from "@/graphql/types";
import BountyClaimAuthForm from "@/components/modals/bounty/PublicBountyClaim/AuthForm";
import { getScarcityKey } from "@/utils/scarcity";
import { getArtistNames } from "@/utils/getArtistNames";
import Button from "@/components/ui/Button";
import HelpIcon from "@/components/ui/svg/HelpIcon";
import { onApolloError } from "@/utils/mutationHooks";
import { getAnalytics } from "@/utils/analytics";
import { useTrackingData } from "@/components/TrackingDataProvider";
import { useWindowSize } from "@/components/WindowSizeProvider";
import getCountdownRenderer from "@/components/reusable/CountdownRenderer";
import { toast } from "@/utils/toasts";

interface PublicBountyClaimViewProps {
  payload: PublicClaimPayload;
  onClaimed: (nft: BasicNftFragment) => void;
  onClose: () => void;
}

function PublicBountyClaimView({
  payload: { track, release, code, updateCache },
  onClaimed,
  onClose,
}: PublicBountyClaimViewProps) {
  const { data: trackingData, updateData: updateTrackingData } = useTrackingData();
  const isLogged = useReactiveVar(hasAccessTokenVar);
  const { width } = useWindowSize();
  const isMobile = width < 1024;
  const { t } = useTranslation("common");

  const { data } = useQuery(GetMyBountyClaimingStatusDocument, {
    variables: { releaseId: release.id },
    fetchPolicy: "cache-and-network",
    skip: !isLogged,
  });
  const [claimNftGiveawayMutation, { called, loading }] = useMutation(ClaimNftGiveawayDocument);

  function onClaim() {
    claimNftGiveawayMutation({
      variables: { code },
      update: (cache, mutation, options) => {
        const bounty = data?.releases.results[0]?.bounty;
        if (mutation.data?.claimNftGiveaway) {
          onClaimed(mutation.data.claimNftGiveaway);

          if (bounty) {
            cache.modify({
              id: cache.identify(bounty),
              fields: {
                claimed() {
                  return true;
                },
              },
            });
          }
          const me = data?.me;
          if (me) {
            cache.modify({
              id: cache.identify(me),
              fields: {
                availableBountyClaims(prev) {
                  return {
                    ...prev,
                    amount: prev.amount - 1,
                  };
                },
              },
            });
          }

          // Allows to pass custom cache update function
          updateCache?.(cache, mutation, options);
        }
      },
      onError: (error) => {
        onApolloError(error);
        if (
          error.message === "AlreadyClaimed" ||
          error.message === "BountyClaimLimitReached" ||
          error.message === "OtherAccountAlreadyClaimed"
        ) {
          onClose();
        }
      },
    });
  }

  useEffect(() => {
    if (data) {
      const availableClaims = data.me.availableBountyClaims;
      const bounty = data.releases.results[0]?.bounty;
      updateTrackingData({
        alreadyClaimed: bounty?.claimed ?? undefined,
        claimsLeft: availableClaims.amount,
        claimsResetAt: availableClaims.resetAt,
      });

      if (bounty?.claimed === true && !called) {
        toast.info(t("common.modals.bounty_public.claimed"));
        onClose();
      }
    }
  }, [track, data, called, onClose, t, updateTrackingData]);

  const canClaim = data?.me.availableBountyClaims.amount !== 0 && !data?.releases.results[0]?.bounty?.claimed;
  return (
    <div
      className={clsx(
        "w-full grow flex flex-col items-center lg:grid p-5",
        isLogged ? "lg:grid-cols-2 lg:pr-22" : "lg:grid-cols-[2fr,3fr]"
      )}
    >
      {/* MARK: NFT Card (Desktop) */}
      {(isLogged || !isMobile) && (
        <div className="-mt-5 mb-8 lg:m-0 lg:px-5">
          <RotatedNftCard
            track={track}
            release={release}
            nft={undefined}
            streamSource="Public Bounty Claim Popup"
          />
        </div>
      )}

      {!isLogged && <BountyClaimAuthForm track={track} claimCode={code} onClose={onClose} />}

      {isLogged && (
        <div className="flex flex-col items-center lg:items-start">
          <span className="hidden lg:block text-48 leading-1 font-medium mb-6">
            {t("common.modals.bounty_public.title")}
          </span>
          <p
            className={clsx(
              "text-center lg:text-left lg:max-w-[24ch]",
              "text-lg leading-1.2 lg:text-22 lg:leading-1.2"
            )}
          >
            <Trans
              ns="common"
              i18nKey="common.modals.bounty_public.text"
              values={{
                scarcity: t(`common.rarity.${getScarcityKey(release.rarity)}`),
                artist: getArtistNames(track.artists),
                track: track.title,
              }}
            >
              Claim it
              <span className="text-purple-3 font-medium" />
            </Trans>
          </p>

          <div className="flex items-center mt-7 lg:mt-8">
            {canClaim && (
              <Button
                height={{ base: 10, lg: 12 }}
                paddingX={{ base: 4, lg: 7 }}
                loading={loading}
                onClick={() => {
                  onClaim();
                  getAnalytics()?.track("WEB - Public Bounty Claim - Claim Button - Clicked", trackingData);
                }}
              >
                {t("common.modals.bounty_public.claim")}
              </Button>
            )}
            <div
              className={clsx(
                canClaim ? "pl-12 -ml-9" : "pl-4 lg:pl-6",
                "h-10 lg:h-12 pr-3",
                "bg-dark-6/50 border border-dark-8 rounded-full",
                "flex items-center uppercase text-xs leading-1 font-medium"
              )}
            >
              <Trans
                ns="common"
                i18nKey="common.modals.bounty_public.claim_left"
                count={data?.me.availableBountyClaims.amount ?? 1}
              >
                claims left
                {data && (
                  <Countdown
                    date={data.me.availableBountyClaims.resetAt}
                    renderer={getCountdownRenderer(undefined, { textColour: "text-purple-3" })}
                  />
                )}
              </Trans>
              <a
                href="https://helpdesk.pianity.com/en/article/whats-a-bounty-and-how-can-i-claim-them-1dd6keh/"
                target="_blank"
                rel="noreferrer noopener"
                className="p-1 lg:p-2"
              >
                <HelpIcon width={16} height={16} />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PublicBountyClaimView;
