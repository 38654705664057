import { AnimatePresence } from "framer-motion";
import { useTranslation } from "next-i18next";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import dynamic from "next/dynamic";

import { GetGiveawayReleasesDocument } from "@/graphql/types";
import getRelativeTime from "@/utils/relativeTime";
import { toast } from "@/utils/toasts";
import { getAnalytics } from "@/utils/analytics";
import Link from "@/components/hoc/CustomLink";
import { SS_CLAIM_CODE } from "@/pages/_app";
import ModalPortal from "@/components/ModalPortal";
import { useSetModalStyles } from "@/utils/hooks/useSetModalStyles";

const Container = dynamic(() => import("@/components/modals/bounty/PrivateBountyClaim/Container"));

function PrivateBountyClaim() {
  const { t } = useTranslation(["common", "notifications"]);
  const router = useRouter();

  const [show, setShow] = useState(false);

  const [getBountyReleases, { data, called }] = useLazyQuery(GetGiveawayReleasesDocument);

  const claimCode = useMemo(() => {
    if (typeof window === "undefined") return undefined;
    let claimCode = sessionStorage.getItem(SS_CLAIM_CODE);
    if (router.query.cc && typeof router.query.cc === "string" && router.query.private === "true") {
      claimCode = router.query.cc;
    }
    if (claimCode?.startsWith("legacy_")) return undefined;
    return claimCode;
  }, [router.query.cc, router.query.private]);

  useEffect(() => {
    if (claimCode && !called && !router.asPath.includes("/connect")) {
      getBountyReleases({ variables: { code: claimCode } }).then(({ data }) => {
        if (data?.giveaway) {
          if (data.giveaway.bounty?.isPublic) return;

          const { releases } = data.giveaway;
          if (data.giveaway.claimed) {
            toast.info(t("notifications:notifications.messages.market.collector.bounty.claimed"));
            sessionStorage.removeItem(SS_CLAIM_CODE);
          } else if (
            data.giveaway.releases.reduce((acc, curr) => acc + curr.availableBounties.size, 0) === 0
          ) {
            toast.warn(
              <EmptyBountyToast
                href={`/market?artistIds=${releases
                  .flatMap((r) => r.track.artists.map((a) => a.id))
                  .join("&artistIds=")}`}
                startsAt={
                  releases.find((r) => r.releaseDate && new Date(r.releaseDate) > new Date())?.releaseDate
                }
              />
            );
            sessionStorage.removeItem(SS_CLAIM_CODE);
          } else setShow(true);
        }
      });
    }
  }, [getBountyReleases, t, called, router.asPath, claimCode]);

  useSetModalStyles(show);

  return (
    <ModalPortal>
      <AnimatePresence>
        {show && data?.giveaway && claimCode && (
          <Container
            key={data.giveaway.id}
            releases={data.giveaway.releases}
            claimCode={claimCode}
            onClose={() => {
              setShow(false);
              getAnalytics()?.track("Bounty Found Closed");
            }}
          />
        )}
      </AnimatePresence>
    </ModalPortal>
  );
}

export default PrivateBountyClaim;

function EmptyBountyToast({ href, startsAt }: { href: string; startsAt?: string | null }) {
  const { t } = useTranslation("notifications");

  return (
    <div>
      {t("notifications.messages.market.collector.bounty.empty.message", {
        context: startsAt ? "upcoming" : undefined,
        time: startsAt
          ? getRelativeTime(new Date(startsAt).getTime(), undefined, undefined, undefined, 0)
          : undefined,
      })}
      {!startsAt && (
        <Link href={href}>
          <a
            className={clsx(
              "mt-2 block text-purple text-xs leading-1 font-medium",
              "uppercase tracking-wider"
            )}
          >
            {t("notifications.messages.market.collector.bounty.empty.cta")}
          </a>
        </Link>
      )}
    </div>
  );
}
