import { ApolloCache, DefaultContext, MutationUpdaterFunction } from "@apollo/client";
import { createContext, useContext, useState } from "react";

import {
  BasicNftFragment,
  BasicReleaseFragment,
  BasicTrackFragment,
  ClaimNftGiveawayMutation,
  Exact,
} from "@/graphql/types";
import PublicBountyClaimModal from "@/components/modals/bounty/PublicBountyClaim/Modal";
import WinningModal from "@/components/modals/WinningModal";
import TrackingDataProvider from "@/components/TrackingDataProvider";

export type PublicClaimPayload = {
  track: BasicTrackFragment;
  release: BasicReleaseFragment;
  code: string;
  updateCache?: MutationUpdaterFunction<
    ClaimNftGiveawayMutation,
    Exact<{ code: string }>,
    DefaultContext,
    ApolloCache<unknown>
  >;
};

type Context = {
  claimPublicBounty(payload: PublicClaimPayload): void;
};

const PublicBountyClaimContext = createContext<Context>({
  claimPublicBounty: () => undefined,
});

const PublicBountyClaimProvider = ({ children }: { children: React.ReactNode }) => {
  const [acquiredNft, setAcquiredNft] = useState<BasicNftFragment>();
  const [claimPayload, setClaimPayload] = useState<PublicClaimPayload>();

  return (
    <PublicBountyClaimContext.Provider value={{ claimPublicBounty: setClaimPayload }}>
      {acquiredNft && claimPayload && (
        <WinningModal
          track={claimPayload.track}
          release={claimPayload.release}
          nft={acquiredNft}
          closeModal={() => {
            setAcquiredNft(undefined);
            setClaimPayload(undefined);
          }}
        />
      )}
      <TrackingDataProvider>
        <PublicBountyClaimModal
          payload={acquiredNft ? undefined : claimPayload}
          onClaimed={setAcquiredNft}
          onClose={() => setClaimPayload(undefined)}
        />
      </TrackingDataProvider>
      {children}
    </PublicBountyClaimContext.Provider>
  );
};

export const usePublicBountyClaim = () => useContext(PublicBountyClaimContext);
export default PublicBountyClaimProvider;
