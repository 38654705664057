import Transaction from "arweave/web/lib/transaction";

import { ArconnectWalletData, getWarp } from "@/utils/walletConnection";

function base64EncodeURL(byteArray: Uint8Array): string {
  return btoa(
    Array.from(new Uint8Array(byteArray))
      .map((val) => {
        return String.fromCharCode(val);
      })
      .join("")
  )
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

export async function getWalletData(): Promise<ArconnectWalletData | null> {
  if (!window.arweaveWallet) {
    return null;
  }

  await window.arweaveWallet.connect(
    ["ACCESS_PUBLIC_KEY", "ACCESS_ADDRESS", "SIGNATURE", "SIGN_TRANSACTION"],
    {
      name: "Pianity",
      logo: "https://pianity.com/assets/sharing/pianity-share.jpg",
    }
  );

  const publicKey = await window.arweaveWallet.getActivePublicKey();
  const address = await (
    await getWarp()
  ).arweave.wallets.jwkToAddress({ kty: "RSA", e: "AQAB", n: publicKey });

  return {
    type: "arconnect",
    publicKey,
    address,
  };
}

export async function sign(data: string): Promise<string> {
  return window.arweaveWallet
    .signature(new TextEncoder().encode(data), {
      name: "RSA-PSS",
      saltLength: 32,
    })
    .then((res) => new Uint8Array(Object.values(res as unknown as object)))
    .then((res) => btoa(String.fromCharCode.apply(null, Array.from(res))));
}

export async function signTx(owner: string, tx: Transaction): Promise<void> {
  tx.setOwner(owner);

  const dataToSign = await tx.getSignatureData();
  const dataSigned = await window.arweaveWallet.signature(dataToSign, {
    name: "RSA-PSS",
    saltLength: 32,
  });
  const id = await window.crypto.subtle.digest("SHA-256", dataSigned);

  tx.setSignature({
    id: base64EncodeURL(new Uint8Array(id)),
    owner,
    signature: base64EncodeURL(dataSigned),
  });

  await tx.getSignatureData();
}
