/**
 * Takes the page URL and breaks it into a base path
 * and an object of its query parameters
 *
 * @param url - URL of the page
 * @returns `[path, queryParams]`
 */
export default function getUrlAnalytics(url: string): [string, { [key: string]: string }] {
  const properties: { [key: string]: string } = {};
  const queryIndex = url.indexOf("?");
  let path = url.substring(0, queryIndex === -1 ? undefined : queryIndex);

  if (queryIndex !== -1) {
    const query = url.substring(queryIndex + 1);
    const queryArgs = query.split("&");
    for (const arg of queryArgs) {
      const splitIndex = arg.indexOf("=");
      const key = arg.substring(0, splitIndex);
      const value = arg.substring(splitIndex + 1);
      properties[key] = value;
    }
  }

  // WARNING: Will need to update when adding more locales
  if (path.includes("/fr/")) {
    properties.language = "FR";
    path = path.replace("/fr", "");
  } else if (path.includes("/ja/")) {
    properties.language = "JA";
    path = path.replace("/ja", "");
  }
  return [path, properties];
}
