import clsx from "clsx";
import { InputHTMLAttributes } from "react";
import { FieldValues, Path, RegisterOptions, useFormContext, useFormState } from "react-hook-form";

type InputProps<T extends FieldValues> = InputHTMLAttributes<HTMLInputElement> & {
  name: Path<T>;
  registerOptions?: RegisterOptions<T>;
};

export default function InputBox<T extends FieldValues>(props: InputProps<T>) {
  const { name, registerOptions, ...inputProps } = props;

  const { control, trigger, register } = useFormContext<T>();
  const { errors } = useFormState<T>({ control, name });
  const errorMessage = errors[name]?.message;

  return (
    <div className="flex flex-col">
      <div
        className={clsx(
          "rounded relative before:border-2 before:border-gradient before:rounded",
          errorMessage
            ? "before:bg-red-dark bg-red-2/15"
            : "bg-dark-5/30  before:bg-dark-stroke shadow-input-dark"
        )}
      >
        <input
          className="w-full px-4 h-12 lg:h-13.5 rounded outline-none bg-transparent"
          {...inputProps}
          {...register(name, {
            ...registerOptions,
            onChange: () => {
              if (errorMessage) trigger(name);
            },
          })}
        />
      </div>
      {errorMessage && <p className="text-red-dark text-xs leading-1 mt-2 text-right">{errorMessage}</p>}
    </div>
  );
}
